import { LoadingButton } from '@mui/lab'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { Box } from '@mui/material'
import { SearchInputItem } from '@shared/components/inputs/BaseSearchInput'
import { UpdateUserInput, useUpdateInstructorMutation, useUserTrainingCompanyQuery } from '@typings/graphql'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'

import { InstructorsProvider, useInstructorsContext } from '../provider/InstructorsProvider'
import { InstructorsCreateEditInputs } from '../components/InstructorsCreateEditInputs'

type InstructorsInputType = UpdateUserInput & {
  trainingCompanies: SearchInputItem[]
  users: SearchInputItem[]
}

const InstructorsEditViewContent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)

  const { setErrorMessage, setMessageByCode, errorMessage } = useErrorMessage()

  const { handleDelete, updateSelectedItem } = useInstructorsContext()

  const { data: instructorData, loading: userLoading } = useUserTrainingCompanyQuery({
    variables: {
      id: id as string
    },
    skip: !id
  })

  const [updateInstructors] = useUpdateInstructorMutation()

  const { formState: { isDirty, isValid, isSubmitted }, handleSubmit, reset } = useFormContext<InstructorsInputType>()

  useUnsavedChanges(isDirty && (!isSubmitted || !!errorMessage) && !deleted)

  React.useEffect(() => {
    if (instructorData?.user) {
      reset({
        email: instructorData.user.email,
        firstname: instructorData.user.firstname,
        lastname: instructorData.user.lastname,
        active: instructorData.user.active,
        trainingCompanies: instructorData.user.trainingCompanies ?? [],
        users: instructorData.user.trainees?.map((trainee) => ({
          id: trainee.id,
          name: trainee.email
        })) ?? []
      })
    }
  }, [instructorData])

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')

    const { trainingCompanies, users, email, ...rest } = input

    try {
      await updateInstructors({
        variables: {
          id: id as string,
          data: {
            ...rest,
            email: email?.toLowerCase(),
            trainingCompaniesIds: trainingCompanies.map((trainingCompany) => trainingCompany.id),
            traineeIds: users.map((user) => user.id)
          }
        }
      })
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setLoading(false)
      setShowToast(true)
    }
  })

  const handleInstructorsDeleted = () => {
    setDeleted(true)
    navigate('/instructors')
  }

  const onDelete = async () => {
    if (!instructorData?.user) {
      return
    }

    updateSelectedItem(instructorData.user)
    handleDelete(false, handleInstructorsDeleted)
  }

  return (
    <CreateEditLayout
      title={t('instructors.editTitle')}
      loading={userLoading}
      description={
        <Trans
          i18nKey={'instructors.editDescription'}
          values={{ name: instructorData?.user.email }}
          components={{ b: <strong /> }}
        />
      }
      headerExtension={(
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <DeleteIconButton onClick={onDelete} />
        </Box>
      )}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >

      <InstructorsCreateEditInputs />

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('instructors.editSuccess')}
      />
    </CreateEditLayout>
  )
}

export const InstructorsEditView:React.FC = () => {
  const formData = useForm<InstructorsInputType>({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <InstructorsProvider disableFetching>
      <FormProvider {...formData}>
        <InstructorsEditViewContent />
      </FormProvider>
    </InstructorsProvider>
  )
}
