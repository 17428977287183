import { Box, Grid, LinearProgress } from '@mui/material'
import {
  ReviewStatus,
  useCountUsersQuery,
  useRegisteredTraineesCountQuery
} from '@typings/graphql'
import React from 'react'
import { mapYearData } from '@utils/dashboard'
import { palette } from '@theme/core/palette/palette'
import { DashboardYearOverview } from '@shared/components/cards/DashboardYearOverview'
import { useTranslation } from 'react-i18next'
import { DashboardQuestionsStatus } from '@features/dashboard/components/DashboardQuestionsStatus'
import { Roles } from '@typings/roles'
import { DashboardConversionRate } from '@shared/components/cards/DashboardConversionRate'

export const AdminDashboardContent: React.FC = () => {
  const { t } = useTranslation()

  const [reviewLoading, setReviewLoading] = React.useState(true)
  const [rejectLoading, setRejectLoading] = React.useState(true)

  const { data: registrationCount, loading: registrationLoading } = useRegisteredTraineesCountQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      monthBack: 12
    }
  })

  const { data: traineeCount, loading: traineeCountLoading } = useCountUsersQuery({
    variables: {
      role: Roles.TRAINEE
    }
  })

  const { data: traineeCountCompany, loading: traineeCountCompanyLoading } = useCountUsersQuery({
    variables: {
      role: Roles.TRAINEE,
      hasTrainingCompany: true
    }
  })

  const traineeData = React.useMemo(() => {
    return {
      total: traineeCount?.countUsers || 0,
      withCompany: traineeCountCompany?.countUsers || 0
    }
  }, [traineeCount, traineeCountCompany])

  const loading = React.useMemo(() => {
    return registrationLoading || reviewLoading || rejectLoading || traineeCountLoading || traineeCountCompanyLoading
  }, [registrationLoading, reviewLoading, rejectLoading, traineeCountLoading, traineeCountCompanyLoading])

  const mappedYearData = React.useMemo(() => {
    if (!registrationCount) {
      return []
    }

    return [
      {
        name: 'registrationsClicks',
        color: palette.primary[900] || '',
        values: mapYearData(registrationCount.registeredTraineesCount || [])
      }
    ]
  }, [registrationCount])

  return <Box sx={{
    position: 'relative'
  }}
  >
    {loading && <LinearProgress sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0
    }}
    />}
    <Box py={4.5}
      sx={{
        opacity: loading ? 0 : 1
      }}
    >
      <Grid container spacing={2}>
        <Grid height="auto" item xs={9} md={10}>
          <DashboardYearOverview
            title={t('dashboard.admin.overviewNewUsers')}
            data={mappedYearData}
            showYAxis
            yBuffer={20}
          />
        </Grid>

        <Grid item xs={3} md={2}>
          <DashboardConversionRate
            primary={traineeData.total}
            secondary={traineeData.withCompany}
            hidePercent
            title={t('dashboard.admin.traineesOverall', { count: traineeData.total })}
            cardProps={{
              title: t('dashboard.admin.trainees'),
              contentContainerProps: {
                sx: {
                  mt: 1
                }
              }
            }}
            titlePrimary={t('dashboard.admin.traineesWithCompany', { count: traineeData.withCompany })}
            titleSecondary={t('dashboard.admin.traineesWithoutCompany', { count: traineeData.total - traineeData.withCompany })}
          ></DashboardConversionRate>
        </Grid>

        <Grid item xs={6}>
          <DashboardQuestionsStatus
            title={t('dashboard.newestQuestionsReview')}
            onLoaded={() => setReviewLoading(false)}
            reviewStatus={ReviewStatus.Review}
          />
        </Grid>
        <Grid item xs={6}>
          <DashboardQuestionsStatus
            title={t('dashboard.newestQuestionsRejected')}
            onLoaded={() => setRejectLoading(false)}
            reviewStatus={ReviewStatus.Rejected}
          />
        </Grid>
      </Grid>
    </Box>
  </Box>
}
