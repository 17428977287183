import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  JWT: any;
  JokerCosts: any;
  QuestionConfig: any;
  Texts: any;
  Upload: any;
};

export type Achievement = {
  __typename?: 'Achievement';
  availableAtLevel: Scalars['Int'];
  id: Scalars['String'];
  level: AchievementLevel;
  target: AchievementTarget;
  targetValue: Scalars['Int'];
  texts: Scalars['Texts'];
  type: AchievementType;
};

/** Achievement level */
export enum AchievementLevel {
  Bronze = 'Bronze',
  Gold = 'Gold',
  Silver = 'Silver'
}

/** Achievement target */
export enum AchievementTarget {
  AnswerStreak = 'AnswerStreak',
  AppStarted = 'AppStarted',
  CategoriesCompleted = 'CategoriesCompleted',
  CustomAvatarUploaded = 'CustomAvatarUploaded',
  DryRunPassed = 'DryRunPassed',
  DryRunStarted = 'DryRunStarted',
  DryRunStreak = 'DryRunStreak',
  DuelStarted = 'DuelStarted',
  DuelsWon = 'DuelsWon',
  ExamDateSet = 'ExamDateSet',
  FriendAdded = 'FriendAdded',
  FriendDuelStarted = 'FriendDuelStarted',
  IncentiveRedeemed = 'IncentiveRedeemed',
  JokersUsed = 'JokersUsed',
  PartnerScreenOpened = 'PartnerScreenOpened',
  PracticeProgress = 'PracticeProgress',
  QuestionsAnswered = 'QuestionsAnswered',
  TopicsCompleted = 'TopicsCompleted'
}

/** Achievement type */
export enum AchievementType {
  DryRun = 'DryRun',
  Duel = 'Duel',
  Other = 'Other',
  Partner = 'Partner',
  Practice = 'Practice',
  Social = 'Social'
}

export type AchievementValue = {
  __typename?: 'AchievementValue';
  target: AchievementTarget;
  userId: Scalars['String'];
  value: Scalars['Int'];
};

export type AppConfig = {
  __typename?: 'AppConfig';
  credits: Credits;
  jokerCosts: Scalars['JokerCosts'];
  levels: Array<Scalars['Int']>;
  points: Points;
};

export type Auth = {
  __typename?: 'Auth';
  /** JWT access token */
  accessToken: Scalars['JWT'];
  /** JWT refresh token */
  refreshToken: Scalars['JWT'];
  user: User;
};

/** Available auth providers */
export enum AuthProvider {
  Apple = 'Apple',
  Facebook = 'Facebook',
  Google = 'Google'
}

export type Category = {
  __typename?: 'Category';
  childCategories: Array<Category>;
  files: Array<File>;
  id: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  professionId: Scalars['String'];
  questionGroups: Array<QuestionGroup>;
  questions: Array<Question>;
  texts: Scalars['Texts'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type CompaniesFilterInput = {
  field: Scalars['String'];
  mode?: InputMaybe<FilterModes>;
  operator: FilterOperators;
  value?: InputMaybe<Scalars['JSON']>;
};

/** Properties by which users can be ordered. */
export enum CompaniesOrderField {
  Address = 'address',
  CategoryId = 'categoryId',
  City = 'city',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsPartner = 'isPartner',
  Name = 'name',
  PostalCode = 'postalCode'
}

export type CompaniesOrderInput = {
  direction: OrderDirection;
  field: CompaniesOrderField;
};

export type CompaniesPaginated = {
  __typename?: 'CompaniesPaginated';
  edges?: Maybe<Array<CompanyEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type Company = {
  __typename?: 'Company';
  address: Scalars['String'];
  category?: Maybe<CompanyCategory>;
  categoryId?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  credits?: Maybe<Scalars['JSON']>;
  files: Array<File>;
  id: Scalars['ID'];
  incentives: Array<Incentive>;
  isPartner: Scalars['Boolean'];
  jobs: Array<Job>;
  name: Scalars['String'];
  postalCode: Scalars['String'];
  texts: Scalars['Texts'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  users?: Maybe<Array<User>>;
};


export type CompanyCreditsArgs = {
  exceededCredits?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyCategory = {
  __typename?: 'CompanyCategory';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type CompanyCredit = {
  __typename?: 'CompanyCredit';
  companyId: Scalars['String'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  regionLocked?: Maybe<Scalars['Boolean']>;
  type: CompanyCreditType;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  usedAt?: Maybe<Scalars['DateTime']>;
  usedByUserId?: Maybe<Scalars['String']>;
  user: User;
};

/** CompanyCreditType of the company credit */
export enum CompanyCreditType {
  Contact = 'Contact',
  JobBasic = 'JobBasic',
  JobExtension = 'JobExtension',
  JobPremium = 'JobPremium',
  JobPro = 'JobPro',
  TalentsBasic = 'TalentsBasic',
  TalentsPremium = 'TalentsPremium',
  TalentsPro = 'TalentsPro'
}

export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor: Scalars['String'];
  node: Company;
};

export type ContactRequestInput = {
  device?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<Scalars['String']>;
  requestType?: InputMaybe<ContactRequestType>;
  subject: Scalars['String'];
  text: Scalars['String'];
  version?: InputMaybe<Scalars['String']>;
};

/** Possible reasons for contact requests. */
export enum ContactRequestType {
  Feedback = 'FEEDBACK',
  Idea = 'IDEA',
  Support = 'SUPPORT'
}

export type CreateCategoryInput = {
  parentId?: InputMaybe<Scalars['String']>;
  professionId: Scalars['String'];
  texts: Array<CreateTranslationInput>;
};

export type CreateCompanyCategoryInput = {
  companyId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateCompanyCreditInput = {
  companyId: Scalars['String'];
  type: CompanyCreditType;
};

export type CreateCompanyCreditsInput = {
  companyId: Scalars['String'];
  credits: Array<CompanyCreditType>;
};

export type CreateCompanyInput = {
  address: Scalars['String'];
  category?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  credits?: InputMaybe<Array<CompanyCreditType>>;
  isPartner?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  postalCode: Scalars['String'];
  texts: Array<CreateTranslationInput>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateExamDateInput = {
  examDate: Scalars['DateTime'];
  season: Season;
  year: Scalars['Int'];
};

export type CreateIncentiveInput = {
  companyId?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  couponCode: Scalars['String'];
  level?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['String']>;
  status: Status;
  texts: Array<CreateTranslationInput>;
};

export type CreateInstructorInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  inviteUser?: InputMaybe<Scalars['Boolean']>;
  lastname: Scalars['String'];
  traineeIds?: InputMaybe<Array<Scalars['String']>>;
  trainingCompanyIds?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateJobDepartmentInput = {
  texts?: InputMaybe<Array<CreateTranslationInput>>;
};

export type CreateJobEmploymentTypeInput = {
  texts?: InputMaybe<Array<CreateTranslationInput>>;
};

export type CreateJobInput = {
  code?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  jobDepartmentId: Scalars['String'];
  jobEmploymentTypeId: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  texts: Array<CreateTranslationInput>;
};

export type CreateProfessionInput = {
  config?: InputMaybe<Scalars['JSON']>;
  examDates: Array<CreateExamDateInput>;
  texts: Array<CreateTranslationInput>;
};

export type CreateQuestionGroupInput = {
  categoryId: Scalars['String'];
  texts: Array<CreateTranslationInput>;
};

export type CreateQuestionInput = {
  categoryId: Scalars['String'];
  config: Scalars['JSON'];
  inDuelAvailable: Scalars['Boolean'];
  questionGroupId?: InputMaybe<Scalars['String']>;
  questionGroupOrder?: InputMaybe<Scalars['Int']>;
  reviewStatus: ReviewStatus;
  texts: Array<CreateTranslationInput>;
  topicId?: InputMaybe<Scalars['String']>;
  type: QuestionType;
};

export type CreateTopicInput = {
  index: Scalars['Int'];
  professionId: Scalars['String'];
  texts: Array<CreateTranslationInput>;
  trainingYear: Scalars['Int'];
};

export type CreateTrackingInput = {
  model: Scalars['String'];
  modelId: Scalars['String'];
  type: Scalars['String'];
};

export type CreateTraineeInput = {
  instructorId?: InputMaybe<Scalars['String']>;
  trainingCompanyId: Scalars['String'];
};

export type CreateTrainingCompanyInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  address: Scalars['String'];
  city: Scalars['String'];
  instructorIds?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  postalCode: Scalars['String'];
  provided?: InputMaybe<Scalars['Boolean']>;
  texts: Array<CreateTranslationInput>;
};

export type CreateTranslationInput = {
  key: Scalars['String'];
  lang?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CreateUserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  examSeason?: InputMaybe<Season>;
  examYear?: InputMaybe<Scalars['Int']>;
  firstname: Scalars['String'];
  inviteUser?: InputMaybe<Scalars['Boolean']>;
  lastname: Scalars['String'];
  nextExamDate?: InputMaybe<Scalars['DateTime']>;
  roleId?: InputMaybe<Scalars['String']>;
  trainingCompanyId?: InputMaybe<Scalars['String']>;
  trainingYear?: InputMaybe<Scalars['Int']>;
};

export type Credits = {
  __typename?: 'Credits';
  achievementBronze: Scalars['Int'];
  achievementGold: Scalars['Int'];
  achievementSilver: Scalars['Int'];
  duelCancellation: Scalars['Int'];
  duelDraw: Scalars['Int'];
  duelWon: Scalars['Int'];
  levelUpMultiplier: Scalars['Int'];
};

export type DeleteCompaniesInput = {
  companyIds?: InputMaybe<Array<Scalars['String']>>;
};

export type DeleteIncentivesInput = {
  incentiveIds?: InputMaybe<Array<Scalars['String']>>;
};

export type DeleteJobDepartmentsInput = {
  ids?: InputMaybe<Scalars['String']>;
};

export type DeleteJobEmploymentTypeInput = {
  ids?: InputMaybe<Scalars['String']>;
};

export type DeleteJobsInput = {
  jobIds?: InputMaybe<Array<Scalars['String']>>;
};

export type DeleteTrainingCompaniesInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export type DryRun = {
  __typename?: 'DryRun';
  category: Category;
  categoryId: Scalars['String'];
  correctCount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  duration: Scalars['Int'];
  id: Scalars['String'];
  incorrectCount: Scalars['Int'];
  passed: Scalars['Boolean'];
  questionCount: Scalars['Int'];
  questions: Array<DryRunQuestion>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  /** The time spent on the dry run in seconds */
  time: Scalars['Int'];
  unansweredCount: Scalars['Int'];
  userId: Scalars['String'];
};

export type DryRunQuestion = {
  __typename?: 'DryRunQuestion';
  answer?: Maybe<Scalars['JSON']>;
  correct?: Maybe<Scalars['Boolean']>;
  dryRun: DryRun;
  dryRunId: Scalars['String'];
  question: Question;
  questionId: Scalars['String'];
  time?: Maybe<Scalars['Int']>;
};

export type Duel = {
  __typename?: 'Duel';
  canceledById?: Maybe<Scalars['String']>;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  endedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isBlocked: Scalars['Boolean'];
  isCompleted: Scalars['Boolean'];
  isMyTurn: Scalars['Boolean'];
  isPlayersTurn: Scalars['Boolean'];
  opponent?: Maybe<Player>;
  opponentId?: Maybe<Scalars['String']>;
  opponentRound: Scalars['Int'];
  player: Player;
  playerBegins: Scalars['Boolean'];
  playerId: Scalars['String'];
  playerRound: Scalars['Int'];
  rounds: Array<DuelRound>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  winnerId?: Maybe<Scalars['String']>;
};


export type DuelIsPlayersTurnArgs = {
  playerId: Scalars['String'];
};

export type DuelPoints = {
  __typename?: 'DuelPoints';
  duel: Scalars['Int'];
  question: Scalars['Int'];
  round: Scalars['Int'];
};

export type DuelQuestion = {
  __typename?: 'DuelQuestion';
  audienceAnswers?: Maybe<Scalars['JSON']>;
  opponentAnswer?: Maybe<Scalars['JSON']>;
  opponentCorrect?: Maybe<Scalars['Boolean']>;
  opponentJoker?: Maybe<Joker>;
  opponentTime?: Maybe<Scalars['Int']>;
  playerAnswer?: Maybe<Scalars['JSON']>;
  playerCorrect?: Maybe<Scalars['Boolean']>;
  playerJoker?: Maybe<Joker>;
  playerTime?: Maybe<Scalars['Int']>;
  question: Question;
  questionId: Scalars['String'];
  round: DuelRound;
  roundId: Scalars['String'];
};

export type DuelRound = {
  __typename?: 'DuelRound';
  category?: Maybe<Category>;
  categoryId: Scalars['String'];
  duel: Duel;
  duelId: Scalars['String'];
  id: Scalars['String'];
  joker?: Maybe<Scalars['String']>;
  opponentStartedAt?: Maybe<Scalars['DateTime']>;
  opponentTime?: Maybe<Scalars['Int']>;
  playerStartedAt?: Maybe<Scalars['DateTime']>;
  playerTime?: Maybe<Scalars['Int']>;
  questions: Array<DuelQuestion>;
  round: Scalars['Int'];
};

export type DuplicateQuestionInput = {
  categoryId: Scalars['String'];
};

export type ExamDate = {
  __typename?: 'ExamDate';
  examDate: Scalars['DateTime'];
  id: Scalars['String'];
  profession: Scalars['Texts'];
  professionId: Scalars['String'];
  season: Season;
  year: Scalars['Int'];
};

export type Favorite = {
  __typename?: 'Favorite';
  questionId: Scalars['String'];
  userId: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  config: Scalars['JSON'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  fileSize: Scalars['Int'];
  id: Scalars['ID'];
  key: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
  mimeType: Scalars['String'];
  modelId: Scalars['String'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

/** Properties which connector can be used for filtering */
export enum FilterConnectionOperators {
  And = 'AND',
  Or = 'OR'
}

/** Properties which filter mode can be used for filtering */
export enum FilterModes {
  Insensitive = 'insensitive',
  Sensitive = 'sensitive'
}

/** Properties by which operators can be used for filtering */
export enum FilterOperators {
  Contains = 'contains',
  Equals = 'equals',
  Gt = 'gt',
  Gte = 'gte',
  Is = 'is',
  Lt = 'lt',
  Lte = 'lte',
  Not = 'not'
}

/** Properties by which operators can be used for filtering */
export enum FilterRelationListOperators {
  Every = 'every',
  None = 'none',
  Some = 'some'
}

export type Friend = {
  __typename?: 'Friend';
  /** @deprecated Replace by status field */
  accepted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  fromUser: User;
  fromUserId: Scalars['String'];
  id: Scalars['String'];
  status: FriendStatus;
  toUser: User;
  toUserId: Scalars['String'];
};

/** Friend status */
export enum FriendStatus {
  Accepted = 'Accepted',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

/** Gender of the user */
export enum Gender {
  Female = 'Female',
  Male = 'Male',
  Other = 'Other'
}

export type Incentive = {
  __typename?: 'Incentive';
  bimonthlyPerformance: Scalars['Float'];
  clicks: Scalars['Int'];
  company?: Maybe<Company>;
  companyId: Scalars['String'];
  contact?: Maybe<Scalars['String']>;
  couponCode: Scalars['String'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  files: Array<File>;
  id: Scalars['ID'];
  level: Scalars['Int'];
  link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status: Status;
  subtitle?: Maybe<Scalars['String']>;
  texts: Scalars['Texts'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type InviteTraineeInput = {
  email: Scalars['String'];
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
};

export type Job = {
  __typename?: 'Job';
  bimonthlyPerformance: Scalars['Float'];
  clicks: Scalars['Int'];
  code?: Maybe<Scalars['String']>;
  company: Company;
  companyId: Scalars['String'];
  contact?: Maybe<Scalars['String']>;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  distance?: Maybe<Scalars['Float']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  extended: Scalars['Boolean'];
  favoriteCount: Scalars['Int'];
  files?: Maybe<Array<File>>;
  id: Scalars['ID'];
  isFavorite: Scalars['Boolean'];
  jobDepartment?: Maybe<JobDepartment>;
  jobDepartmentId: Scalars['String'];
  jobEmploymentType?: Maybe<JobEmploymentType>;
  jobEmploymentTypeId: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  status: Status;
  texts: Scalars['Texts'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type JobDepartment = {
  __typename?: 'JobDepartment';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  texts: Scalars['Texts'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type JobEmploymentType = {
  __typename?: 'JobEmploymentType';
  id: Scalars['String'];
  texts: Scalars['JSON'];
};

export type JobFavorite = {
  __typename?: 'JobFavorite';
  jobId: Scalars['String'];
  userId: Scalars['String'];
};

/** Joker types */
export enum Joker {
  Audience = 'Audience',
  HighlightCorrectAnswer = 'HighlightCorrectAnswer',
  RoundTimer = 'RoundTimer',
  ViewOpponentAnswer = 'ViewOpponentAnswer'
}

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
};

export type LoginWithSocialInput = {
  email: Scalars['String'];
  provider: AuthProvider;
  providerId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptCompanyInvitation: Scalars['Boolean'];
  acceptFriendRequest: Friend;
  acceptInvitation: Scalars['Boolean'];
  activateJob: Job;
  addCredits: Player;
  addDeviceToken: Scalars['Boolean'];
  addFavorite: Favorite;
  addJobFavorite: JobFavorite;
  addPoints: Player;
  applyJoker: DuelQuestion;
  assignInstructor: Trainee;
  cancelDuel: Duel;
  changePassword: User;
  confirmMail: Scalars['Boolean'];
  confirmTrainee: User;
  createCategory: Category;
  createCompany: Company;
  createCompanyCategory: CompanyCategory;
  createCompanyCredit: CompanyCredit;
  createCompanyCredits: Array<CompanyCredit>;
  createDryRun: DryRun;
  createFriendRequest: Friend;
  createIncentive: Incentive;
  createInstructor: User;
  createJob: Job;
  createJobDepartment: JobDepartment;
  createJobEmploymentType: JobEmploymentType;
  createNextDuelRound: Duel;
  createProfession: Profession;
  createQuestion: Question;
  createQuestionGroup: QuestionGroup;
  createStudyTime: StudyTime;
  createTopic: Topic;
  createTracking?: Maybe<Tracking>;
  createTrainee: Trainee;
  createTrainingCompany: TrainingCompany;
  createUser: User;
  deleteAccount: Scalars['Boolean'];
  deleteCategories?: Maybe<Array<Category>>;
  deleteCategory: Category;
  deleteCompanies?: Maybe<Company>;
  deleteCompany: Company;
  deleteCompanyCategory: CompanyCategory;
  deleteCompanyCredit: CompanyCredit;
  deleteFile: File;
  deleteFiles: Scalars['Boolean'];
  deleteIncentive: Incentive;
  deleteIncentives?: Maybe<Incentive>;
  deleteJob: Job;
  deleteJobDepartment: JobDepartment;
  deleteJobDepartments: Array<JobDepartment>;
  deleteJobEmploymentType: JobEmploymentType;
  deleteJobEmploymentTypes: Array<JobEmploymentType>;
  deleteJobs: Array<Job>;
  deleteMe: User;
  deleteProfession: Profession;
  deleteProfessions?: Maybe<Array<Profession>>;
  deleteQuestion: Question;
  deleteQuestionGroup: QuestionGroup;
  deleteQuestionGroups: Array<QuestionGroup>;
  deleteQuestions: Array<Question>;
  deleteTopic: Topic;
  deleteTopics: Array<Topic>;
  deleteTrainee: Trainee;
  deleteTrainingCompanies?: Maybe<TrainingCompany>;
  deleteTrainingCompany: TrainingCompany;
  deleteUser: User;
  deleteUsers?: Maybe<Array<User>>;
  disconnectTrainee: User;
  disconnectTrainees: Array<User>;
  duplicateQuestions: Array<Question>;
  extendJob: Job;
  forgotPassword: Scalars['Boolean'];
  invitationToken: Scalars['String'];
  inviteOrCreateTrainee: Scalars['Boolean'];
  inviteUser: Scalars['Boolean'];
  login: Auth;
  loginWithSocial: Auth;
  logout: Scalars['Boolean'];
  refreshToken: Token;
  rejectFriendRequest: Friend;
  removeDeviceToken: Scalars['Boolean'];
  removeFavorite: Favorite;
  removeJobFavorite: JobFavorite;
  replaceFileInOrder: File;
  requestCompanyUser: Scalars['Boolean'];
  requestContact: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  signup: Auth;
  signupCompany: Scalars['Boolean'];
  signupPlayer: Auth;
  signupTrainingCompany: Scalars['Boolean'];
  startDuel: Duel;
  startDuelRound: DuelRound;
  submitDryRun: DryRun;
  subscribeNotifications: Array<UserNotification>;
  testFCM: Scalars['Boolean'];
  unlockAchievement: UserAchievement;
  unsubscribeNotifications: Array<UserNotification>;
  updateAchievementValue: AchievementValue;
  updateCategory: Category;
  updateCompany: Company;
  updateCompanyCategory: CompanyCategory;
  updateDryRunQuestion: DryRunQuestion;
  updateDuelQuestionAnswer: DuelQuestion;
  updateDuelRound: Duel;
  updateFileInfo: File;
  updateIncentive: Incentive;
  updateInstructor: User;
  updateJob: Job;
  updateJobDepartment: JobDepartment;
  updateJobEmploymentType: JobEmploymentType;
  updateMe: User;
  updateMyCompany?: Maybe<Company>;
  updateProfession: Profession;
  updateQuestion: Question;
  updateQuestionGroup: QuestionGroup;
  updateQuestionsStatus: Array<Question>;
  updateStudyTime: StudyTime;
  updateTopic: Topic;
  updateTrainingCompanies: Array<TrainingCompany>;
  updateTrainingCompany: TrainingCompany;
  updateUser: User;
  updateUserAnswer: UserAnswerValue;
  updateUsers: Array<User>;
  uploadFile: File;
};


export type MutationAcceptCompanyInvitationArgs = {
  token: Scalars['String'];
};


export type MutationAcceptFriendRequestArgs = {
  id: Scalars['String'];
};


export type MutationAcceptInvitationArgs = {
  token: Scalars['String'];
};


export type MutationActivateJobArgs = {
  id: Scalars['String'];
};


export type MutationAddCreditsArgs = {
  credits: Scalars['Int'];
};


export type MutationAddDeviceTokenArgs = {
  token: Scalars['String'];
};


export type MutationAddFavoriteArgs = {
  questionId: Scalars['String'];
};


export type MutationAddJobFavoriteArgs = {
  jobId: Scalars['String'];
};


export type MutationAddPointsArgs = {
  points: Scalars['Int'];
};


export type MutationApplyJokerArgs = {
  joker: Joker;
  questionId: Scalars['String'];
  roundId: Scalars['String'];
};


export type MutationAssignInstructorArgs = {
  instructorId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCancelDuelArgs = {
  id: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  data: ChangePasswordInput;
};


export type MutationConfirmMailArgs = {
  token: Scalars['String'];
};


export type MutationConfirmTraineeArgs = {
  id: Scalars['String'];
};


export type MutationCreateCategoryArgs = {
  data: CreateCategoryInput;
};


export type MutationCreateCompanyArgs = {
  data: CreateCompanyInput;
};


export type MutationCreateCompanyCategoryArgs = {
  data: CreateCompanyCategoryInput;
};


export type MutationCreateCompanyCreditArgs = {
  data: CreateCompanyCreditInput;
};


export type MutationCreateCompanyCreditsArgs = {
  data: CreateCompanyCreditsInput;
};


export type MutationCreateDryRunArgs = {
  categoryId: Scalars['String'];
  duration: Scalars['Int'];
  questionIds: Array<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateFriendRequestArgs = {
  playerId: Scalars['String'];
};


export type MutationCreateIncentiveArgs = {
  data: CreateIncentiveInput;
};


export type MutationCreateInstructorArgs = {
  data: CreateInstructorInput;
};


export type MutationCreateJobArgs = {
  data: CreateJobInput;
};


export type MutationCreateJobDepartmentArgs = {
  data: CreateJobDepartmentInput;
};


export type MutationCreateJobEmploymentTypeArgs = {
  data: CreateJobEmploymentTypeInput;
};


export type MutationCreateNextDuelRoundArgs = {
  categoryId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  joker?: InputMaybe<Joker>;
};


export type MutationCreateProfessionArgs = {
  data: CreateProfessionInput;
};


export type MutationCreateQuestionArgs = {
  data: CreateQuestionInput;
};


export type MutationCreateQuestionGroupArgs = {
  data: CreateQuestionGroupInput;
};


export type MutationCreateStudyTimeArgs = {
  area?: InputMaybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  time?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateTopicArgs = {
  data: CreateTopicInput;
};


export type MutationCreateTrackingArgs = {
  data: CreateTrackingInput;
};


export type MutationCreateTraineeArgs = {
  data: CreateTraineeInput;
};


export type MutationCreateTrainingCompanyArgs = {
  data: CreateTrainingCompanyInput;
};


export type MutationCreateUserArgs = {
  data: CreateUserInput;
};


export type MutationDeleteAccountArgs = {
  validation: Scalars['String'];
};


export type MutationDeleteCategoriesArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['String'];
};


export type MutationDeleteCompaniesArgs = {
  data: DeleteCompaniesInput;
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['String'];
};


export type MutationDeleteCompanyCategoryArgs = {
  id: Scalars['String'];
};


export type MutationDeleteCompanyCreditArgs = {
  id: Scalars['String'];
};


export type MutationDeleteFileArgs = {
  id: Scalars['String'];
};


export type MutationDeleteFilesArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteIncentiveArgs = {
  id: Scalars['String'];
};


export type MutationDeleteIncentivesArgs = {
  data: DeleteIncentivesInput;
};


export type MutationDeleteJobArgs = {
  id: Scalars['String'];
};


export type MutationDeleteJobDepartmentArgs = {
  id: Scalars['String'];
};


export type MutationDeleteJobDepartmentsArgs = {
  data: DeleteJobDepartmentsInput;
};


export type MutationDeleteJobEmploymentTypeArgs = {
  id: Scalars['String'];
};


export type MutationDeleteJobEmploymentTypesArgs = {
  data: DeleteJobEmploymentTypeInput;
};


export type MutationDeleteJobsArgs = {
  data: DeleteJobsInput;
};


export type MutationDeleteProfessionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteProfessionsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteQuestionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteQuestionGroupArgs = {
  id: Scalars['String'];
};


export type MutationDeleteQuestionGroupsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteQuestionsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteTopicArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTopicsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteTrainingCompaniesArgs = {
  data: DeleteTrainingCompaniesInput;
};


export type MutationDeleteTrainingCompanyArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUsersArgs = {
  userIds: Array<Scalars['String']>;
};


export type MutationDisconnectTraineeArgs = {
  traineeId: Scalars['String'];
};


export type MutationDisconnectTraineesArgs = {
  traineeIds: Array<Scalars['String']>;
};


export type MutationDuplicateQuestionsArgs = {
  data: DuplicateQuestionInput;
  ids: Array<Scalars['String']>;
};


export type MutationExtendJobArgs = {
  id: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
  target?: InputMaybe<Scalars['String']>;
};


export type MutationInvitationTokenArgs = {
  id: Scalars['String'];
};


export type MutationInviteOrCreateTraineeArgs = {
  data: InviteTraineeInput;
};


export type MutationInviteUserArgs = {
  id: Scalars['String'];
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationLoginWithSocialArgs = {
  data: LoginWithSocialInput;
};


export type MutationLogoutArgs = {
  deviceToken?: InputMaybe<Scalars['String']>;
};


export type MutationRefreshTokenArgs = {
  token: Scalars['JWT'];
};


export type MutationRejectFriendRequestArgs = {
  id: Scalars['String'];
};


export type MutationRemoveDeviceTokenArgs = {
  token: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationRemoveFavoriteArgs = {
  questionId: Scalars['String'];
};


export type MutationRemoveJobFavoriteArgs = {
  jobId: Scalars['String'];
};


export type MutationReplaceFileInOrderArgs = {
  data: UploadFileInput;
  file: Scalars['Upload'];
  maxImageDimension?: InputMaybe<Scalars['Int']>;
};


export type MutationRequestCompanyUserArgs = {
  data: RequestCompanyUserInput;
};


export type MutationRequestContactArgs = {
  data: ContactRequestInput;
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSignupArgs = {
  data: SignupInput;
};


export type MutationSignupCompanyArgs = {
  data: SignupCompanyInput;
};


export type MutationSignupPlayerArgs = {
  data: SignupPlayerInput;
};


export type MutationSignupTrainingCompanyArgs = {
  data: SignupTrainingCompanyInput;
};


export type MutationStartDuelArgs = {
  opponentId?: InputMaybe<Scalars['String']>;
};


export type MutationStartDuelRoundArgs = {
  duelId: Scalars['String'];
};


export type MutationSubmitDryRunArgs = {
  id: Scalars['String'];
};


export type MutationSubscribeNotificationsArgs = {
  data: Array<SubscribeNotificationInput>;
};


export type MutationTestFcmArgs = {
  body: Scalars['String'];
  data: Scalars['JSON'];
  title: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUnlockAchievementArgs = {
  achievementId: Scalars['String'];
};


export type MutationUnsubscribeNotificationsArgs = {
  subjects: Array<Scalars['String']>;
};


export type MutationUpdateAchievementValueArgs = {
  target: AchievementTarget;
  value: Scalars['Int'];
};


export type MutationUpdateCategoryArgs = {
  data: UpdateCategoryInput;
  id: Scalars['String'];
};


export type MutationUpdateCompanyArgs = {
  data: UpdateCompanyInput;
  id: Scalars['String'];
};


export type MutationUpdateCompanyCategoryArgs = {
  data: UpdateCompanyCategoryInput;
  id: Scalars['String'];
};


export type MutationUpdateDryRunQuestionArgs = {
  data: UpdateDryRunQuestionInput;
  dryRunId: Scalars['String'];
  questionId: Scalars['String'];
};


export type MutationUpdateDuelQuestionAnswerArgs = {
  data: UpdateDuelQuestionAnswerInput;
  questionId: Scalars['String'];
  roundId: Scalars['String'];
};


export type MutationUpdateDuelRoundArgs = {
  roundId: Scalars['String'];
  time: Scalars['Int'];
};


export type MutationUpdateFileInfoArgs = {
  data: UpdateFileInput;
  id: Scalars['String'];
};


export type MutationUpdateIncentiveArgs = {
  data: UpdateIncentiveInput;
  id: Scalars['String'];
};


export type MutationUpdateInstructorArgs = {
  data: UpdateInstructorInput;
  id: Scalars['String'];
};


export type MutationUpdateJobArgs = {
  data: UpdateJobInput;
  id: Scalars['String'];
};


export type MutationUpdateJobDepartmentArgs = {
  data: UpdateJobDepartmentInput;
  id: Scalars['String'];
};


export type MutationUpdateJobEmploymentTypeArgs = {
  data: UpdateJobEmploymentTypeInput;
  id: Scalars['String'];
};


export type MutationUpdateMeArgs = {
  data: UpdateUserInput;
};


export type MutationUpdateMyCompanyArgs = {
  data: UpdateMyCompanyInput;
};


export type MutationUpdateProfessionArgs = {
  data: UpdateProfessionInput;
  id: Scalars['String'];
};


export type MutationUpdateQuestionArgs = {
  data: UpdateQuestionInput;
  id: Scalars['String'];
  removedTexts?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationUpdateQuestionGroupArgs = {
  data: UpdateQuestionGroupInput;
  id: Scalars['String'];
};


export type MutationUpdateQuestionsStatusArgs = {
  ids: Array<Scalars['String']>;
  reviewStatus: ReviewStatus;
};


export type MutationUpdateStudyTimeArgs = {
  id: Scalars['String'];
  time: Scalars['Int'];
};


export type MutationUpdateTopicArgs = {
  data: UpdateTopicInput;
  id: Scalars['String'];
};


export type MutationUpdateTrainingCompaniesArgs = {
  data: UpdateTrainingCompaniesInput;
};


export type MutationUpdateTrainingCompanyArgs = {
  data: UpdateTrainingCompanyInput;
  id: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
  id: Scalars['String'];
};


export type MutationUpdateUserAnswerArgs = {
  data: UpdateUserAnswerInput;
};


export type MutationUpdateUsersArgs = {
  data: UpdateUsersInput;
};


export type MutationUploadFileArgs = {
  data: UploadFileInput;
  file: Scalars['Upload'];
  maxImageDimension?: InputMaybe<Scalars['Int']>;
};

/** Notification subjects for the user */
export enum NotificationSubjects {
  NewCompany = 'NewCompany',
  NewTrainee = 'NewTrainee',
  NewTrainingCompany = 'NewTrainingCompany',
  RequestUser = 'RequestUser',
  TrainingCompanyVerified = 'TrainingCompanyVerified'
}

/** Notification type for the user */
export enum NotificationType {
  Mail = 'Mail',
  Push = 'Push'
}

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Permission = {
  __typename?: 'Permission';
  ability: Scalars['String'];
  id: Scalars['String'];
  resource: Scalars['String'];
};

export type Player = {
  __typename?: 'Player';
  avatar: Scalars['String'];
  credits: Scalars['Int'];
  duelMaxRanking?: Maybe<Scalars['Int']>;
  duelRanking?: Maybe<Scalars['Int']>;
  duelStatistic: Scalars['JSON'];
  duels: Array<Duel>;
  files: Array<File>;
  id: Scalars['String'];
  level: Scalars['Int'];
  points: Scalars['Int'];
  user: User;
  userId: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};

export type Points = {
  __typename?: 'Points';
  duel: DuelPoints;
  practice: PracticePoints;
};

export type PostalCode = {
  __typename?: 'PostalCode';
  code: Scalars['String'];
  distance?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  texts: Scalars['Texts'];
};

export type PracticePoints = {
  __typename?: 'PracticePoints';
  correct: Scalars['Int'];
  explanationView: Scalars['Int'];
  partiallyCorrect: Scalars['Int'];
  unanswered: Scalars['Int'];
};

export type Profession = {
  __typename?: 'Profession';
  categories: Array<Category>;
  config: Scalars['JSON'];
  examDates: Array<ExamDate>;
  id: Scalars['String'];
  status: Status;
  texts: Scalars['Texts'];
  topics: Array<Topic>;
};


export type ProfessionExamDatesArgs = {
  onlyUpcoming?: InputMaybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  achievement: Achievement;
  achievements: Array<Achievement>;
  appConfig: AppConfig;
  categories: Array<Category>;
  category: Category;
  checkInvitationToken: Scalars['Boolean'];
  checkUsername: Scalars['Boolean'];
  companies: Array<Company>;
  companiesPaginated: CompaniesPaginated;
  company?: Maybe<Company>;
  companyCategories: Array<CompanyCategory>;
  companyCredits: Array<CompanyCredit>;
  countUsers: Scalars['Int'];
  dashboardTrackingIncentive: Scalars['JSON'];
  dashboardTrackingIncentives: Scalars['JSON'];
  dashboardTrackingJob: Scalars['JSON'];
  dashboardTrackingJobs: Scalars['JSON'];
  dryRun: DryRun;
  duel: Duel;
  examDate: ExamDate;
  examDates: Array<ExamDate>;
  file: File;
  filterCompanies: Array<Company>;
  filterTrainingCompanies: Array<TrainingCompany>;
  filterUsers: Array<User>;
  incentive?: Maybe<Incentive>;
  incentives: Array<Incentive>;
  isUsernameAvailable: Scalars['Boolean'];
  job?: Maybe<Job>;
  jobDepartment: JobDepartment;
  jobDepartments: Array<JobDepartment>;
  jobEmploymentType: JobEmploymentType;
  jobEmploymentTypes: Array<JobEmploymentType>;
  jobFavorites: Array<Job>;
  jobs: Array<Job>;
  legalText: Scalars['String'];
  me: User;
  myAnswers: Array<UserAnswer>;
  myCompany?: Maybe<Company>;
  myDryRuns: Array<DryRun>;
  partners: Array<Company>;
  postalCodeByCoordinates?: Maybe<Scalars['String']>;
  postalCodes: Array<PostalCode>;
  postalCodesInRadius: Array<PostalCode>;
  profession: Profession;
  professions: Array<Profession>;
  question: Question;
  questionGroup: QuestionGroup;
  questionGroups: Array<QuestionGroup>;
  questions: Array<Question>;
  questionsCount: Scalars['Int'];
  questionsPaginated: QuestionsPaginated;
  registeredTraineesCount: Scalars['JSON'];
  roles: Array<Role>;
  searchJobs: Array<Job>;
  searchTrainingCompanies: Array<TrainingCompany>;
  status: Scalars['String'];
  structure: Array<Category>;
  topic: Topic;
  topics: Array<Topic>;
  trackings: Array<Tracking>;
  trainingCompanies?: Maybe<Array<TrainingCompany>>;
  trainingCompaniesPaginated: TrainingCompaniesPaginated;
  trainingCompany?: Maybe<TrainingCompany>;
  user: User;
  userAnswers: Array<UserAnswer>;
  userExists: Scalars['Boolean'];
  userNotification: UserNotification;
  userNotifications: Array<UserNotification>;
  users: Array<User>;
  usersPaginated: UsersPaginated;
};


export type QueryAchievementArgs = {
  id: Scalars['String'];
};


export type QueryCategoriesArgs = {
  parentId?: InputMaybe<Scalars['String']>;
};


export type QueryCategoryArgs = {
  id: Scalars['String'];
};


export type QueryCheckInvitationTokenArgs = {
  token: Scalars['String'];
};


export type QueryCheckUsernameArgs = {
  username: Scalars['String'];
};


export type QueryCompaniesPaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<CompaniesFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CompaniesOrderInput>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryCompanyArgs = {
  id: Scalars['String'];
};


export type QueryCountUsersArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  hasTrainingCompany?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Scalars['String']>;
};


export type QueryDashboardTrackingIncentiveArgs = {
  incentiveId: Scalars['String'];
};


export type QueryDashboardTrackingIncentivesArgs = {
  companyId?: InputMaybe<Scalars['String']>;
};


export type QueryDashboardTrackingJobArgs = {
  jobId: Scalars['String'];
};


export type QueryDashboardTrackingJobsArgs = {
  companyId?: InputMaybe<Scalars['String']>;
};


export type QueryDryRunArgs = {
  id: Scalars['String'];
};


export type QueryDuelArgs = {
  id: Scalars['String'];
};


export type QueryExamDateArgs = {
  id: Scalars['String'];
};


export type QueryExamDatesArgs = {
  professionId?: InputMaybe<Scalars['String']>;
};


export type QueryFileArgs = {
  id: Scalars['String'];
};


export type QueryFilterCompaniesArgs = {
  filter?: InputMaybe<Array<CompaniesFilterInput>>;
};


export type QueryFilterTrainingCompaniesArgs = {
  filter?: InputMaybe<Array<TrainingCompaniesFilterInput>>;
};


export type QueryFilterUsersArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Array<UserFilterInput>>;
  roles?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryIncentiveArgs = {
  id: Scalars['String'];
};


export type QueryIncentivesArgs = {
  companyId?: InputMaybe<Scalars['String']>;
};


export type QueryIsUsernameAvailableArgs = {
  username: Scalars['String'];
};


export type QueryJobArgs = {
  id: Scalars['String'];
};


export type QueryJobDepartmentArgs = {
  id: Scalars['String'];
};


export type QueryJobEmploymentTypeArgs = {
  id: Scalars['String'];
};


export type QueryJobsArgs = {
  companyId?: InputMaybe<Scalars['String']>;
};


export type QueryLegalTextArgs = {
  key: Scalars['String'];
};


export type QueryPartnersArgs = {
  hasIncentives?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPostalCodeByCoordinatesArgs = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};


export type QueryPostalCodesArgs = {
  search?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryPostalCodesInRadiusArgs = {
  code: Scalars['String'];
  radius: Scalars['Int'];
};


export type QueryProfessionArgs = {
  id: Scalars['String'];
};


export type QueryQuestionArgs = {
  id: Scalars['String'];
};


export type QueryQuestionGroupArgs = {
  id: Scalars['String'];
};


export type QueryQuestionGroupsArgs = {
  categoryId?: InputMaybe<Scalars['String']>;
};


export type QueryQuestionsArgs = {
  data: QueryQuestionsInput;
};


export type QueryQuestionsCountArgs = {
  connector?: InputMaybe<FilterConnectionOperators>;
  filter?: InputMaybe<Array<QuestionsFilterInput>>;
};


export type QueryQuestionsPaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  connector?: InputMaybe<FilterConnectionOperators>;
  filter?: InputMaybe<Array<QuestionsFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<QuestionsOrderInput>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryRegisteredTraineesCountArgs = {
  monthsBack: Scalars['Int'];
};


export type QuerySearchJobsArgs = {
  jobDepartmentId?: InputMaybe<Scalars['String']>;
  jobEmploymentTypeId?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  radius?: InputMaybe<Scalars['Int']>;
};


export type QuerySearchTrainingCompaniesArgs = {
  searchInput: Scalars['String'];
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryTopicArgs = {
  id: Scalars['String'];
};


export type QueryTopicsArgs = {
  professionId?: InputMaybe<Scalars['String']>;
};


export type QueryTrackingsArgs = {
  filter?: InputMaybe<Array<TrackingFilterInput>>;
  tartgetFilter?: InputMaybe<TrackingTargetFilterInput>;
};


export type QueryTrainingCompaniesPaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TrainingCompaniesFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TrainingCompaniesOrderInput>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryTrainingCompanyArgs = {
  id: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserAnswersArgs = {
  userId: Scalars['String'];
};


export type QueryUserExistsArgs = {
  email: Scalars['String'];
};


export type QueryUserNotificationArgs = {
  subject: Scalars['String'];
};


export type QueryUsersArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryUsersPaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Array<UserFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserOrderInput>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryQuestionsInput = {
  connector?: InputMaybe<FilterConnectionOperators>;
  count?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Array<QuestionsFilterInput>>;
  orderBy?: InputMaybe<Array<QuestionsOrderInput>>;
};

export type Question = {
  __typename?: 'Question';
  answer?: Maybe<UserAnswerValue>;
  category: Category;
  categoryId: Scalars['String'];
  config: Scalars['QuestionConfig'];
  files: Array<File>;
  id: Scalars['String'];
  inDuelAvailable: Scalars['Boolean'];
  isFavorite: Scalars['Boolean'];
  key: Scalars['Int'];
  profession: Profession;
  questionGroup?: Maybe<QuestionGroup>;
  questionGroupId?: Maybe<Scalars['String']>;
  questionGroupOrder?: Maybe<Scalars['Int']>;
  questionKey: Scalars['String'];
  reviewStatus: ReviewStatus;
  texts: Scalars['Texts'];
  topic?: Maybe<Topic>;
  topicId?: Maybe<Scalars['String']>;
  type: QuestionType;
};

export type QuestionEdge = {
  __typename?: 'QuestionEdge';
  cursor: Scalars['String'];
  node: Question;
};

export type QuestionGroup = {
  __typename?: 'QuestionGroup';
  category: Array<Category>;
  categoryId: Scalars['String'];
  files: Array<File>;
  id: Scalars['String'];
  questionCount: Scalars['Int'];
  questions: Array<Question>;
  texts: Scalars['Texts'];
};

/** The type of question */
export enum QuestionType {
  Assignment = 'Assignment',
  Choice = 'Choice',
  Date = 'Date',
  DragDrop = 'DragDrop',
  NumericValue = 'NumericValue',
  Sort = 'Sort',
  Time = 'Time'
}

export type QuestionsFilterInput = {
  field: Scalars['String'];
  mode?: InputMaybe<FilterModes>;
  operator: FilterOperators;
  value?: InputMaybe<Scalars['JSON']>;
};

/** Properties by which questions can be ordered. */
export enum QuestionsOrderField {
  CreatedAt = 'createdAt',
  InDuelAvailable = 'inDuelAvailable',
  Key = 'key',
  ReviewStatus = 'reviewStatus',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type QuestionsOrderInput = {
  direction: OrderDirection;
  field: QuestionsOrderField;
};

export type QuestionsPaginated = {
  __typename?: 'QuestionsPaginated';
  edges?: Maybe<Array<QuestionEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type RequestCompanyUserInput = {
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
};

/** The review status of question */
export enum ReviewStatus {
  Approved = 'Approved',
  Draft = 'Draft',
  Rejected = 'Rejected',
  Review = 'Review'
}

export type Role = {
  __typename?: 'Role';
  ancestorId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  key: Scalars['String'];
  permissions: Array<Permission>;
};

/** Season */
export enum Season {
  Autumn = 'Autumn',
  Spring = 'Spring',
  Summer = 'Summer',
  Winter = 'Winter'
}

export type SignupCompanyInput = {
  address: Scalars['String'];
  categoryId: Scalars['String'];
  city: Scalars['String'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  postalCode: Scalars['String'];
};

export type SignupInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  password: Scalars['String'];
};

export type SignupPlayerInput = {
  authProvider?: InputMaybe<AuthProvider>;
  authProviderId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  gender?: InputMaybe<Gender>;
  lastname: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};

export type SignupTrainingCompanyInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  postalCode: Scalars['String'];
};

/** Status of the incentive */
export enum Status {
  Active = 'Active',
  Draft = 'Draft',
  Inactive = 'Inactive'
}

/** StudyStatus of the user */
export enum StudyStatus {
  Done = 'DONE',
  Exam = 'EXAM',
  Failed = 'FAILED',
  Learning = 'LEARNING',
  Ready = 'READY'
}

export type StudyTime = {
  __typename?: 'StudyTime';
  id: Scalars['String'];
  startedAt: Scalars['DateTime'];
  time: Scalars['Int'];
  topic: Scalars['String'];
  userId: Scalars['String'];
};

export type SubscribeNotificationInput = {
  subject: NotificationSubjects;
  type: NotificationType;
};

export type Subscription = {
  __typename?: 'Subscription';
  duelUpdated: Duel;
  friendRequestAccepted: Friend;
  friendRequestCreated: Friend;
  meUpdated: User;
  playerUpdated: Player;
};

export type Token = {
  __typename?: 'Token';
  /** JWT access token */
  accessToken: Scalars['JWT'];
  /** JWT refresh token */
  refreshToken: Scalars['JWT'];
};

export type Topic = {
  __typename?: 'Topic';
  id: Scalars['String'];
  index: Scalars['Int'];
  profession: Profession;
  professionId: Scalars['String'];
  questions: Array<Question>;
  texts: Scalars['Texts'];
  trainingYear: Scalars['Int'];
};

export type Tracking = {
  __typename?: 'Tracking';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  targets?: Maybe<Array<TrackingTarget>>;
  type: Scalars['String'];
  user?: Maybe<User>;
};

export type TrackingFilterInput = {
  field: Scalars['String'];
  mode?: InputMaybe<FilterModes>;
  operator: FilterOperators;
  value?: InputMaybe<Scalars['JSON']>;
};

export type TrackingTarget = {
  __typename?: 'TrackingTarget';
  id: Scalars['String'];
  model: Scalars['String'];
  modelId: Scalars['String'];
  trackingId: Scalars['String'];
};

export type TrackingTargetFilterInput = {
  model?: InputMaybe<Scalars['String']>;
  modelId?: InputMaybe<Scalars['String']>;
  operator: FilterRelationListOperators;
};

export type Trainee = {
  __typename?: 'Trainee';
  confirmed: Scalars['Boolean'];
  instructor?: Maybe<User>;
  instructorId?: Maybe<Scalars['String']>;
  trainingCompany?: Maybe<TrainingCompany>;
  trainingCompanyId: Scalars['String'];
  userId: Scalars['String'];
};

export type TrainingCompaniesFilterInput = {
  field: Scalars['String'];
  mode?: InputMaybe<FilterModes>;
  operator: FilterOperators;
  value?: InputMaybe<Scalars['JSON']>;
};

export type TrainingCompaniesOrderInput = {
  direction: OrderDirection;
  field: TrainingCompanyOrderField;
};

export type TrainingCompaniesPaginated = {
  __typename?: 'TrainingCompaniesPaginated';
  edges?: Maybe<Array<TrainingCompanyEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TrainingCompany = {
  __typename?: 'TrainingCompany';
  active: Scalars['Boolean'];
  address: Scalars['String'];
  city: Scalars['String'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  files: Array<File>;
  id: Scalars['ID'];
  instructors: Array<User>;
  name: Scalars['String'];
  postalCode: Scalars['String'];
  provided: Scalars['Boolean'];
  texts: Scalars['Texts'];
  trainees: Array<User>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};


export type TrainingCompanyTraineesArgs = {
  all?: InputMaybe<Scalars['Boolean']>;
};

export type TrainingCompanyEdge = {
  __typename?: 'TrainingCompanyEdge';
  cursor: Scalars['String'];
  node: TrainingCompany;
};

/** Properties by which training companies can be ordered. */
export enum TrainingCompanyOrderField {
  Active = 'active',
  Address = 'address',
  City = 'city',
  CreatedAt = 'createdAt',
  Name = 'name',
  PostalCode = 'postalCode',
  Provided = 'provided'
}

export type UpdateCategoryInput = {
  parentId?: InputMaybe<Scalars['String']>;
  professionId?: InputMaybe<Scalars['String']>;
  texts?: InputMaybe<Array<CreateTranslationInput>>;
};

export type UpdateCompanyCategoryInput = {
  companyId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateCompanyInput = {
  address?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  credits?: InputMaybe<Array<CompanyCreditType>>;
  isPartner: Scalars['Boolean'];
  name: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  texts: Array<CreateTranslationInput>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateDryRunQuestionInput = {
  answer: Scalars['JSON'];
  correct: Scalars['Boolean'];
  time: Scalars['Int'];
};

export type UpdateDuelQuestionAnswerInput = {
  correct: Scalars['Boolean'];
  time: Scalars['Int'];
  value?: InputMaybe<Scalars['JSON']>;
};

export type UpdateExamDateInput = {
  examDate: Scalars['DateTime'];
  id: Scalars['String'];
  season: Season;
  year: Scalars['Int'];
};

export type UpdateFileInput = {
  config?: InputMaybe<Scalars['JSON']>;
  key?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['JSON']>;
};

export type UpdateIncentiveInput = {
  companyId?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  couponCode?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  texts?: InputMaybe<Array<CreateTranslationInput>>;
};

export type UpdateInstructorInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  traineeIds?: InputMaybe<Array<Scalars['String']>>;
  trainingCompaniesIds?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateJobDepartmentInput = {
  texts?: InputMaybe<Array<CreateTranslationInput>>;
};

export type UpdateJobEmploymentTypeInput = {
  texts?: InputMaybe<Array<CreateTranslationInput>>;
};

export type UpdateJobInput = {
  code?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  jobDepartmentId?: InputMaybe<Scalars['String']>;
  jobEmploymentTypeId?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  texts?: InputMaybe<Array<CreateTranslationInput>>;
};

export type UpdateMyCompanyInput = {
  address?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  texts: Array<CreateTranslationInput>;
};

export type UpdatePlayerInput = {
  avatar?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpdateProfessionInput = {
  config?: InputMaybe<Scalars['JSON']>;
  examDates: Array<UpdateExamDateInput>;
  status?: InputMaybe<Status>;
  texts?: InputMaybe<Array<CreateTranslationInput>>;
};

export type UpdateQuestionGroupInput = {
  questionIdsOrder?: InputMaybe<Array<Scalars['String']>>;
  texts?: InputMaybe<Array<CreateTranslationInput>>;
};

export type UpdateQuestionInput = {
  categoryId: Scalars['String'];
  config?: InputMaybe<Scalars['JSON']>;
  inDuelAvailable?: InputMaybe<Scalars['Boolean']>;
  questionGroupId?: InputMaybe<Scalars['String']>;
  questionGroupOrder?: InputMaybe<Scalars['Int']>;
  reviewStatus?: InputMaybe<ReviewStatus>;
  texts?: InputMaybe<Array<CreateTranslationInput>>;
  topicId?: InputMaybe<Scalars['String']>;
};

export type UpdateTopicInput = {
  index?: InputMaybe<Scalars['Int']>;
  texts?: InputMaybe<Array<CreateTranslationInput>>;
  trainingYear?: InputMaybe<Scalars['Int']>;
};

export type UpdateTrainingCompaniesInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  ids: Array<Scalars['String']>;
  provided?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateTrainingCompanyInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  instructorIds?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  provided?: InputMaybe<Scalars['Boolean']>;
  texts: Array<CreateTranslationInput>;
};

export type UpdateUserAnswerInput = {
  questionId: Scalars['String'];
  userId: Scalars['String'];
  value: UserAnswerValue;
};

export type UpdateUserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  examSeason?: InputMaybe<Season>;
  examYear?: InputMaybe<Scalars['Int']>;
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  nextExamDate?: InputMaybe<Scalars['DateTime']>;
  player?: InputMaybe<UpdatePlayerInput>;
  professionId?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
  trainingCompanyId?: InputMaybe<Scalars['String']>;
  trainingYear?: InputMaybe<Scalars['Int']>;
};

export type UpdateUsersInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  ids: Array<Scalars['String']>;
};

export type UploadFileInput = {
  config?: InputMaybe<Scalars['JSON']>;
  key: Scalars['String'];
  meta?: InputMaybe<Scalars['JSON']>;
  model: Scalars['String'];
  modelId: Scalars['String'];
  replace?: InputMaybe<Scalars['Boolean']>;
  replaceId?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  achievementValues: Array<AchievementValue>;
  achievements: Array<UserAchievement>;
  active: Scalars['Boolean'];
  answers: User;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  deviceTokens: Array<Scalars['String']>;
  dryRuns?: Maybe<Array<DryRun>>;
  email: Scalars['String'];
  examDate?: Maybe<Scalars['DateTime']>;
  favorites: Array<Question>;
  files: Array<File>;
  firstname: Scalars['String'];
  friends: Array<Friend>;
  gender?: Maybe<Gender>;
  id: Scalars['ID'];
  instructor?: Maybe<User>;
  instructorId?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  learningProgress?: Maybe<Scalars['JSON']>;
  nextExamDate?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<Array<UserNotification>>;
  overallStudyTime: Scalars['Int'];
  player?: Maybe<Player>;
  profession?: Maybe<Profession>;
  professionId?: Maybe<Scalars['String']>;
  role: Role;
  roleId: Scalars['String'];
  studyStatus: StudyStatus;
  studyTimes: Array<UserStudyTime>;
  trainee?: Maybe<Trainee>;
  traineeCount: Scalars['Int'];
  trainees?: Maybe<Array<User>>;
  trainingCompanies: Array<TrainingCompany>;
  trainingCompany?: Maybe<TrainingCompany>;
  trainingYear?: Maybe<Scalars['Int']>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};


export type UserLearningProgressArgs = {
  withTopics?: InputMaybe<Scalars['Boolean']>;
};


export type UserStudyStatusArgs = {
  categoryId?: InputMaybe<Scalars['String']>;
};

export type UserAchievement = {
  __typename?: 'UserAchievement';
  achievedAt: Scalars['DateTime'];
  achievementId: Scalars['String'];
  userId: Scalars['String'];
};

export type UserAnswer = {
  __typename?: 'UserAnswer';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  questionId: Scalars['String'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  value: UserAnswerValue;
};

/**
 * The value/evaluation of a user answer.
 *
 * Correct: user answered correctly twice
 * PartiallyCorrect: user answered correctly once
 * Incorrect: users latest answer was incorrect
 */
export enum UserAnswerValue {
  Correct = 'Correct',
  Incorrect = 'Incorrect',
  PartiallyCorrect = 'PartiallyCorrect'
}

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node: User;
};

export type UserFilterInput = {
  field: Scalars['String'];
  mode?: InputMaybe<FilterModes>;
  operator: FilterOperators;
  value?: InputMaybe<Scalars['JSON']>;
};

export type UserNotification = {
  __typename?: 'UserNotification';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  subject: NotificationSubjects;
  type: NotificationType;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

/** Properties by which users can be ordered. */
export enum UserOrderField {
  Active = 'active',
  CreatedAt = 'createdAt',
  Email = 'email',
  Firstname = 'firstname',
  Lastname = 'lastname',
  TrainingYear = 'trainingYear'
}

export type UserOrderInput = {
  direction: OrderDirection;
  field: UserOrderField;
  model?: InputMaybe<Scalars['String']>;
};

export type UserStudyTime = {
  __typename?: 'UserStudyTime';
  date: Scalars['DateTime'];
  time: Scalars['Int'];
};

export type UsersPaginated = {
  __typename?: 'UsersPaginated';
  edges?: Maybe<Array<UserEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type LegalTextQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type LegalTextQuery = { __typename?: 'Query', legalText: string };

export type LoginMutationVariables = Exact<{
  data: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'Auth', accessToken: any, refreshToken: any, user: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, role: { __typename?: 'Role', key: string, permissions: Array<{ __typename?: 'Permission', ability: string, resource: string }> }, company?: { __typename?: 'Company', isPartner: boolean } | null } } };

export type SignupTrainingCompanyMutationVariables = Exact<{
  data: SignupTrainingCompanyInput;
}>;


export type SignupTrainingCompanyMutation = { __typename?: 'Mutation', signupTrainingCompany: boolean };

export type SignupCompanyMutationVariables = Exact<{
  data: SignupCompanyInput;
}>;


export type SignupCompanyMutation = { __typename?: 'Mutation', signupCompany: boolean };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type RefreshTokenMutationVariables = Exact<{
  token: Scalars['JWT'];
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken: { __typename?: 'Token', accessToken: any, refreshToken: any } };

export type ChangePasswordMutationVariables = Exact<{
  data: ChangePasswordInput;
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'User', id: string } };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type ConfirmMailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ConfirmMailMutation = { __typename?: 'Mutation', confirmMail: boolean };

export type CategoryDataFragment = { __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> };

export type CategoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CategoryQuery = { __typename?: 'Query', category: { __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> } };

export type CategoryWithQuestionsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CategoryWithQuestionsQuery = { __typename?: 'Query', category: { __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, questions: Array<{ __typename?: 'Question', id: string, type: QuestionType, texts: any, inDuelAvailable: boolean, config: any, questionGroupId?: string | null, questionGroupOrder?: number | null, reviewStatus: ReviewStatus, topicId?: string | null, questionKey: string, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, topic?: { __typename?: 'Topic', texts: any, index: number } | null, questionGroup?: { __typename?: 'QuestionGroup', texts: any } | null, category: { __typename?: 'Category', id: string, parentId?: string | null, texts: any }, profession: { __typename?: 'Profession', id: string, texts: any } }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> } };

export type CategoryWithQuestionGroupsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CategoryWithQuestionGroupsQuery = { __typename?: 'Query', category: { __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, questions: Array<{ __typename?: 'Question', id: string, texts: any }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> } };

export type CategoriesQueryVariables = Exact<{
  parentId?: InputMaybe<Scalars['String']>;
}>;


export type CategoriesQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> }> };

export type CategoriesWithChildrenQueryVariables = Exact<{
  parentId?: InputMaybe<Scalars['String']>;
}>;


export type CategoriesWithChildrenQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, childCategories: Array<{ __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> }> };

export type CreateCategoryMutationVariables = Exact<{
  data: CreateCategoryInput;
}>;


export type CreateCategoryMutation = { __typename?: 'Mutation', createCategory: { __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> } };

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCategoryMutation = { __typename?: 'Mutation', deleteCategory: { __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> } };

export type DeleteCategoriesMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteCategoriesMutation = { __typename?: 'Mutation', deleteCategories?: Array<{ __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> }> | null };

export type UpdateCategoryMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateCategoryInput;
}>;


export type UpdateCategoryMutation = { __typename?: 'Mutation', updateCategory: { __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> } };

export type CompanyMinimalFragment = { __typename?: 'Company', id: string, name: string, isPartner: boolean, categoryId?: string | null, credits?: any | null, createdAt: any, postalCode: string, address: string, city: string, texts: any, category?: { __typename?: 'CompanyCategory', id: string, name: string } | null, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, users?: Array<{ __typename?: 'User', id: string, email: string }> | null };

export type MyCompanyMinimalFragment = { __typename?: 'Company', id: string, name: string, isPartner: boolean, credits?: any | null, postalCode: string, address: string, city: string, texts: any, createdAt: any, category?: { __typename?: 'CompanyCategory', id: string, name: string } | null, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> };

export type MyCompanyJobCreditsFragment = { __typename?: 'Company', id: string, isPartner: boolean, credits?: any | null };

export type MyCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCompanyQuery = { __typename?: 'Query', myCompany?: { __typename?: 'Company', id: string, name: string, isPartner: boolean, credits?: any | null, postalCode: string, address: string, city: string, texts: any, createdAt: any, category?: { __typename?: 'CompanyCategory', id: string, name: string } | null, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> } | null };

export type MyCompanyCreditsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCompanyCreditsQuery = { __typename?: 'Query', myCompany?: { __typename?: 'Company', id: string, isPartner: boolean, credits?: any | null } | null };

export type JobCreditsQueryVariables = Exact<{ [key: string]: never; }>;


export type JobCreditsQuery = { __typename?: 'Query', myCompany?: { __typename?: 'Company', id: string, isPartner: boolean, credits?: any | null } | null };

export type CompanyQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CompanyQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, name: string, isPartner: boolean, categoryId?: string | null, credits?: any | null, createdAt: any, postalCode: string, address: string, city: string, texts: any, category?: { __typename?: 'CompanyCategory', id: string, name: string } | null, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, users?: Array<{ __typename?: 'User', id: string, email: string }> | null } | null };

export type CompanyWithCreditsQueryVariables = Exact<{
  id: Scalars['String'];
  exceededCredits: Scalars['Boolean'];
}>;


export type CompanyWithCreditsQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, name: string, isPartner: boolean, credits?: any | null } | null };

export type CompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesQuery = { __typename?: 'Query', companies: Array<{ __typename?: 'Company', id: string, name: string, isPartner: boolean, categoryId?: string | null, credits?: any | null, createdAt: any, postalCode: string, address: string, city: string, texts: any, category?: { __typename?: 'CompanyCategory', id: string, name: string } | null, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, users?: Array<{ __typename?: 'User', id: string, email: string }> | null }> };

export type CompaniesPaginatedQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CompaniesOrderInput>;
  filter?: InputMaybe<Array<CompaniesFilterInput> | CompaniesFilterInput>;
}>;


export type CompaniesPaginatedQuery = { __typename?: 'Query', companiesPaginated: { __typename?: 'CompaniesPaginated', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges?: Array<{ __typename?: 'CompanyEdge', cursor: string, node: { __typename?: 'Company', id: string, name: string, isPartner: boolean, categoryId?: string | null, credits?: any | null, createdAt: any, postalCode: string, address: string, city: string, texts: any, category?: { __typename?: 'CompanyCategory', id: string, name: string } | null, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, users?: Array<{ __typename?: 'User', id: string, email: string }> | null } }> | null } };

export type FilterCompaniesQueryVariables = Exact<{
  filter?: InputMaybe<Array<CompaniesFilterInput> | CompaniesFilterInput>;
}>;


export type FilterCompaniesQuery = { __typename?: 'Query', filterCompanies: Array<{ __typename?: 'Company', id: string, name: string, isPartner: boolean, categoryId?: string | null, credits?: any | null, createdAt: any, postalCode: string, address: string, city: string, texts: any, category?: { __typename?: 'CompanyCategory', id: string, name: string } | null, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, users?: Array<{ __typename?: 'User', id: string, email: string }> | null }> };

export type CompanyNameOnlyQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CompanyNameOnlyQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, name: string, isPartner: boolean } | null };

export type CompaniesNameOnlyQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesNameOnlyQuery = { __typename?: 'Query', companies: Array<{ __typename?: 'Company', id: string, name: string, isPartner: boolean }> };

export type CreateCompanyMutationVariables = Exact<{
  data: CreateCompanyInput;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany: { __typename?: 'Company', id: string, name: string, isPartner: boolean, categoryId?: string | null, credits?: any | null, createdAt: any, postalCode: string, address: string, city: string, texts: any, category?: { __typename?: 'CompanyCategory', id: string, name: string } | null, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, users?: Array<{ __typename?: 'User', id: string, email: string }> | null } };

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany: { __typename?: 'Company', id: string, name: string, isPartner: boolean, categoryId?: string | null, credits?: any | null, createdAt: any, postalCode: string, address: string, city: string, texts: any, category?: { __typename?: 'CompanyCategory', id: string, name: string } | null, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, users?: Array<{ __typename?: 'User', id: string, email: string }> | null } };

export type UpdateMyCompanyMutationVariables = Exact<{
  data: UpdateMyCompanyInput;
}>;


export type UpdateMyCompanyMutation = { __typename?: 'Mutation', updateMyCompany?: { __typename?: 'Company', id: string, name: string, isPartner: boolean, credits?: any | null, postalCode: string, address: string, city: string, texts: any, createdAt: any, category?: { __typename?: 'CompanyCategory', id: string, name: string } | null, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> } | null };

export type RequestCompanyUserMutationVariables = Exact<{
  data: RequestCompanyUserInput;
}>;


export type RequestCompanyUserMutation = { __typename?: 'Mutation', requestCompanyUser: boolean };

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCompanyMutation = { __typename?: 'Mutation', deleteCompany: { __typename?: 'Company', id: string, name: string, isPartner: boolean, categoryId?: string | null, credits?: any | null, createdAt: any, postalCode: string, address: string, city: string, texts: any, category?: { __typename?: 'CompanyCategory', id: string, name: string } | null, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, users?: Array<{ __typename?: 'User', id: string, email: string }> | null } };

export type DeleteCompaniesMutationVariables = Exact<{
  data: DeleteCompaniesInput;
}>;


export type DeleteCompaniesMutation = { __typename?: 'Mutation', deleteCompanies?: { __typename?: 'Company', id: string, name: string, isPartner: boolean, categoryId?: string | null, credits?: any | null, createdAt: any, postalCode: string, address: string, city: string, texts: any, category?: { __typename?: 'CompanyCategory', id: string, name: string } | null, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, users?: Array<{ __typename?: 'User', id: string, email: string }> | null } | null };

export type CompanyCategoryMinimalFragment = { __typename?: 'CompanyCategory', id: string, name: string };

export type CompanyCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanyCategoriesQuery = { __typename?: 'Query', companyCategories: Array<{ __typename?: 'CompanyCategory', id: string, name: string }> };

export type DeleteCompanyCategoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCompanyCategoryMutation = { __typename?: 'Mutation', deleteCompanyCategory: { __typename?: 'CompanyCategory', id: string, name: string } };

export type CompanyCreditsMinimalFragment = { __typename?: 'CompanyCredit', id: string, type: CompanyCreditType, companyId: string, regionLocked?: boolean | null, usedAt?: any | null, user: { __typename?: 'User', email: string } };

export type DryRunDataFragment = { __typename?: 'DryRun', submittedAt?: any | null, duration: number, passed: boolean, correctCount: number, questionCount: number, category: { __typename?: 'Category', id: string, texts: any } };

export type ExamDateDataFragment = { __typename?: 'ExamDate', id: string, season: Season, year: number, examDate: any };

export type ExamDatesQueryVariables = Exact<{
  professionId: Scalars['String'];
}>;


export type ExamDatesQuery = { __typename?: 'Query', examDates: Array<{ __typename?: 'ExamDate', id: string, season: Season, year: number, examDate: any }> };

export type FileDataFragment = { __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any };

export type UploadFileMutationVariables = Exact<{
  data: UploadFileInput;
  file: Scalars['Upload'];
}>;


export type UploadFileMutation = { __typename?: 'Mutation', uploadFile: { __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any } };

export type UpdateFileMutationVariables = Exact<{
  data: UpdateFileInput;
  id: Scalars['String'];
}>;


export type UpdateFileMutation = { __typename?: 'Mutation', updateFileInfo: { __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any } };

export type DeleteFileMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteFileMutation = { __typename?: 'Mutation', deleteFile: { __typename?: 'File', id: string } };

export type DeleteFilesMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteFilesMutation = { __typename?: 'Mutation', deleteFiles: boolean };

export type IncentiveDataFragment = { __typename?: 'Incentive', id: string, createdAt: any, status: Status, link?: string | null, couponCode: string, level: number, contact?: string | null, texts: any, clicks: number, bimonthlyPerformance: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null };

export type IncentivesQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']>;
}>;


export type IncentivesQuery = { __typename?: 'Query', incentives: Array<{ __typename?: 'Incentive', id: string, createdAt: any, status: Status, link?: string | null, couponCode: string, level: number, contact?: string | null, texts: any, clicks: number, bimonthlyPerformance: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null }> };

export type IncentiveQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type IncentiveQuery = { __typename?: 'Query', incentive?: { __typename?: 'Incentive', id: string, createdAt: any, status: Status, link?: string | null, couponCode: string, level: number, contact?: string | null, texts: any, clicks: number, bimonthlyPerformance: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null } | null };

export type CreateIncentiveMutationVariables = Exact<{
  data: CreateIncentiveInput;
}>;


export type CreateIncentiveMutation = { __typename?: 'Mutation', createIncentive: { __typename?: 'Incentive', id: string, createdAt: any, status: Status, link?: string | null, couponCode: string, level: number, contact?: string | null, texts: any, clicks: number, bimonthlyPerformance: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null } };

export type UpdateIncentiveMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateIncentiveInput;
}>;


export type UpdateIncentiveMutation = { __typename?: 'Mutation', updateIncentive: { __typename?: 'Incentive', id: string, createdAt: any, status: Status, link?: string | null, couponCode: string, level: number, contact?: string | null, texts: any, clicks: number, bimonthlyPerformance: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null } };

export type DeleteIncentiveMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteIncentiveMutation = { __typename?: 'Mutation', deleteIncentive: { __typename?: 'Incentive', id: string, createdAt: any, status: Status, link?: string | null, couponCode: string, level: number, contact?: string | null, texts: any, clicks: number, bimonthlyPerformance: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null } };

export type DeleteIncentivesMutationVariables = Exact<{
  data: DeleteIncentivesInput;
}>;


export type DeleteIncentivesMutation = { __typename?: 'Mutation', deleteIncentives?: { __typename?: 'Incentive', id: string, createdAt: any, status: Status, link?: string | null, couponCode: string, level: number, contact?: string | null, texts: any, clicks: number, bimonthlyPerformance: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null } | null };

export type JobDepartmentMinimalFragment = { __typename?: 'JobDepartment', id: string, texts: any };

export type JobDepartmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type JobDepartmentsQuery = { __typename?: 'Query', jobDepartments: Array<{ __typename?: 'JobDepartment', id: string, texts: any }> };

export type JobEmploymentTypeMinimalFragment = { __typename?: 'JobEmploymentType', id: string, texts: any };

export type JobEmploymentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type JobEmploymentTypesQuery = { __typename?: 'Query', jobEmploymentTypes: Array<{ __typename?: 'JobEmploymentType', id: string, texts: any }> };

export type JobDataFragment = { __typename?: 'Job', id: string, createdAt: any, status: Status, link?: string | null, code?: string | null, contact?: string | null, favoriteCount: number, postalCode?: string | null, expiresAt?: any | null, extended: boolean, texts: any, clicks: number, bimonthlyPerformance: number, files?: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> | null, jobDepartment?: { __typename?: 'JobDepartment', id: string, texts: any } | null, jobEmploymentType?: { __typename?: 'JobEmploymentType', id: string, texts: any } | null, company: { __typename?: 'Company', id: string, name: string, isPartner: boolean } };

export type JobsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']>;
}>;


export type JobsQuery = { __typename?: 'Query', jobs: Array<{ __typename?: 'Job', id: string, createdAt: any, status: Status, link?: string | null, code?: string | null, contact?: string | null, favoriteCount: number, postalCode?: string | null, expiresAt?: any | null, extended: boolean, texts: any, clicks: number, bimonthlyPerformance: number, files?: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> | null, jobDepartment?: { __typename?: 'JobDepartment', id: string, texts: any } | null, jobEmploymentType?: { __typename?: 'JobEmploymentType', id: string, texts: any } | null, company: { __typename?: 'Company', id: string, name: string, isPartner: boolean } }> };

export type JobQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type JobQuery = { __typename?: 'Query', job?: { __typename?: 'Job', id: string, createdAt: any, status: Status, link?: string | null, code?: string | null, contact?: string | null, favoriteCount: number, postalCode?: string | null, expiresAt?: any | null, extended: boolean, texts: any, clicks: number, bimonthlyPerformance: number, files?: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> | null, jobDepartment?: { __typename?: 'JobDepartment', id: string, texts: any } | null, jobEmploymentType?: { __typename?: 'JobEmploymentType', id: string, texts: any } | null, company: { __typename?: 'Company', id: string, name: string, isPartner: boolean } } | null };

export type ActivateJobMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ActivateJobMutation = { __typename?: 'Mutation', activateJob: { __typename?: 'Job', id: string, createdAt: any, status: Status, link?: string | null, code?: string | null, contact?: string | null, favoriteCount: number, postalCode?: string | null, expiresAt?: any | null, extended: boolean, texts: any, clicks: number, bimonthlyPerformance: number, files?: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> | null, jobDepartment?: { __typename?: 'JobDepartment', id: string, texts: any } | null, jobEmploymentType?: { __typename?: 'JobEmploymentType', id: string, texts: any } | null, company: { __typename?: 'Company', id: string, name: string, isPartner: boolean } } };

export type ExtendJobMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ExtendJobMutation = { __typename?: 'Mutation', extendJob: { __typename?: 'Job', id: string, createdAt: any, status: Status, link?: string | null, code?: string | null, contact?: string | null, favoriteCount: number, postalCode?: string | null, expiresAt?: any | null, extended: boolean, texts: any, clicks: number, bimonthlyPerformance: number, files?: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> | null, jobDepartment?: { __typename?: 'JobDepartment', id: string, texts: any } | null, jobEmploymentType?: { __typename?: 'JobEmploymentType', id: string, texts: any } | null, company: { __typename?: 'Company', id: string, name: string, isPartner: boolean } } };

export type CreateJobMutationVariables = Exact<{
  data: CreateJobInput;
}>;


export type CreateJobMutation = { __typename?: 'Mutation', createJob: { __typename?: 'Job', id: string, createdAt: any, status: Status, link?: string | null, code?: string | null, contact?: string | null, favoriteCount: number, postalCode?: string | null, expiresAt?: any | null, extended: boolean, texts: any, clicks: number, bimonthlyPerformance: number, files?: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> | null, jobDepartment?: { __typename?: 'JobDepartment', id: string, texts: any } | null, jobEmploymentType?: { __typename?: 'JobEmploymentType', id: string, texts: any } | null, company: { __typename?: 'Company', id: string, name: string, isPartner: boolean } } };

export type UpdateJobMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateJobInput;
}>;


export type UpdateJobMutation = { __typename?: 'Mutation', updateJob: { __typename?: 'Job', id: string, createdAt: any, status: Status, link?: string | null, code?: string | null, contact?: string | null, favoriteCount: number, postalCode?: string | null, expiresAt?: any | null, extended: boolean, texts: any, clicks: number, bimonthlyPerformance: number, files?: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> | null, jobDepartment?: { __typename?: 'JobDepartment', id: string, texts: any } | null, jobEmploymentType?: { __typename?: 'JobEmploymentType', id: string, texts: any } | null, company: { __typename?: 'Company', id: string, name: string, isPartner: boolean } } };

export type DeleteJobMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteJobMutation = { __typename?: 'Mutation', deleteJob: { __typename?: 'Job', id: string, createdAt: any, status: Status, link?: string | null, code?: string | null, contact?: string | null, favoriteCount: number, postalCode?: string | null, expiresAt?: any | null, extended: boolean, texts: any, clicks: number, bimonthlyPerformance: number, files?: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> | null, jobDepartment?: { __typename?: 'JobDepartment', id: string, texts: any } | null, jobEmploymentType?: { __typename?: 'JobEmploymentType', id: string, texts: any } | null, company: { __typename?: 'Company', id: string, name: string, isPartner: boolean } } };

export type DeleteJobsMutationVariables = Exact<{
  data: DeleteJobsInput;
}>;


export type DeleteJobsMutation = { __typename?: 'Mutation', deleteJobs: Array<{ __typename?: 'Job', id: string, createdAt: any, status: Status, link?: string | null, code?: string | null, contact?: string | null, favoriteCount: number, postalCode?: string | null, expiresAt?: any | null, extended: boolean, texts: any, clicks: number, bimonthlyPerformance: number, files?: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> | null, jobDepartment?: { __typename?: 'JobDepartment', id: string, texts: any } | null, jobEmploymentType?: { __typename?: 'JobEmploymentType', id: string, texts: any } | null, company: { __typename?: 'Company', id: string, name: string, isPartner: boolean } }> };

export type UserNotificationFragment = { __typename?: 'UserNotification', userId: string, subject: NotificationSubjects, type: NotificationType };

export type SubscribeNotificationsMutationVariables = Exact<{
  data: Array<SubscribeNotificationInput> | SubscribeNotificationInput;
}>;


export type SubscribeNotificationsMutation = { __typename?: 'Mutation', subscribeNotifications: Array<{ __typename?: 'UserNotification', userId: string, subject: NotificationSubjects, type: NotificationType }> };

export type UnsubscribeNotificationsMutationVariables = Exact<{
  subjects: Array<Scalars['String']> | Scalars['String'];
}>;


export type UnsubscribeNotificationsMutation = { __typename?: 'Mutation', unsubscribeNotifications: Array<{ __typename?: 'UserNotification', userId: string, subject: NotificationSubjects, type: NotificationType }> };

export type ProfessionMinimalFragment = { __typename?: 'Profession', id: string, texts: any };

export type ProfessionDataFragment = { __typename?: 'Profession', status: Status, config: any, id: string, texts: any, examDates: Array<{ __typename?: 'ExamDate', id: string, year: number, examDate: any, season: Season }>, categories: Array<{ __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> }> };

export type ProfessionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProfessionQuery = { __typename?: 'Query', profession: { __typename?: 'Profession', status: Status, config: any, id: string, texts: any, examDates: Array<{ __typename?: 'ExamDate', id: string, year: number, examDate: any, season: Season }>, categories: Array<{ __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> }> } };

export type ProfessionWithCategoriesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProfessionWithCategoriesQuery = { __typename?: 'Query', profession: { __typename?: 'Profession', status: Status, config: any, id: string, texts: any, categories: Array<{ __typename?: 'Category', id: string, texts: any, professionId: string, parentId?: string | null, childCategories: Array<{ __typename?: 'Category', id: string, texts: any }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> }>, examDates: Array<{ __typename?: 'ExamDate', id: string, year: number, examDate: any, season: Season }> } };

export type ProfessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfessionsQuery = { __typename?: 'Query', professions: Array<{ __typename?: 'Profession', status: Status, config: any, id: string, texts: any, examDates: Array<{ __typename?: 'ExamDate', id: string, year: number, examDate: any, season: Season }>, categories: Array<{ __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> }> }> };

export type ProfessionsMinimalQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfessionsMinimalQuery = { __typename?: 'Query', professions: Array<{ __typename?: 'Profession', id: string, texts: any }> };

export type ProfessionsWithCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfessionsWithCategoriesQuery = { __typename?: 'Query', professions: Array<{ __typename?: 'Profession', id: string, texts: any, categories: Array<{ __typename?: 'Category', id: string, texts: any, childCategories: Array<{ __typename?: 'Category', id: string, texts: any }> }> }> };

export type CreateProfessionMutationVariables = Exact<{
  data: CreateProfessionInput;
}>;


export type CreateProfessionMutation = { __typename?: 'Mutation', createProfession: { __typename?: 'Profession', status: Status, config: any, id: string, texts: any, examDates: Array<{ __typename?: 'ExamDate', id: string, year: number, examDate: any, season: Season }>, categories: Array<{ __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> }> } };

export type UpdateProfessionMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateProfessionInput;
}>;


export type UpdateProfessionMutation = { __typename?: 'Mutation', updateProfession: { __typename?: 'Profession', status: Status, config: any, id: string, texts: any, examDates: Array<{ __typename?: 'ExamDate', id: string, year: number, examDate: any, season: Season }>, categories: Array<{ __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> }> } };

export type DeleteProfessionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteProfessionMutation = { __typename?: 'Mutation', deleteProfession: { __typename?: 'Profession', status: Status, config: any, id: string, texts: any, examDates: Array<{ __typename?: 'ExamDate', id: string, year: number, examDate: any, season: Season }>, categories: Array<{ __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> }> } };

export type DeleteProfessionsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteProfessionsMutation = { __typename?: 'Mutation', deleteProfessions?: Array<{ __typename?: 'Profession', status: Status, config: any, id: string, texts: any, examDates: Array<{ __typename?: 'ExamDate', id: string, year: number, examDate: any, season: Season }>, categories: Array<{ __typename?: 'Category', id: string, professionId: string, parentId?: string | null, texts: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> }> }> | null };

export type QuestionGroupMinimalDataFragment = { __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> };

export type QuestionGroupDataFragment = { __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, questions: Array<{ __typename?: 'Question', id: string, texts: any }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> };

export type QuestionGroupQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type QuestionGroupQuery = { __typename?: 'Query', questionGroup: { __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, questions: Array<{ __typename?: 'Question', id: string, texts: any }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> } };

export type QuestionGroupsQueryVariables = Exact<{
  categoryId?: InputMaybe<Scalars['String']>;
}>;


export type QuestionGroupsQuery = { __typename?: 'Query', questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, questions: Array<{ __typename?: 'Question', id: string, texts: any }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> };

export type QuestionGroupWithQuestionsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type QuestionGroupWithQuestionsQuery = { __typename?: 'Query', questionGroup: { __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, questions: Array<{ __typename?: 'Question', id: string, texts: any }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> } };

export type CreateQuestionGroupMutationVariables = Exact<{
  data: CreateQuestionGroupInput;
}>;


export type CreateQuestionGroupMutation = { __typename?: 'Mutation', createQuestionGroup: { __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, questions: Array<{ __typename?: 'Question', id: string, texts: any }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> } };

export type UpdateQuestionGroupMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateQuestionGroupInput;
}>;


export type UpdateQuestionGroupMutation = { __typename?: 'Mutation', updateQuestionGroup: { __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, questions: Array<{ __typename?: 'Question', id: string, texts: any }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> } };

export type DeleteQuestionGroupMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteQuestionGroupMutation = { __typename?: 'Mutation', deleteQuestionGroup: { __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, questions: Array<{ __typename?: 'Question', id: string, texts: any }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> } };

export type DeleteQuestionGroupsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteQuestionGroupsMutation = { __typename?: 'Mutation', deleteQuestionGroups: Array<{ __typename?: 'QuestionGroup', id: string, texts: any, questionCount: number, questions: Array<{ __typename?: 'Question', id: string, texts: any }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> };

export type QuestionDataFragment = { __typename?: 'Question', id: string, type: QuestionType, texts: any, inDuelAvailable: boolean, config: any, questionGroupId?: string | null, questionGroupOrder?: number | null, reviewStatus: ReviewStatus, topicId?: string | null, questionKey: string, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, topic?: { __typename?: 'Topic', texts: any, index: number } | null, questionGroup?: { __typename?: 'QuestionGroup', texts: any } | null, category: { __typename?: 'Category', id: string, parentId?: string | null, texts: any }, profession: { __typename?: 'Profession', id: string, texts: any } };

export type QuestionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type QuestionQuery = { __typename?: 'Query', question: { __typename?: 'Question', id: string, type: QuestionType, texts: any, inDuelAvailable: boolean, config: any, questionGroupId?: string | null, questionGroupOrder?: number | null, reviewStatus: ReviewStatus, topicId?: string | null, questionKey: string, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, topic?: { __typename?: 'Topic', texts: any, index: number } | null, questionGroup?: { __typename?: 'QuestionGroup', texts: any } | null, category: { __typename?: 'Category', id: string, parentId?: string | null, texts: any }, profession: { __typename?: 'Profession', id: string, texts: any } } };

export type QuestionsQueryVariables = Exact<{
  data: QueryQuestionsInput;
}>;


export type QuestionsQuery = { __typename?: 'Query', questions: Array<{ __typename?: 'Question', id: string, type: QuestionType, texts: any, inDuelAvailable: boolean, config: any, questionGroupId?: string | null, questionGroupOrder?: number | null, reviewStatus: ReviewStatus, topicId?: string | null, questionKey: string, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, topic?: { __typename?: 'Topic', texts: any, index: number } | null, questionGroup?: { __typename?: 'QuestionGroup', texts: any } | null, category: { __typename?: 'Category', id: string, parentId?: string | null, texts: any }, profession: { __typename?: 'Profession', id: string, texts: any } }> };

export type QuestionsPaginatedQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<QuestionsOrderInput>;
  filter?: InputMaybe<Array<QuestionsFilterInput> | QuestionsFilterInput>;
  connector?: InputMaybe<FilterConnectionOperators>;
}>;


export type QuestionsPaginatedQuery = { __typename?: 'Query', questionsPaginated: { __typename?: 'QuestionsPaginated', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges?: Array<{ __typename?: 'QuestionEdge', cursor: string, node: { __typename?: 'Question', id: string, type: QuestionType, texts: any, inDuelAvailable: boolean, config: any, questionGroupId?: string | null, questionGroupOrder?: number | null, reviewStatus: ReviewStatus, topicId?: string | null, questionKey: string, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, topic?: { __typename?: 'Topic', texts: any, index: number } | null, questionGroup?: { __typename?: 'QuestionGroup', texts: any } | null, category: { __typename?: 'Category', id: string, parentId?: string | null, texts: any }, profession: { __typename?: 'Profession', id: string, texts: any } } }> | null } };

export type QuestionsCountQueryVariables = Exact<{
  filter?: InputMaybe<Array<QuestionsFilterInput> | QuestionsFilterInput>;
  connector?: InputMaybe<FilterConnectionOperators>;
}>;


export type QuestionsCountQuery = { __typename?: 'Query', questionsCount: number };

export type CreateQuestionMutationVariables = Exact<{
  data: CreateQuestionInput;
}>;


export type CreateQuestionMutation = { __typename?: 'Mutation', createQuestion: { __typename?: 'Question', id: string, type: QuestionType, texts: any, inDuelAvailable: boolean, config: any, questionGroupId?: string | null, questionGroupOrder?: number | null, reviewStatus: ReviewStatus, topicId?: string | null, questionKey: string, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, topic?: { __typename?: 'Topic', texts: any, index: number } | null, questionGroup?: { __typename?: 'QuestionGroup', texts: any } | null, category: { __typename?: 'Category', id: string, parentId?: string | null, texts: any }, profession: { __typename?: 'Profession', id: string, texts: any } } };

export type UpdateQuestionMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateQuestionInput;
  removedTexts?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type UpdateQuestionMutation = { __typename?: 'Mutation', updateQuestion: { __typename?: 'Question', id: string, type: QuestionType, texts: any, inDuelAvailable: boolean, config: any, questionGroupId?: string | null, questionGroupOrder?: number | null, reviewStatus: ReviewStatus, topicId?: string | null, questionKey: string, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, topic?: { __typename?: 'Topic', texts: any, index: number } | null, questionGroup?: { __typename?: 'QuestionGroup', texts: any } | null, category: { __typename?: 'Category', id: string, parentId?: string | null, texts: any }, profession: { __typename?: 'Profession', id: string, texts: any } } };

export type UpdateQuestionsStatusMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
  reviewStatus: ReviewStatus;
}>;


export type UpdateQuestionsStatusMutation = { __typename?: 'Mutation', updateQuestionsStatus: Array<{ __typename?: 'Question', id: string, type: QuestionType, texts: any, inDuelAvailable: boolean, config: any, questionGroupId?: string | null, questionGroupOrder?: number | null, reviewStatus: ReviewStatus, topicId?: string | null, questionKey: string, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, topic?: { __typename?: 'Topic', texts: any, index: number } | null, questionGroup?: { __typename?: 'QuestionGroup', texts: any } | null, category: { __typename?: 'Category', id: string, parentId?: string | null, texts: any }, profession: { __typename?: 'Profession', id: string, texts: any } }> };

export type DuplicateQuestionsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
  data: DuplicateQuestionInput;
}>;


export type DuplicateQuestionsMutation = { __typename?: 'Mutation', duplicateQuestions: Array<{ __typename?: 'Question', id: string, type: QuestionType, texts: any, inDuelAvailable: boolean, config: any, questionGroupId?: string | null, questionGroupOrder?: number | null, reviewStatus: ReviewStatus, topicId?: string | null, questionKey: string, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, topic?: { __typename?: 'Topic', texts: any, index: number } | null, questionGroup?: { __typename?: 'QuestionGroup', texts: any } | null, category: { __typename?: 'Category', id: string, parentId?: string | null, texts: any }, profession: { __typename?: 'Profession', id: string, texts: any } }> };

export type DeleteQuestionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteQuestionMutation = { __typename?: 'Mutation', deleteQuestion: { __typename?: 'Question', id: string, type: QuestionType, texts: any, inDuelAvailable: boolean, config: any, questionGroupId?: string | null, questionGroupOrder?: number | null, reviewStatus: ReviewStatus, topicId?: string | null, questionKey: string, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, topic?: { __typename?: 'Topic', texts: any, index: number } | null, questionGroup?: { __typename?: 'QuestionGroup', texts: any } | null, category: { __typename?: 'Category', id: string, parentId?: string | null, texts: any }, profession: { __typename?: 'Profession', id: string, texts: any } } };

export type DeleteQuestionsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteQuestionsMutation = { __typename?: 'Mutation', deleteQuestions: Array<{ __typename?: 'Question', id: string, type: QuestionType, texts: any, inDuelAvailable: boolean, config: any, questionGroupId?: string | null, questionGroupOrder?: number | null, reviewStatus: ReviewStatus, topicId?: string | null, questionKey: string, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, topic?: { __typename?: 'Topic', texts: any, index: number } | null, questionGroup?: { __typename?: 'QuestionGroup', texts: any } | null, category: { __typename?: 'Category', id: string, parentId?: string | null, texts: any }, profession: { __typename?: 'Profession', id: string, texts: any } }> };

export type RolesMinimalFragment = { __typename?: 'Role', id: string, key: string };

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'Query', roles: Array<{ __typename?: 'Role', id: string, key: string }> };

export type TopicDataFragment = { __typename?: 'Topic', id: string, professionId: string, texts: any, index: number, trainingYear: number, profession: { __typename?: 'Profession', id: string, texts: any } };

export type TopicQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TopicQuery = { __typename?: 'Query', topic: { __typename?: 'Topic', id: string, professionId: string, texts: any, index: number, trainingYear: number, profession: { __typename?: 'Profession', id: string, texts: any } } };

export type TopicWithQuestionsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TopicWithQuestionsQuery = { __typename?: 'Query', topic: { __typename?: 'Topic', id: string, professionId: string, texts: any, index: number, trainingYear: number, questions: Array<{ __typename?: 'Question', id: string, type: QuestionType, texts: any, inDuelAvailable: boolean, config: any, questionGroupId?: string | null, questionGroupOrder?: number | null, reviewStatus: ReviewStatus, topicId?: string | null, questionKey: string, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, topic?: { __typename?: 'Topic', texts: any, index: number } | null, questionGroup?: { __typename?: 'QuestionGroup', texts: any } | null, category: { __typename?: 'Category', id: string, parentId?: string | null, texts: any }, profession: { __typename?: 'Profession', id: string, texts: any } }>, profession: { __typename?: 'Profession', id: string, texts: any } } };

export type TopicsQueryVariables = Exact<{
  professionId: Scalars['String'];
}>;


export type TopicsQuery = { __typename?: 'Query', topics: Array<{ __typename?: 'Topic', id: string, professionId: string, texts: any, index: number, trainingYear: number, profession: { __typename?: 'Profession', id: string, texts: any } }> };

export type CreateTopicMutationVariables = Exact<{
  data: CreateTopicInput;
}>;


export type CreateTopicMutation = { __typename?: 'Mutation', createTopic: { __typename?: 'Topic', id: string, professionId: string, texts: any, index: number, trainingYear: number, profession: { __typename?: 'Profession', id: string, texts: any } } };

export type DeleteTopicMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteTopicMutation = { __typename?: 'Mutation', deleteTopic: { __typename?: 'Topic', id: string, professionId: string, texts: any, index: number, trainingYear: number, profession: { __typename?: 'Profession', id: string, texts: any } } };

export type DeleteTopicsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteTopicsMutation = { __typename?: 'Mutation', deleteTopics: Array<{ __typename?: 'Topic', id: string, professionId: string, texts: any, index: number, trainingYear: number, profession: { __typename?: 'Profession', id: string, texts: any } }> };

export type UpdateTopicMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateTopicInput;
}>;


export type UpdateTopicMutation = { __typename?: 'Mutation', updateTopic: { __typename?: 'Topic', id: string, professionId: string, texts: any, index: number, trainingYear: number, profession: { __typename?: 'Profession', id: string, texts: any } } };

export type DashboardTrackingJobsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']>;
}>;


export type DashboardTrackingJobsQuery = { __typename?: 'Query', dashboardTrackingJobs: any };

export type DashboardTrackingIncentivesQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']>;
}>;


export type DashboardTrackingIncentivesQuery = { __typename?: 'Query', dashboardTrackingIncentives: any };

export type JobTrackingQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;


export type JobTrackingQuery = { __typename?: 'Query', dashboardTrackingJob: any };

export type IncentiveTrackingQueryVariables = Exact<{
  incentiveId: Scalars['String'];
}>;


export type IncentiveTrackingQuery = { __typename?: 'Query', dashboardTrackingIncentive: any };

export type TrainingCompanyMinimalFragment = { __typename?: 'TrainingCompany', id: string, name: string, address: string, postalCode: string, city: string, active: boolean, provided: boolean, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> };

export type TrainingCompanyUserMinimalFragment = { __typename?: 'User', id: string, email: string, firstname: string, lastname: string, active: boolean, examDate?: any | null, confirmed?: boolean | null, traineeCount: number, createdAt: any, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> | null, instructor?: { __typename?: 'User', id: string, firstname: string, lastname: string } | null, role: { __typename?: 'Role', key: string }, profession?: { __typename?: 'Profession', id: string, texts: any } | null };

export type TrainingCompanyUserMinimalWithPlayerFragment = { __typename?: 'User', overallStudyTime: number, trainingYear?: number | null, id: string, email: string, firstname: string, lastname: string, active: boolean, examDate?: any | null, confirmed?: boolean | null, traineeCount: number, createdAt: any, player?: { __typename?: 'Player', id: string, level: number, points: number, credits: number, username?: string | null, duelStatistic: any, duelRanking?: number | null } | null, studyTimes: Array<{ __typename?: 'UserStudyTime', time: number, date: any }>, profession?: { __typename?: 'Profession', id: string, texts: any, topics: Array<{ __typename?: 'Topic', id: string, texts: any, index: number }>, categories: Array<{ __typename?: 'Category', childCategories: Array<{ __typename?: 'Category', id: string, texts: any }> }> } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> | null, instructor?: { __typename?: 'User', id: string, firstname: string, lastname: string } | null, role: { __typename?: 'Role', key: string } };

export type TrainingCompanyQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TrainingCompanyQuery = { __typename?: 'Query', trainingCompany?: { __typename?: 'TrainingCompany', id: string, name: string, address: string, postalCode: string, city: string, active: boolean, provided: boolean, texts: any, createdAt: any, instructors: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string, active: boolean, examDate?: any | null, confirmed?: boolean | null, traineeCount: number, createdAt: any, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> | null, instructor?: { __typename?: 'User', id: string, firstname: string, lastname: string } | null, role: { __typename?: 'Role', key: string }, profession?: { __typename?: 'Profession', id: string, texts: any } | null }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> } | null };

export type TrainingCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type TrainingCompaniesQuery = { __typename?: 'Query', trainingCompanies?: Array<{ __typename?: 'TrainingCompany', id: string, name: string, address: string, postalCode: string, city: string, active: boolean, provided: boolean, texts: any, createdAt: any, instructors: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string, active: boolean, examDate?: any | null, confirmed?: boolean | null, traineeCount: number, createdAt: any, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> | null, instructor?: { __typename?: 'User', id: string, firstname: string, lastname: string } | null, role: { __typename?: 'Role', key: string }, profession?: { __typename?: 'Profession', id: string, texts: any } | null }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> | null };

export type TrainingCompaniesPaginatedQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TrainingCompaniesOrderInput>;
  filter?: InputMaybe<Array<TrainingCompaniesFilterInput> | TrainingCompaniesFilterInput>;
}>;


export type TrainingCompaniesPaginatedQuery = { __typename?: 'Query', trainingCompaniesPaginated: { __typename?: 'TrainingCompaniesPaginated', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges?: Array<{ __typename?: 'TrainingCompanyEdge', cursor: string, node: { __typename?: 'TrainingCompany', id: string, name: string, address: string, postalCode: string, city: string, active: boolean, provided: boolean, texts: any, createdAt: any, instructors: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string, active: boolean, examDate?: any | null, confirmed?: boolean | null, traineeCount: number, createdAt: any, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> | null, instructor?: { __typename?: 'User', id: string, firstname: string, lastname: string } | null, role: { __typename?: 'Role', key: string }, profession?: { __typename?: 'Profession', id: string, texts: any } | null }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> } }> | null } };

export type TrainingCompaniesTraineesQueryVariables = Exact<{
  all?: InputMaybe<Scalars['Boolean']>;
  withTopics?: InputMaybe<Scalars['Boolean']>;
}>;


export type TrainingCompaniesTraineesQuery = { __typename?: 'Query', trainingCompanies?: Array<{ __typename?: 'TrainingCompany', id: string, name: string, address: string, postalCode: string, city: string, active: boolean, provided: boolean, texts: any, createdAt: any, trainees: Array<{ __typename?: 'User', learningProgress?: any | null, overallStudyTime: number, trainingYear?: number | null, id: string, email: string, firstname: string, lastname: string, active: boolean, examDate?: any | null, confirmed?: boolean | null, traineeCount: number, createdAt: any, player?: { __typename?: 'Player', id: string, level: number, points: number, credits: number, username?: string | null, duelStatistic: any, duelRanking?: number | null } | null, studyTimes: Array<{ __typename?: 'UserStudyTime', time: number, date: any }>, profession?: { __typename?: 'Profession', id: string, texts: any, topics: Array<{ __typename?: 'Topic', id: string, texts: any, index: number }>, categories: Array<{ __typename?: 'Category', childCategories: Array<{ __typename?: 'Category', id: string, texts: any }> }> } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> | null, instructor?: { __typename?: 'User', id: string, firstname: string, lastname: string } | null, role: { __typename?: 'Role', key: string } }>, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> | null };

export type FilterTrainingCompaniesQueryVariables = Exact<{
  filter?: InputMaybe<Array<TrainingCompaniesFilterInput> | TrainingCompaniesFilterInput>;
}>;


export type FilterTrainingCompaniesQuery = { __typename?: 'Query', filterTrainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string, address: string, postalCode: string, city: string, active: boolean, provided: boolean, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> };

export type UpdateTrainingCompaniesMutationVariables = Exact<{
  data: UpdateTrainingCompaniesInput;
}>;


export type UpdateTrainingCompaniesMutation = { __typename?: 'Mutation', updateTrainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string, address: string, postalCode: string, city: string, active: boolean, provided: boolean, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> }> };

export type CreateTrainingCompanyMutationVariables = Exact<{
  data: CreateTrainingCompanyInput;
}>;


export type CreateTrainingCompanyMutation = { __typename?: 'Mutation', createTrainingCompany: { __typename?: 'TrainingCompany', id: string, name: string, address: string, postalCode: string, city: string, active: boolean, provided: boolean, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> } };

export type UpdateTrainingCompanyMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateTrainingCompanyInput;
}>;


export type UpdateTrainingCompanyMutation = { __typename?: 'Mutation', updateTrainingCompany: { __typename?: 'TrainingCompany', id: string, name: string, address: string, postalCode: string, city: string, active: boolean, provided: boolean, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> } };

export type DeleteTrainingCompanyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteTrainingCompanyMutation = { __typename?: 'Mutation', deleteTrainingCompany: { __typename?: 'TrainingCompany', id: string, name: string, address: string, postalCode: string, city: string, active: boolean, provided: boolean, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> } };

export type DeleteTrainingCompaniesMutationVariables = Exact<{
  data: DeleteTrainingCompaniesInput;
}>;


export type DeleteTrainingCompaniesMutation = { __typename?: 'Mutation', deleteTrainingCompanies?: { __typename?: 'TrainingCompany', id: string, name: string, address: string, postalCode: string, city: string, active: boolean, provided: boolean, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }> } | null };

export type UserFullFragment = { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, roleId: string, companyId?: string | null, traineeCount: number, trainingYear?: number | null, professionId?: string | null, active: boolean, createdAt: any, role: { __typename?: 'Role', id: string, key: string }, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string }> | null, profession?: { __typename?: 'Profession', id: string, texts: any } | null, instructor?: { __typename?: 'User', id: string, email: string } | null, player?: { __typename?: 'Player', username?: string | null } | null, notifications?: Array<{ __typename?: 'UserNotification', subject: NotificationSubjects, type: NotificationType }> | null };

export type MyTraineesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyTraineesQuery = { __typename?: 'Query', me: { __typename?: 'User', trainees?: Array<{ __typename?: 'User', learningProgress?: any | null, overallStudyTime: number, trainingYear?: number | null, id: string, email: string, firstname: string, lastname: string, active: boolean, examDate?: any | null, confirmed?: boolean | null, traineeCount: number, createdAt: any, player?: { __typename?: 'Player', id: string, level: number, points: number, credits: number, username?: string | null, duelStatistic: any, duelRanking?: number | null } | null, studyTimes: Array<{ __typename?: 'UserStudyTime', time: number, date: any }>, profession?: { __typename?: 'Profession', id: string, texts: any, topics: Array<{ __typename?: 'Topic', id: string, texts: any, index: number }>, categories: Array<{ __typename?: 'Category', childCategories: Array<{ __typename?: 'Category', id: string, texts: any }> }> } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> | null, instructor?: { __typename?: 'User', id: string, firstname: string, lastname: string } | null, role: { __typename?: 'Role', key: string } }> | null } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, roleId: string, companyId?: string | null, traineeCount: number, trainingYear?: number | null, professionId?: string | null, active: boolean, createdAt: any, role: { __typename?: 'Role', id: string, key: string }, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string }> | null, profession?: { __typename?: 'Profession', id: string, texts: any } | null, instructor?: { __typename?: 'User', id: string, email: string } | null, player?: { __typename?: 'Player', username?: string | null } | null, notifications?: Array<{ __typename?: 'UserNotification', subject: NotificationSubjects, type: NotificationType }> | null } };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, firstname: string, lastname: string, email: string, roleId: string, companyId?: string | null, traineeCount: number, trainingYear?: number | null, professionId?: string | null, active: boolean, createdAt: any, role: { __typename?: 'Role', id: string, key: string }, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string }> | null, profession?: { __typename?: 'Profession', id: string, texts: any } | null, instructor?: { __typename?: 'User', id: string, email: string } | null, player?: { __typename?: 'Player', username?: string | null } | null, notifications?: Array<{ __typename?: 'UserNotification', subject: NotificationSubjects, type: NotificationType }> | null }> };

export type UsersPaginatedQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserOrderInput>;
  filter?: InputMaybe<Array<UserFilterInput> | UserFilterInput>;
}>;


export type UsersPaginatedQuery = { __typename?: 'Query', usersPaginated: { __typename?: 'UsersPaginated', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges?: Array<{ __typename?: 'UserEdge', cursor: string, node: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, roleId: string, companyId?: string | null, traineeCount: number, trainingYear?: number | null, professionId?: string | null, active: boolean, createdAt: any, role: { __typename?: 'Role', id: string, key: string }, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string }> | null, profession?: { __typename?: 'Profession', id: string, texts: any } | null, instructor?: { __typename?: 'User', id: string, email: string } | null, player?: { __typename?: 'Player', username?: string | null } | null, notifications?: Array<{ __typename?: 'UserNotification', subject: NotificationSubjects, type: NotificationType }> | null } }> | null } };

export type RegisteredTraineesCountQueryVariables = Exact<{
  monthBack: Scalars['Int'];
}>;


export type RegisteredTraineesCountQuery = { __typename?: 'Query', registeredTraineesCount: any };

export type UserTrainingCompanyQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type UserTrainingCompanyQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, email: string, firstname: string, lastname: string, active: boolean, examDate?: any | null, confirmed?: boolean | null, traineeCount: number, createdAt: any, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> | null, instructor?: { __typename?: 'User', id: string, firstname: string, lastname: string } | null, role: { __typename?: 'Role', key: string }, profession?: { __typename?: 'Profession', id: string, texts: any } | null } };

export type UserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, roleId: string, companyId?: string | null, traineeCount: number, trainingYear?: number | null, professionId?: string | null, active: boolean, createdAt: any, role: { __typename?: 'Role', id: string, key: string }, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string }> | null, profession?: { __typename?: 'Profession', id: string, texts: any } | null, instructor?: { __typename?: 'User', id: string, email: string } | null, player?: { __typename?: 'Player', username?: string | null } | null, notifications?: Array<{ __typename?: 'UserNotification', subject: NotificationSubjects, type: NotificationType }> | null } };

export type TrainingCompanyUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TrainingCompanyUserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, email: string, firstname: string, lastname: string, active: boolean, examDate?: any | null, confirmed?: boolean | null, traineeCount: number, createdAt: any, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> | null, instructor?: { __typename?: 'User', id: string, firstname: string, lastname: string } | null, role: { __typename?: 'Role', key: string }, profession?: { __typename?: 'Profession', id: string, texts: any } | null } };

export type TraineeQueryVariables = Exact<{
  id: Scalars['String'];
  withTopics?: InputMaybe<Scalars['Boolean']>;
}>;


export type TraineeQuery = { __typename?: 'Query', user: { __typename?: 'User', learningProgress?: any | null, overallStudyTime: number, trainingYear?: number | null, id: string, email: string, firstname: string, lastname: string, active: boolean, examDate?: any | null, confirmed?: boolean | null, traineeCount: number, createdAt: any, dryRuns?: Array<{ __typename?: 'DryRun', submittedAt?: any | null, duration: number, passed: boolean, correctCount: number, questionCount: number, category: { __typename?: 'Category', id: string, texts: any } }> | null, player?: { __typename?: 'Player', id: string, level: number, points: number, credits: number, username?: string | null, duelStatistic: any, duelRanking?: number | null } | null, studyTimes: Array<{ __typename?: 'UserStudyTime', time: number, date: any }>, profession?: { __typename?: 'Profession', id: string, texts: any, topics: Array<{ __typename?: 'Topic', id: string, texts: any, index: number }>, categories: Array<{ __typename?: 'Category', childCategories: Array<{ __typename?: 'Category', id: string, texts: any }> }> } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> | null, instructor?: { __typename?: 'User', id: string, firstname: string, lastname: string } | null, role: { __typename?: 'Role', key: string } } };

export type FilterUsersQueryVariables = Exact<{
  filter?: InputMaybe<Array<UserFilterInput> | UserFilterInput>;
  roles?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type FilterUsersQuery = { __typename?: 'Query', filterUsers: Array<{ __typename?: 'User', id: string, firstname: string, lastname: string, email: string, roleId: string, companyId?: string | null, traineeCount: number, trainingYear?: number | null, professionId?: string | null, active: boolean, createdAt: any, role: { __typename?: 'Role', id: string, key: string }, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string }> | null, profession?: { __typename?: 'Profession', id: string, texts: any } | null, instructor?: { __typename?: 'User', id: string, email: string } | null, player?: { __typename?: 'Player', username?: string | null } | null, notifications?: Array<{ __typename?: 'UserNotification', subject: NotificationSubjects, type: NotificationType }> | null }> };

export type CheckInvitationTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type CheckInvitationTokenQuery = { __typename?: 'Query', checkInvitationToken: boolean };

export type CountUsersQueryVariables = Exact<{
  role?: InputMaybe<Scalars['String']>;
  hasTrainingCompany?: InputMaybe<Scalars['Boolean']>;
}>;


export type CountUsersQuery = { __typename?: 'Query', countUsers: number };

export type DisconnectTraineeMutationVariables = Exact<{
  traineeId: Scalars['String'];
}>;


export type DisconnectTraineeMutation = { __typename?: 'Mutation', disconnectTrainee: { __typename?: 'User', id: string, email: string, firstname: string, lastname: string, active: boolean, examDate?: any | null, confirmed?: boolean | null, traineeCount: number, createdAt: any, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> | null, instructor?: { __typename?: 'User', id: string, firstname: string, lastname: string } | null, role: { __typename?: 'Role', key: string }, profession?: { __typename?: 'Profession', id: string, texts: any } | null } };

export type DisconnectTraineesMutationVariables = Exact<{
  traineeIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type DisconnectTraineesMutation = { __typename?: 'Mutation', disconnectTrainees: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string, active: boolean, examDate?: any | null, confirmed?: boolean | null, traineeCount: number, createdAt: any, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> | null, instructor?: { __typename?: 'User', id: string, firstname: string, lastname: string } | null, role: { __typename?: 'Role', key: string }, profession?: { __typename?: 'Profession', id: string, texts: any } | null }> };

export type ConfirmTraineeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ConfirmTraineeMutation = { __typename?: 'Mutation', confirmTrainee: { __typename?: 'User', id: string, email: string, firstname: string, lastname: string, active: boolean, examDate?: any | null, confirmed?: boolean | null, traineeCount: number, createdAt: any, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> | null, instructor?: { __typename?: 'User', id: string, firstname: string, lastname: string } | null, role: { __typename?: 'Role', key: string }, profession?: { __typename?: 'Profession', id: string, texts: any } | null } };

export type CreateUserMutationVariables = Exact<{
  data: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, roleId: string, companyId?: string | null, traineeCount: number, trainingYear?: number | null, professionId?: string | null, active: boolean, createdAt: any, role: { __typename?: 'Role', id: string, key: string }, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string }> | null, profession?: { __typename?: 'Profession', id: string, texts: any } | null, instructor?: { __typename?: 'User', id: string, email: string } | null, player?: { __typename?: 'Player', username?: string | null } | null, notifications?: Array<{ __typename?: 'UserNotification', subject: NotificationSubjects, type: NotificationType }> | null } };

export type InviteOrCreateTraineeMutationVariables = Exact<{
  data: InviteTraineeInput;
}>;


export type InviteOrCreateTraineeMutation = { __typename?: 'Mutation', inviteOrCreateTrainee: boolean };

export type CreateInstructorMutationVariables = Exact<{
  data: CreateInstructorInput;
}>;


export type CreateInstructorMutation = { __typename?: 'Mutation', createInstructor: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, roleId: string, companyId?: string | null, traineeCount: number, trainingYear?: number | null, professionId?: string | null, active: boolean, createdAt: any, role: { __typename?: 'Role', id: string, key: string }, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string }> | null, profession?: { __typename?: 'Profession', id: string, texts: any } | null, instructor?: { __typename?: 'User', id: string, email: string } | null, player?: { __typename?: 'Player', username?: string | null } | null, notifications?: Array<{ __typename?: 'UserNotification', subject: NotificationSubjects, type: NotificationType }> | null } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, roleId: string, companyId?: string | null, traineeCount: number, trainingYear?: number | null, professionId?: string | null, active: boolean, createdAt: any, role: { __typename?: 'Role', id: string, key: string }, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string }> | null, profession?: { __typename?: 'Profession', id: string, texts: any } | null, instructor?: { __typename?: 'User', id: string, email: string } | null, player?: { __typename?: 'Player', username?: string | null } | null, notifications?: Array<{ __typename?: 'UserNotification', subject: NotificationSubjects, type: NotificationType }> | null } };

export type UpdateInstructorMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateInstructorInput;
}>;


export type UpdateInstructorMutation = { __typename?: 'Mutation', updateInstructor: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, roleId: string, companyId?: string | null, traineeCount: number, trainingYear?: number | null, professionId?: string | null, active: boolean, createdAt: any, role: { __typename?: 'Role', id: string, key: string }, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string }> | null, profession?: { __typename?: 'Profession', id: string, texts: any } | null, instructor?: { __typename?: 'User', id: string, email: string } | null, player?: { __typename?: 'Player', username?: string | null } | null, notifications?: Array<{ __typename?: 'UserNotification', subject: NotificationSubjects, type: NotificationType }> | null } };

export type UpdateUsersMutationVariables = Exact<{
  data: UpdateUsersInput;
}>;


export type UpdateUsersMutation = { __typename?: 'Mutation', updateUsers: Array<{ __typename?: 'User', id: string, firstname: string, lastname: string, email: string, roleId: string, companyId?: string | null, traineeCount: number, trainingYear?: number | null, professionId?: string | null, active: boolean, createdAt: any, role: { __typename?: 'Role', id: string, key: string }, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string }> | null, profession?: { __typename?: 'Profession', id: string, texts: any } | null, instructor?: { __typename?: 'User', id: string, email: string } | null, player?: { __typename?: 'Player', username?: string | null } | null, notifications?: Array<{ __typename?: 'UserNotification', subject: NotificationSubjects, type: NotificationType }> | null }> };

export type UpdateMeMutationVariables = Exact<{
  data: UpdateUserInput;
}>;


export type UpdateMeMutation = { __typename?: 'Mutation', updateMe: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, roleId: string, companyId?: string | null, traineeCount: number, trainingYear?: number | null, professionId?: string | null, active: boolean, createdAt: any, role: { __typename?: 'Role', id: string, key: string }, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string }> | null, profession?: { __typename?: 'Profession', id: string, texts: any } | null, instructor?: { __typename?: 'User', id: string, email: string } | null, player?: { __typename?: 'Player', username?: string | null } | null, notifications?: Array<{ __typename?: 'UserNotification', subject: NotificationSubjects, type: NotificationType }> | null } };

export type DeleteMeMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteMeMutation = { __typename?: 'Mutation', deleteMe: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, roleId: string, companyId?: string | null, traineeCount: number, trainingYear?: number | null, professionId?: string | null, active: boolean, createdAt: any, role: { __typename?: 'Role', id: string, key: string }, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string }> | null, profession?: { __typename?: 'Profession', id: string, texts: any } | null, instructor?: { __typename?: 'User', id: string, email: string } | null, player?: { __typename?: 'Player', username?: string | null } | null, notifications?: Array<{ __typename?: 'UserNotification', subject: NotificationSubjects, type: NotificationType }> | null } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, roleId: string, companyId?: string | null, traineeCount: number, trainingYear?: number | null, professionId?: string | null, active: boolean, createdAt: any, role: { __typename?: 'Role', id: string, key: string }, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string }> | null, profession?: { __typename?: 'Profession', id: string, texts: any } | null, instructor?: { __typename?: 'User', id: string, email: string } | null, player?: { __typename?: 'Player', username?: string | null } | null, notifications?: Array<{ __typename?: 'UserNotification', subject: NotificationSubjects, type: NotificationType }> | null } };

export type DeleteUsersMutationVariables = Exact<{
  userIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteUsersMutation = { __typename?: 'Mutation', deleteUsers?: Array<{ __typename?: 'User', id: string, firstname: string, lastname: string, email: string, roleId: string, companyId?: string | null, traineeCount: number, trainingYear?: number | null, professionId?: string | null, active: boolean, createdAt: any, role: { __typename?: 'Role', id: string, key: string }, files: Array<{ __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any }>, company?: { __typename?: 'Company', id: string, name: string } | null, trainingCompanies: Array<{ __typename?: 'TrainingCompany', id: string, name: string }>, trainees?: Array<{ __typename?: 'User', id: string, email: string }> | null, profession?: { __typename?: 'Profession', id: string, texts: any } | null, instructor?: { __typename?: 'User', id: string, email: string } | null, player?: { __typename?: 'Player', username?: string | null } | null, notifications?: Array<{ __typename?: 'UserNotification', subject: NotificationSubjects, type: NotificationType }> | null }> | null };

export type InviteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser: boolean };

export const CompanyCategoryMinimalFragmentDoc = gql`
    fragment CompanyCategoryMinimal on CompanyCategory {
  id
  name
}
    `;
export const FileDataFragmentDoc = gql`
    fragment FileData on File {
  id
  key
  fileName
  fileSize
  createdAt
  mimeType
  url
  config
}
    `;
export const CompanyMinimalFragmentDoc = gql`
    fragment CompanyMinimal on Company {
  id
  name
  isPartner
  categoryId
  category {
    ...CompanyCategoryMinimal
  }
  files {
    ...FileData
  }
  credits
  users {
    id
    email
  }
  createdAt
  postalCode
  address
  city
  texts
}
    ${CompanyCategoryMinimalFragmentDoc}
${FileDataFragmentDoc}`;
export const MyCompanyMinimalFragmentDoc = gql`
    fragment MyCompanyMinimal on Company {
  id
  name
  isPartner
  category {
    ...CompanyCategoryMinimal
  }
  credits
  postalCode
  address
  city
  files {
    ...FileData
  }
  texts
  createdAt
}
    ${CompanyCategoryMinimalFragmentDoc}
${FileDataFragmentDoc}`;
export const MyCompanyJobCreditsFragmentDoc = gql`
    fragment MyCompanyJobCredits on Company {
  id
  isPartner
  credits
}
    `;
export const CompanyCreditsMinimalFragmentDoc = gql`
    fragment CompanyCreditsMinimal on CompanyCredit {
  id
  type
  companyId
  regionLocked
  usedAt
  user {
    email
  }
}
    `;
export const DryRunDataFragmentDoc = gql`
    fragment dryRunData on DryRun {
  category {
    id
    texts
  }
  submittedAt
  duration
  passed
  correctCount
  questionCount
}
    `;
export const ExamDateDataFragmentDoc = gql`
    fragment ExamDateData on ExamDate {
  id
  season
  year
  examDate
}
    `;
export const IncentiveDataFragmentDoc = gql`
    fragment IncentiveData on Incentive {
  id
  createdAt
  status
  link
  couponCode
  level
  contact
  files {
    ...FileData
  }
  company {
    id
    name
  }
  texts
  clicks
  bimonthlyPerformance
}
    ${FileDataFragmentDoc}`;
export const JobDepartmentMinimalFragmentDoc = gql`
    fragment JobDepartmentMinimal on JobDepartment {
  id
  texts
}
    `;
export const JobEmploymentTypeMinimalFragmentDoc = gql`
    fragment JobEmploymentTypeMinimal on JobEmploymentType {
  id
  texts
}
    `;
export const JobDataFragmentDoc = gql`
    fragment JobData on Job {
  id
  createdAt
  status
  link
  code
  contact
  favoriteCount
  postalCode
  expiresAt
  extended
  files {
    ...FileData
  }
  jobDepartment {
    id
    texts
  }
  jobEmploymentType {
    id
    texts
  }
  company {
    id
    name
    isPartner
  }
  texts
  clicks
  bimonthlyPerformance
}
    ${FileDataFragmentDoc}`;
export const UserNotificationFragmentDoc = gql`
    fragment UserNotification on UserNotification {
  userId
  subject
  type
}
    `;
export const ProfessionMinimalFragmentDoc = gql`
    fragment ProfessionMinimal on Profession {
  id
  texts
}
    `;
export const QuestionGroupMinimalDataFragmentDoc = gql`
    fragment QuestionGroupMinimalData on QuestionGroup {
  id
  texts
  questionCount
  files {
    ...FileData
  }
}
    ${FileDataFragmentDoc}`;
export const CategoryDataFragmentDoc = gql`
    fragment CategoryData on Category {
  id
  professionId
  parentId
  texts
  files {
    ...FileData
  }
  questionGroups {
    ...QuestionGroupMinimalData
  }
}
    ${FileDataFragmentDoc}
${QuestionGroupMinimalDataFragmentDoc}`;
export const ProfessionDataFragmentDoc = gql`
    fragment ProfessionData on Profession {
  ...ProfessionMinimal
  status
  config
  examDates {
    id
    year
    examDate
    season
  }
  categories {
    ...CategoryData
  }
}
    ${ProfessionMinimalFragmentDoc}
${CategoryDataFragmentDoc}`;
export const QuestionGroupDataFragmentDoc = gql`
    fragment QuestionGroupData on QuestionGroup {
  ...QuestionGroupMinimalData
  questions {
    id
    texts
  }
}
    ${QuestionGroupMinimalDataFragmentDoc}`;
export const QuestionDataFragmentDoc = gql`
    fragment QuestionData on Question {
  id
  type
  texts
  files {
    ...FileData
  }
  inDuelAvailable
  config
  questionGroupId
  questionGroupOrder
  reviewStatus
  topicId
  questionKey
  topic {
    texts
    index
  }
  questionGroup {
    texts
  }
  category {
    id
    parentId
    texts
  }
  profession {
    id
    texts
  }
}
    ${FileDataFragmentDoc}`;
export const RolesMinimalFragmentDoc = gql`
    fragment RolesMinimal on Role {
  id
  key
}
    `;
export const TopicDataFragmentDoc = gql`
    fragment TopicData on Topic {
  id
  professionId
  texts
  index
  trainingYear
  profession {
    id
    texts
  }
}
    `;
export const TrainingCompanyMinimalFragmentDoc = gql`
    fragment TrainingCompanyMinimal on TrainingCompany {
  id
  name
  address
  postalCode
  city
  active
  provided
  texts
  files {
    ...FileData
  }
  createdAt
}
    ${FileDataFragmentDoc}`;
export const TrainingCompanyUserMinimalFragmentDoc = gql`
    fragment TrainingCompanyUserMinimal on User {
  id
  email
  firstname
  lastname
  active
  trainingCompanies {
    id
    name
  }
  examDate
  trainees {
    id
    email
    firstname
    lastname
  }
  confirmed
  traineeCount
  instructor {
    id
    firstname
    lastname
  }
  role {
    key
  }
  createdAt
  profession {
    id
    texts
  }
}
    `;
export const TrainingCompanyUserMinimalWithPlayerFragmentDoc = gql`
    fragment TrainingCompanyUserMinimalWithPlayer on User {
  ...TrainingCompanyUserMinimal
  player {
    id
    level
    points
    credits
    username
    duelStatistic
    duelRanking
  }
  studyTimes {
    time
    date
  }
  overallStudyTime
  trainingYear
  profession {
    topics {
      id
      texts
      index
    }
    categories {
      childCategories {
        id
        texts
      }
    }
  }
}
    ${TrainingCompanyUserMinimalFragmentDoc}`;
export const UserFullFragmentDoc = gql`
    fragment UserFull on User {
  id
  firstname
  lastname
  email
  roleId
  role {
    id
    key
  }
  files {
    ...FileData
  }
  companyId
  company {
    id
    name
  }
  trainingCompanies {
    id
    name
  }
  trainees {
    id
    email
  }
  traineeCount
  trainingYear
  professionId
  profession {
    id
    texts
  }
  instructor {
    id
    email
  }
  player {
    username
  }
  active
  createdAt
  notifications {
    subject
    type
  }
}
    ${FileDataFragmentDoc}`;
export const LegalTextDocument = gql`
    query legalText($key: String!) {
  legalText(key: $key)
}
    `;

/**
 * __useLegalTextQuery__
 *
 * To run a query within a React component, call `useLegalTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalTextQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useLegalTextQuery(baseOptions: Apollo.QueryHookOptions<LegalTextQuery, LegalTextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LegalTextQuery, LegalTextQueryVariables>(LegalTextDocument, options);
      }
export function useLegalTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LegalTextQuery, LegalTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LegalTextQuery, LegalTextQueryVariables>(LegalTextDocument, options);
        }
export type LegalTextQueryHookResult = ReturnType<typeof useLegalTextQuery>;
export type LegalTextLazyQueryHookResult = ReturnType<typeof useLegalTextLazyQuery>;
export type LegalTextQueryResult = Apollo.QueryResult<LegalTextQuery, LegalTextQueryVariables>;
export const LoginDocument = gql`
    mutation login($data: LoginInput!) {
  login(data: $data) {
    accessToken
    refreshToken
    user {
      id
      firstname
      lastname
      email
      role {
        key
        permissions {
          ability
          resource
        }
      }
      company {
        isPartner
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const SignupTrainingCompanyDocument = gql`
    mutation signupTrainingCompany($data: SignupTrainingCompanyInput!) {
  signupTrainingCompany(data: $data)
}
    `;
export type SignupTrainingCompanyMutationFn = Apollo.MutationFunction<SignupTrainingCompanyMutation, SignupTrainingCompanyMutationVariables>;

/**
 * __useSignupTrainingCompanyMutation__
 *
 * To run a mutation, you first call `useSignupTrainingCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupTrainingCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupTrainingCompanyMutation, { data, loading, error }] = useSignupTrainingCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignupTrainingCompanyMutation(baseOptions?: Apollo.MutationHookOptions<SignupTrainingCompanyMutation, SignupTrainingCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupTrainingCompanyMutation, SignupTrainingCompanyMutationVariables>(SignupTrainingCompanyDocument, options);
      }
export type SignupTrainingCompanyMutationHookResult = ReturnType<typeof useSignupTrainingCompanyMutation>;
export type SignupTrainingCompanyMutationResult = Apollo.MutationResult<SignupTrainingCompanyMutation>;
export type SignupTrainingCompanyMutationOptions = Apollo.BaseMutationOptions<SignupTrainingCompanyMutation, SignupTrainingCompanyMutationVariables>;
export const SignupCompanyDocument = gql`
    mutation signupCompany($data: SignupCompanyInput!) {
  signupCompany(data: $data)
}
    `;
export type SignupCompanyMutationFn = Apollo.MutationFunction<SignupCompanyMutation, SignupCompanyMutationVariables>;

/**
 * __useSignupCompanyMutation__
 *
 * To run a mutation, you first call `useSignupCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupCompanyMutation, { data, loading, error }] = useSignupCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignupCompanyMutation(baseOptions?: Apollo.MutationHookOptions<SignupCompanyMutation, SignupCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupCompanyMutation, SignupCompanyMutationVariables>(SignupCompanyDocument, options);
      }
export type SignupCompanyMutationHookResult = ReturnType<typeof useSignupCompanyMutation>;
export type SignupCompanyMutationResult = Apollo.MutationResult<SignupCompanyMutation>;
export type SignupCompanyMutationOptions = Apollo.BaseMutationOptions<SignupCompanyMutation, SignupCompanyMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($token: JWT!) {
  refreshToken(token: $token) {
    accessToken
    refreshToken
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($data: ChangePasswordInput!) {
  changePassword(data: $data) {
    id
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($token: String!, $password: String!) {
  resetPassword(token: $token, password: $password)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ConfirmMailDocument = gql`
    mutation confirmMail($token: String!) {
  confirmMail(token: $token)
}
    `;
export type ConfirmMailMutationFn = Apollo.MutationFunction<ConfirmMailMutation, ConfirmMailMutationVariables>;

/**
 * __useConfirmMailMutation__
 *
 * To run a mutation, you first call `useConfirmMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMailMutation, { data, loading, error }] = useConfirmMailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmMailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmMailMutation, ConfirmMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmMailMutation, ConfirmMailMutationVariables>(ConfirmMailDocument, options);
      }
export type ConfirmMailMutationHookResult = ReturnType<typeof useConfirmMailMutation>;
export type ConfirmMailMutationResult = Apollo.MutationResult<ConfirmMailMutation>;
export type ConfirmMailMutationOptions = Apollo.BaseMutationOptions<ConfirmMailMutation, ConfirmMailMutationVariables>;
export const CategoryDocument = gql`
    query category($id: String!) {
  category(id: $id) {
    ...CategoryData
  }
}
    ${CategoryDataFragmentDoc}`;

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryQuery(baseOptions: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
      }
export function useCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
        }
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = Apollo.QueryResult<CategoryQuery, CategoryQueryVariables>;
export const CategoryWithQuestionsDocument = gql`
    query categoryWithQuestions($id: String!) {
  category(id: $id) {
    ...CategoryData
    questions {
      ...QuestionData
    }
  }
}
    ${CategoryDataFragmentDoc}
${QuestionDataFragmentDoc}`;

/**
 * __useCategoryWithQuestionsQuery__
 *
 * To run a query within a React component, call `useCategoryWithQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryWithQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryWithQuestionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryWithQuestionsQuery(baseOptions: Apollo.QueryHookOptions<CategoryWithQuestionsQuery, CategoryWithQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryWithQuestionsQuery, CategoryWithQuestionsQueryVariables>(CategoryWithQuestionsDocument, options);
      }
export function useCategoryWithQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryWithQuestionsQuery, CategoryWithQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryWithQuestionsQuery, CategoryWithQuestionsQueryVariables>(CategoryWithQuestionsDocument, options);
        }
export type CategoryWithQuestionsQueryHookResult = ReturnType<typeof useCategoryWithQuestionsQuery>;
export type CategoryWithQuestionsLazyQueryHookResult = ReturnType<typeof useCategoryWithQuestionsLazyQuery>;
export type CategoryWithQuestionsQueryResult = Apollo.QueryResult<CategoryWithQuestionsQuery, CategoryWithQuestionsQueryVariables>;
export const CategoryWithQuestionGroupsDocument = gql`
    query categoryWithQuestionGroups($id: String!) {
  category(id: $id) {
    ...CategoryData
    questionGroups {
      ...QuestionGroupData
    }
  }
}
    ${CategoryDataFragmentDoc}
${QuestionGroupDataFragmentDoc}`;

/**
 * __useCategoryWithQuestionGroupsQuery__
 *
 * To run a query within a React component, call `useCategoryWithQuestionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryWithQuestionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryWithQuestionGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryWithQuestionGroupsQuery(baseOptions: Apollo.QueryHookOptions<CategoryWithQuestionGroupsQuery, CategoryWithQuestionGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryWithQuestionGroupsQuery, CategoryWithQuestionGroupsQueryVariables>(CategoryWithQuestionGroupsDocument, options);
      }
export function useCategoryWithQuestionGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryWithQuestionGroupsQuery, CategoryWithQuestionGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryWithQuestionGroupsQuery, CategoryWithQuestionGroupsQueryVariables>(CategoryWithQuestionGroupsDocument, options);
        }
export type CategoryWithQuestionGroupsQueryHookResult = ReturnType<typeof useCategoryWithQuestionGroupsQuery>;
export type CategoryWithQuestionGroupsLazyQueryHookResult = ReturnType<typeof useCategoryWithQuestionGroupsLazyQuery>;
export type CategoryWithQuestionGroupsQueryResult = Apollo.QueryResult<CategoryWithQuestionGroupsQuery, CategoryWithQuestionGroupsQueryVariables>;
export const CategoriesDocument = gql`
    query categories($parentId: String) {
  categories(parentId: $parentId) {
    ...CategoryData
  }
}
    ${CategoryDataFragmentDoc}`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const CategoriesWithChildrenDocument = gql`
    query categoriesWithChildren($parentId: String) {
  categories(parentId: $parentId) {
    ...CategoryData
    childCategories {
      ...CategoryData
    }
  }
}
    ${CategoryDataFragmentDoc}`;

/**
 * __useCategoriesWithChildrenQuery__
 *
 * To run a query within a React component, call `useCategoriesWithChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesWithChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesWithChildrenQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useCategoriesWithChildrenQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesWithChildrenQuery, CategoriesWithChildrenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesWithChildrenQuery, CategoriesWithChildrenQueryVariables>(CategoriesWithChildrenDocument, options);
      }
export function useCategoriesWithChildrenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesWithChildrenQuery, CategoriesWithChildrenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesWithChildrenQuery, CategoriesWithChildrenQueryVariables>(CategoriesWithChildrenDocument, options);
        }
export type CategoriesWithChildrenQueryHookResult = ReturnType<typeof useCategoriesWithChildrenQuery>;
export type CategoriesWithChildrenLazyQueryHookResult = ReturnType<typeof useCategoriesWithChildrenLazyQuery>;
export type CategoriesWithChildrenQueryResult = Apollo.QueryResult<CategoriesWithChildrenQuery, CategoriesWithChildrenQueryVariables>;
export const CreateCategoryDocument = gql`
    mutation createCategory($data: CreateCategoryInput!) {
  createCategory(data: $data) {
    ...CategoryData
  }
}
    ${CategoryDataFragmentDoc}`;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation deleteCategory($id: String!) {
  deleteCategory(id: $id) {
    ...CategoryData
  }
}
    ${CategoryDataFragmentDoc}`;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const DeleteCategoriesDocument = gql`
    mutation deleteCategories($ids: [String!]!) {
  deleteCategories(ids: $ids) {
    ...CategoryData
  }
}
    ${CategoryDataFragmentDoc}`;
export type DeleteCategoriesMutationFn = Apollo.MutationFunction<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>;

/**
 * __useDeleteCategoriesMutation__
 *
 * To run a mutation, you first call `useDeleteCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoriesMutation, { data, loading, error }] = useDeleteCategoriesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>(DeleteCategoriesDocument, options);
      }
export type DeleteCategoriesMutationHookResult = ReturnType<typeof useDeleteCategoriesMutation>;
export type DeleteCategoriesMutationResult = Apollo.MutationResult<DeleteCategoriesMutation>;
export type DeleteCategoriesMutationOptions = Apollo.BaseMutationOptions<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation updateCategory($id: String!, $data: UpdateCategoryInput!) {
  updateCategory(id: $id, data: $data) {
    ...CategoryData
  }
}
    ${CategoryDataFragmentDoc}`;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const MyCompanyDocument = gql`
    query myCompany {
  myCompany {
    ...MyCompanyMinimal
  }
}
    ${MyCompanyMinimalFragmentDoc}`;

/**
 * __useMyCompanyQuery__
 *
 * To run a query within a React component, call `useMyCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCompanyQuery(baseOptions?: Apollo.QueryHookOptions<MyCompanyQuery, MyCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyCompanyQuery, MyCompanyQueryVariables>(MyCompanyDocument, options);
      }
export function useMyCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyCompanyQuery, MyCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyCompanyQuery, MyCompanyQueryVariables>(MyCompanyDocument, options);
        }
export type MyCompanyQueryHookResult = ReturnType<typeof useMyCompanyQuery>;
export type MyCompanyLazyQueryHookResult = ReturnType<typeof useMyCompanyLazyQuery>;
export type MyCompanyQueryResult = Apollo.QueryResult<MyCompanyQuery, MyCompanyQueryVariables>;
export const MyCompanyCreditsDocument = gql`
    query myCompanyCredits {
  myCompany {
    ...MyCompanyJobCredits
  }
}
    ${MyCompanyJobCreditsFragmentDoc}`;

/**
 * __useMyCompanyCreditsQuery__
 *
 * To run a query within a React component, call `useMyCompanyCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCompanyCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCompanyCreditsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCompanyCreditsQuery(baseOptions?: Apollo.QueryHookOptions<MyCompanyCreditsQuery, MyCompanyCreditsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyCompanyCreditsQuery, MyCompanyCreditsQueryVariables>(MyCompanyCreditsDocument, options);
      }
export function useMyCompanyCreditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyCompanyCreditsQuery, MyCompanyCreditsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyCompanyCreditsQuery, MyCompanyCreditsQueryVariables>(MyCompanyCreditsDocument, options);
        }
export type MyCompanyCreditsQueryHookResult = ReturnType<typeof useMyCompanyCreditsQuery>;
export type MyCompanyCreditsLazyQueryHookResult = ReturnType<typeof useMyCompanyCreditsLazyQuery>;
export type MyCompanyCreditsQueryResult = Apollo.QueryResult<MyCompanyCreditsQuery, MyCompanyCreditsQueryVariables>;
export const JobCreditsDocument = gql`
    query jobCredits {
  myCompany {
    ...MyCompanyJobCredits
  }
}
    ${MyCompanyJobCreditsFragmentDoc}`;

/**
 * __useJobCreditsQuery__
 *
 * To run a query within a React component, call `useJobCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobCreditsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobCreditsQuery(baseOptions?: Apollo.QueryHookOptions<JobCreditsQuery, JobCreditsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobCreditsQuery, JobCreditsQueryVariables>(JobCreditsDocument, options);
      }
export function useJobCreditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobCreditsQuery, JobCreditsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobCreditsQuery, JobCreditsQueryVariables>(JobCreditsDocument, options);
        }
export type JobCreditsQueryHookResult = ReturnType<typeof useJobCreditsQuery>;
export type JobCreditsLazyQueryHookResult = ReturnType<typeof useJobCreditsLazyQuery>;
export type JobCreditsQueryResult = Apollo.QueryResult<JobCreditsQuery, JobCreditsQueryVariables>;
export const CompanyDocument = gql`
    query company($id: String!) {
  company(id: $id) {
    ...CompanyMinimal
  }
}
    ${CompanyMinimalFragmentDoc}`;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const CompanyWithCreditsDocument = gql`
    query companyWithCredits($id: String!, $exceededCredits: Boolean!) {
  company(id: $id) {
    id
    name
    isPartner
    credits(exceededCredits: $exceededCredits)
  }
}
    `;

/**
 * __useCompanyWithCreditsQuery__
 *
 * To run a query within a React component, call `useCompanyWithCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyWithCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyWithCreditsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      exceededCredits: // value for 'exceededCredits'
 *   },
 * });
 */
export function useCompanyWithCreditsQuery(baseOptions: Apollo.QueryHookOptions<CompanyWithCreditsQuery, CompanyWithCreditsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyWithCreditsQuery, CompanyWithCreditsQueryVariables>(CompanyWithCreditsDocument, options);
      }
export function useCompanyWithCreditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyWithCreditsQuery, CompanyWithCreditsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyWithCreditsQuery, CompanyWithCreditsQueryVariables>(CompanyWithCreditsDocument, options);
        }
export type CompanyWithCreditsQueryHookResult = ReturnType<typeof useCompanyWithCreditsQuery>;
export type CompanyWithCreditsLazyQueryHookResult = ReturnType<typeof useCompanyWithCreditsLazyQuery>;
export type CompanyWithCreditsQueryResult = Apollo.QueryResult<CompanyWithCreditsQuery, CompanyWithCreditsQueryVariables>;
export const CompaniesDocument = gql`
    query companies {
  companies {
    ...CompanyMinimal
  }
}
    ${CompanyMinimalFragmentDoc}`;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CompaniesPaginatedDocument = gql`
    query companiesPaginated($skip: Int, $after: String, $before: String, $first: Int, $last: Int, $orderBy: CompaniesOrderInput, $filter: [CompaniesFilterInput!]) {
  companiesPaginated(
    skip: $skip
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...CompanyMinimal
      }
    }
  }
}
    ${CompanyMinimalFragmentDoc}`;

/**
 * __useCompaniesPaginatedQuery__
 *
 * To run a query within a React component, call `useCompaniesPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesPaginatedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompaniesPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesPaginatedQuery, CompaniesPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesPaginatedQuery, CompaniesPaginatedQueryVariables>(CompaniesPaginatedDocument, options);
      }
export function useCompaniesPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesPaginatedQuery, CompaniesPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesPaginatedQuery, CompaniesPaginatedQueryVariables>(CompaniesPaginatedDocument, options);
        }
export type CompaniesPaginatedQueryHookResult = ReturnType<typeof useCompaniesPaginatedQuery>;
export type CompaniesPaginatedLazyQueryHookResult = ReturnType<typeof useCompaniesPaginatedLazyQuery>;
export type CompaniesPaginatedQueryResult = Apollo.QueryResult<CompaniesPaginatedQuery, CompaniesPaginatedQueryVariables>;
export const FilterCompaniesDocument = gql`
    query filterCompanies($filter: [CompaniesFilterInput!]) {
  filterCompanies(filter: $filter) {
    ...CompanyMinimal
  }
}
    ${CompanyMinimalFragmentDoc}`;

/**
 * __useFilterCompaniesQuery__
 *
 * To run a query within a React component, call `useFilterCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterCompaniesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFilterCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<FilterCompaniesQuery, FilterCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterCompaniesQuery, FilterCompaniesQueryVariables>(FilterCompaniesDocument, options);
      }
export function useFilterCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterCompaniesQuery, FilterCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterCompaniesQuery, FilterCompaniesQueryVariables>(FilterCompaniesDocument, options);
        }
export type FilterCompaniesQueryHookResult = ReturnType<typeof useFilterCompaniesQuery>;
export type FilterCompaniesLazyQueryHookResult = ReturnType<typeof useFilterCompaniesLazyQuery>;
export type FilterCompaniesQueryResult = Apollo.QueryResult<FilterCompaniesQuery, FilterCompaniesQueryVariables>;
export const CompanyNameOnlyDocument = gql`
    query companyNameOnly($id: String!) {
  company(id: $id) {
    id
    name
    isPartner
  }
}
    `;

/**
 * __useCompanyNameOnlyQuery__
 *
 * To run a query within a React component, call `useCompanyNameOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyNameOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyNameOnlyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyNameOnlyQuery(baseOptions: Apollo.QueryHookOptions<CompanyNameOnlyQuery, CompanyNameOnlyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyNameOnlyQuery, CompanyNameOnlyQueryVariables>(CompanyNameOnlyDocument, options);
      }
export function useCompanyNameOnlyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyNameOnlyQuery, CompanyNameOnlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyNameOnlyQuery, CompanyNameOnlyQueryVariables>(CompanyNameOnlyDocument, options);
        }
export type CompanyNameOnlyQueryHookResult = ReturnType<typeof useCompanyNameOnlyQuery>;
export type CompanyNameOnlyLazyQueryHookResult = ReturnType<typeof useCompanyNameOnlyLazyQuery>;
export type CompanyNameOnlyQueryResult = Apollo.QueryResult<CompanyNameOnlyQuery, CompanyNameOnlyQueryVariables>;
export const CompaniesNameOnlyDocument = gql`
    query companiesNameOnly {
  companies {
    id
    name
    isPartner
  }
}
    `;

/**
 * __useCompaniesNameOnlyQuery__
 *
 * To run a query within a React component, call `useCompaniesNameOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesNameOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesNameOnlyQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesNameOnlyQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesNameOnlyQuery, CompaniesNameOnlyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesNameOnlyQuery, CompaniesNameOnlyQueryVariables>(CompaniesNameOnlyDocument, options);
      }
export function useCompaniesNameOnlyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesNameOnlyQuery, CompaniesNameOnlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesNameOnlyQuery, CompaniesNameOnlyQueryVariables>(CompaniesNameOnlyDocument, options);
        }
export type CompaniesNameOnlyQueryHookResult = ReturnType<typeof useCompaniesNameOnlyQuery>;
export type CompaniesNameOnlyLazyQueryHookResult = ReturnType<typeof useCompaniesNameOnlyLazyQuery>;
export type CompaniesNameOnlyQueryResult = Apollo.QueryResult<CompaniesNameOnlyQuery, CompaniesNameOnlyQueryVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($data: CreateCompanyInput!) {
  createCompany(data: $data) {
    ...CompanyMinimal
  }
}
    ${CompanyMinimalFragmentDoc}`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($id: String!, $data: UpdateCompanyInput!) {
  updateCompany(id: $id, data: $data) {
    ...CompanyMinimal
  }
}
    ${CompanyMinimalFragmentDoc}`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const UpdateMyCompanyDocument = gql`
    mutation updateMyCompany($data: UpdateMyCompanyInput!) {
  updateMyCompany(data: $data) {
    ...MyCompanyMinimal
  }
}
    ${MyCompanyMinimalFragmentDoc}`;
export type UpdateMyCompanyMutationFn = Apollo.MutationFunction<UpdateMyCompanyMutation, UpdateMyCompanyMutationVariables>;

/**
 * __useUpdateMyCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateMyCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyCompanyMutation, { data, loading, error }] = useUpdateMyCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMyCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMyCompanyMutation, UpdateMyCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMyCompanyMutation, UpdateMyCompanyMutationVariables>(UpdateMyCompanyDocument, options);
      }
export type UpdateMyCompanyMutationHookResult = ReturnType<typeof useUpdateMyCompanyMutation>;
export type UpdateMyCompanyMutationResult = Apollo.MutationResult<UpdateMyCompanyMutation>;
export type UpdateMyCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateMyCompanyMutation, UpdateMyCompanyMutationVariables>;
export const RequestCompanyUserDocument = gql`
    mutation requestCompanyUser($data: RequestCompanyUserInput!) {
  requestCompanyUser(data: $data)
}
    `;
export type RequestCompanyUserMutationFn = Apollo.MutationFunction<RequestCompanyUserMutation, RequestCompanyUserMutationVariables>;

/**
 * __useRequestCompanyUserMutation__
 *
 * To run a mutation, you first call `useRequestCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCompanyUserMutation, { data, loading, error }] = useRequestCompanyUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRequestCompanyUserMutation(baseOptions?: Apollo.MutationHookOptions<RequestCompanyUserMutation, RequestCompanyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestCompanyUserMutation, RequestCompanyUserMutationVariables>(RequestCompanyUserDocument, options);
      }
export type RequestCompanyUserMutationHookResult = ReturnType<typeof useRequestCompanyUserMutation>;
export type RequestCompanyUserMutationResult = Apollo.MutationResult<RequestCompanyUserMutation>;
export type RequestCompanyUserMutationOptions = Apollo.BaseMutationOptions<RequestCompanyUserMutation, RequestCompanyUserMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($id: String!) {
  deleteCompany(id: $id) {
    ...CompanyMinimal
  }
}
    ${CompanyMinimalFragmentDoc}`;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const DeleteCompaniesDocument = gql`
    mutation deleteCompanies($data: DeleteCompaniesInput!) {
  deleteCompanies(data: $data) {
    ...CompanyMinimal
  }
}
    ${CompanyMinimalFragmentDoc}`;
export type DeleteCompaniesMutationFn = Apollo.MutationFunction<DeleteCompaniesMutation, DeleteCompaniesMutationVariables>;

/**
 * __useDeleteCompaniesMutation__
 *
 * To run a mutation, you first call `useDeleteCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompaniesMutation, { data, loading, error }] = useDeleteCompaniesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompaniesMutation, DeleteCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompaniesMutation, DeleteCompaniesMutationVariables>(DeleteCompaniesDocument, options);
      }
export type DeleteCompaniesMutationHookResult = ReturnType<typeof useDeleteCompaniesMutation>;
export type DeleteCompaniesMutationResult = Apollo.MutationResult<DeleteCompaniesMutation>;
export type DeleteCompaniesMutationOptions = Apollo.BaseMutationOptions<DeleteCompaniesMutation, DeleteCompaniesMutationVariables>;
export const CompanyCategoriesDocument = gql`
    query CompanyCategories {
  companyCategories {
    ...CompanyCategoryMinimal
  }
}
    ${CompanyCategoryMinimalFragmentDoc}`;

/**
 * __useCompanyCategoriesQuery__
 *
 * To run a query within a React component, call `useCompanyCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CompanyCategoriesQuery, CompanyCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyCategoriesQuery, CompanyCategoriesQueryVariables>(CompanyCategoriesDocument, options);
      }
export function useCompanyCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyCategoriesQuery, CompanyCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyCategoriesQuery, CompanyCategoriesQueryVariables>(CompanyCategoriesDocument, options);
        }
export type CompanyCategoriesQueryHookResult = ReturnType<typeof useCompanyCategoriesQuery>;
export type CompanyCategoriesLazyQueryHookResult = ReturnType<typeof useCompanyCategoriesLazyQuery>;
export type CompanyCategoriesQueryResult = Apollo.QueryResult<CompanyCategoriesQuery, CompanyCategoriesQueryVariables>;
export const DeleteCompanyCategoryDocument = gql`
    mutation deleteCompanyCategory($id: String!) {
  deleteCompanyCategory(id: $id) {
    ...CompanyCategoryMinimal
  }
}
    ${CompanyCategoryMinimalFragmentDoc}`;
export type DeleteCompanyCategoryMutationFn = Apollo.MutationFunction<DeleteCompanyCategoryMutation, DeleteCompanyCategoryMutationVariables>;

/**
 * __useDeleteCompanyCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyCategoryMutation, { data, loading, error }] = useDeleteCompanyCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyCategoryMutation, DeleteCompanyCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyCategoryMutation, DeleteCompanyCategoryMutationVariables>(DeleteCompanyCategoryDocument, options);
      }
export type DeleteCompanyCategoryMutationHookResult = ReturnType<typeof useDeleteCompanyCategoryMutation>;
export type DeleteCompanyCategoryMutationResult = Apollo.MutationResult<DeleteCompanyCategoryMutation>;
export type DeleteCompanyCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyCategoryMutation, DeleteCompanyCategoryMutationVariables>;
export const ExamDatesDocument = gql`
    query examDates($professionId: String!) {
  examDates(professionId: $professionId) {
    ...ExamDateData
  }
}
    ${ExamDateDataFragmentDoc}`;

/**
 * __useExamDatesQuery__
 *
 * To run a query within a React component, call `useExamDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamDatesQuery({
 *   variables: {
 *      professionId: // value for 'professionId'
 *   },
 * });
 */
export function useExamDatesQuery(baseOptions: Apollo.QueryHookOptions<ExamDatesQuery, ExamDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExamDatesQuery, ExamDatesQueryVariables>(ExamDatesDocument, options);
      }
export function useExamDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExamDatesQuery, ExamDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExamDatesQuery, ExamDatesQueryVariables>(ExamDatesDocument, options);
        }
export type ExamDatesQueryHookResult = ReturnType<typeof useExamDatesQuery>;
export type ExamDatesLazyQueryHookResult = ReturnType<typeof useExamDatesLazyQuery>;
export type ExamDatesQueryResult = Apollo.QueryResult<ExamDatesQuery, ExamDatesQueryVariables>;
export const UploadFileDocument = gql`
    mutation uploadFile($data: UploadFileInput!, $file: Upload!) {
  uploadFile(data: $data, file: $file) {
    ...FileData
  }
}
    ${FileDataFragmentDoc}`;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      data: // value for 'data'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const UpdateFileDocument = gql`
    mutation updateFile($data: UpdateFileInput!, $id: String!) {
  updateFileInfo(data: $data, id: $id) {
    ...FileData
  }
}
    ${FileDataFragmentDoc}`;
export type UpdateFileMutationFn = Apollo.MutationFunction<UpdateFileMutation, UpdateFileMutationVariables>;

/**
 * __useUpdateFileMutation__
 *
 * To run a mutation, you first call `useUpdateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileMutation, { data, loading, error }] = useUpdateFileMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateFileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFileMutation, UpdateFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFileMutation, UpdateFileMutationVariables>(UpdateFileDocument, options);
      }
export type UpdateFileMutationHookResult = ReturnType<typeof useUpdateFileMutation>;
export type UpdateFileMutationResult = Apollo.MutationResult<UpdateFileMutation>;
export type UpdateFileMutationOptions = Apollo.BaseMutationOptions<UpdateFileMutation, UpdateFileMutationVariables>;
export const DeleteFileDocument = gql`
    mutation deleteFile($id: String!) {
  deleteFile(id: $id) {
    id
  }
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export const DeleteFilesDocument = gql`
    mutation deleteFiles($ids: [String!]!) {
  deleteFiles(ids: $ids)
}
    `;
export type DeleteFilesMutationFn = Apollo.MutationFunction<DeleteFilesMutation, DeleteFilesMutationVariables>;

/**
 * __useDeleteFilesMutation__
 *
 * To run a mutation, you first call `useDeleteFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFilesMutation, { data, loading, error }] = useDeleteFilesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteFilesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFilesMutation, DeleteFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFilesMutation, DeleteFilesMutationVariables>(DeleteFilesDocument, options);
      }
export type DeleteFilesMutationHookResult = ReturnType<typeof useDeleteFilesMutation>;
export type DeleteFilesMutationResult = Apollo.MutationResult<DeleteFilesMutation>;
export type DeleteFilesMutationOptions = Apollo.BaseMutationOptions<DeleteFilesMutation, DeleteFilesMutationVariables>;
export const IncentivesDocument = gql`
    query incentives($companyId: String) {
  incentives(companyId: $companyId) {
    ...IncentiveData
  }
}
    ${IncentiveDataFragmentDoc}`;

/**
 * __useIncentivesQuery__
 *
 * To run a query within a React component, call `useIncentivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncentivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncentivesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useIncentivesQuery(baseOptions?: Apollo.QueryHookOptions<IncentivesQuery, IncentivesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncentivesQuery, IncentivesQueryVariables>(IncentivesDocument, options);
      }
export function useIncentivesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncentivesQuery, IncentivesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncentivesQuery, IncentivesQueryVariables>(IncentivesDocument, options);
        }
export type IncentivesQueryHookResult = ReturnType<typeof useIncentivesQuery>;
export type IncentivesLazyQueryHookResult = ReturnType<typeof useIncentivesLazyQuery>;
export type IncentivesQueryResult = Apollo.QueryResult<IncentivesQuery, IncentivesQueryVariables>;
export const IncentiveDocument = gql`
    query incentive($id: String!) {
  incentive(id: $id) {
    ...IncentiveData
  }
}
    ${IncentiveDataFragmentDoc}`;

/**
 * __useIncentiveQuery__
 *
 * To run a query within a React component, call `useIncentiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncentiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncentiveQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIncentiveQuery(baseOptions: Apollo.QueryHookOptions<IncentiveQuery, IncentiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncentiveQuery, IncentiveQueryVariables>(IncentiveDocument, options);
      }
export function useIncentiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncentiveQuery, IncentiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncentiveQuery, IncentiveQueryVariables>(IncentiveDocument, options);
        }
export type IncentiveQueryHookResult = ReturnType<typeof useIncentiveQuery>;
export type IncentiveLazyQueryHookResult = ReturnType<typeof useIncentiveLazyQuery>;
export type IncentiveQueryResult = Apollo.QueryResult<IncentiveQuery, IncentiveQueryVariables>;
export const CreateIncentiveDocument = gql`
    mutation createIncentive($data: CreateIncentiveInput!) {
  createIncentive(data: $data) {
    ...IncentiveData
  }
}
    ${IncentiveDataFragmentDoc}`;
export type CreateIncentiveMutationFn = Apollo.MutationFunction<CreateIncentiveMutation, CreateIncentiveMutationVariables>;

/**
 * __useCreateIncentiveMutation__
 *
 * To run a mutation, you first call `useCreateIncentiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIncentiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIncentiveMutation, { data, loading, error }] = useCreateIncentiveMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateIncentiveMutation(baseOptions?: Apollo.MutationHookOptions<CreateIncentiveMutation, CreateIncentiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIncentiveMutation, CreateIncentiveMutationVariables>(CreateIncentiveDocument, options);
      }
export type CreateIncentiveMutationHookResult = ReturnType<typeof useCreateIncentiveMutation>;
export type CreateIncentiveMutationResult = Apollo.MutationResult<CreateIncentiveMutation>;
export type CreateIncentiveMutationOptions = Apollo.BaseMutationOptions<CreateIncentiveMutation, CreateIncentiveMutationVariables>;
export const UpdateIncentiveDocument = gql`
    mutation updateIncentive($id: String!, $data: UpdateIncentiveInput!) {
  updateIncentive(id: $id, data: $data) {
    ...IncentiveData
  }
}
    ${IncentiveDataFragmentDoc}`;
export type UpdateIncentiveMutationFn = Apollo.MutationFunction<UpdateIncentiveMutation, UpdateIncentiveMutationVariables>;

/**
 * __useUpdateIncentiveMutation__
 *
 * To run a mutation, you first call `useUpdateIncentiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIncentiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncentiveMutation, { data, loading, error }] = useUpdateIncentiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateIncentiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIncentiveMutation, UpdateIncentiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIncentiveMutation, UpdateIncentiveMutationVariables>(UpdateIncentiveDocument, options);
      }
export type UpdateIncentiveMutationHookResult = ReturnType<typeof useUpdateIncentiveMutation>;
export type UpdateIncentiveMutationResult = Apollo.MutationResult<UpdateIncentiveMutation>;
export type UpdateIncentiveMutationOptions = Apollo.BaseMutationOptions<UpdateIncentiveMutation, UpdateIncentiveMutationVariables>;
export const DeleteIncentiveDocument = gql`
    mutation deleteIncentive($id: String!) {
  deleteIncentive(id: $id) {
    ...IncentiveData
  }
}
    ${IncentiveDataFragmentDoc}`;
export type DeleteIncentiveMutationFn = Apollo.MutationFunction<DeleteIncentiveMutation, DeleteIncentiveMutationVariables>;

/**
 * __useDeleteIncentiveMutation__
 *
 * To run a mutation, you first call `useDeleteIncentiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIncentiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIncentiveMutation, { data, loading, error }] = useDeleteIncentiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIncentiveMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIncentiveMutation, DeleteIncentiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIncentiveMutation, DeleteIncentiveMutationVariables>(DeleteIncentiveDocument, options);
      }
export type DeleteIncentiveMutationHookResult = ReturnType<typeof useDeleteIncentiveMutation>;
export type DeleteIncentiveMutationResult = Apollo.MutationResult<DeleteIncentiveMutation>;
export type DeleteIncentiveMutationOptions = Apollo.BaseMutationOptions<DeleteIncentiveMutation, DeleteIncentiveMutationVariables>;
export const DeleteIncentivesDocument = gql`
    mutation deleteIncentives($data: DeleteIncentivesInput!) {
  deleteIncentives(data: $data) {
    ...IncentiveData
  }
}
    ${IncentiveDataFragmentDoc}`;
export type DeleteIncentivesMutationFn = Apollo.MutationFunction<DeleteIncentivesMutation, DeleteIncentivesMutationVariables>;

/**
 * __useDeleteIncentivesMutation__
 *
 * To run a mutation, you first call `useDeleteIncentivesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIncentivesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIncentivesMutation, { data, loading, error }] = useDeleteIncentivesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteIncentivesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIncentivesMutation, DeleteIncentivesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIncentivesMutation, DeleteIncentivesMutationVariables>(DeleteIncentivesDocument, options);
      }
export type DeleteIncentivesMutationHookResult = ReturnType<typeof useDeleteIncentivesMutation>;
export type DeleteIncentivesMutationResult = Apollo.MutationResult<DeleteIncentivesMutation>;
export type DeleteIncentivesMutationOptions = Apollo.BaseMutationOptions<DeleteIncentivesMutation, DeleteIncentivesMutationVariables>;
export const JobDepartmentsDocument = gql`
    query jobDepartments {
  jobDepartments {
    ...JobDepartmentMinimal
  }
}
    ${JobDepartmentMinimalFragmentDoc}`;

/**
 * __useJobDepartmentsQuery__
 *
 * To run a query within a React component, call `useJobDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobDepartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobDepartmentsQuery(baseOptions?: Apollo.QueryHookOptions<JobDepartmentsQuery, JobDepartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobDepartmentsQuery, JobDepartmentsQueryVariables>(JobDepartmentsDocument, options);
      }
export function useJobDepartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobDepartmentsQuery, JobDepartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobDepartmentsQuery, JobDepartmentsQueryVariables>(JobDepartmentsDocument, options);
        }
export type JobDepartmentsQueryHookResult = ReturnType<typeof useJobDepartmentsQuery>;
export type JobDepartmentsLazyQueryHookResult = ReturnType<typeof useJobDepartmentsLazyQuery>;
export type JobDepartmentsQueryResult = Apollo.QueryResult<JobDepartmentsQuery, JobDepartmentsQueryVariables>;
export const JobEmploymentTypesDocument = gql`
    query jobEmploymentTypes {
  jobEmploymentTypes {
    ...JobEmploymentTypeMinimal
  }
}
    ${JobEmploymentTypeMinimalFragmentDoc}`;

/**
 * __useJobEmploymentTypesQuery__
 *
 * To run a query within a React component, call `useJobEmploymentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobEmploymentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobEmploymentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobEmploymentTypesQuery(baseOptions?: Apollo.QueryHookOptions<JobEmploymentTypesQuery, JobEmploymentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobEmploymentTypesQuery, JobEmploymentTypesQueryVariables>(JobEmploymentTypesDocument, options);
      }
export function useJobEmploymentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobEmploymentTypesQuery, JobEmploymentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobEmploymentTypesQuery, JobEmploymentTypesQueryVariables>(JobEmploymentTypesDocument, options);
        }
export type JobEmploymentTypesQueryHookResult = ReturnType<typeof useJobEmploymentTypesQuery>;
export type JobEmploymentTypesLazyQueryHookResult = ReturnType<typeof useJobEmploymentTypesLazyQuery>;
export type JobEmploymentTypesQueryResult = Apollo.QueryResult<JobEmploymentTypesQuery, JobEmploymentTypesQueryVariables>;
export const JobsDocument = gql`
    query jobs($companyId: String) {
  jobs(companyId: $companyId) {
    ...JobData
  }
}
    ${JobDataFragmentDoc}`;

/**
 * __useJobsQuery__
 *
 * To run a query within a React component, call `useJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useJobsQuery(baseOptions?: Apollo.QueryHookOptions<JobsQuery, JobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
      }
export function useJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsQuery, JobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
        }
export type JobsQueryHookResult = ReturnType<typeof useJobsQuery>;
export type JobsLazyQueryHookResult = ReturnType<typeof useJobsLazyQuery>;
export type JobsQueryResult = Apollo.QueryResult<JobsQuery, JobsQueryVariables>;
export const JobDocument = gql`
    query job($id: String!) {
  job(id: $id) {
    ...JobData
  }
}
    ${JobDataFragmentDoc}`;

/**
 * __useJobQuery__
 *
 * To run a query within a React component, call `useJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobQuery(baseOptions: Apollo.QueryHookOptions<JobQuery, JobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobQuery, JobQueryVariables>(JobDocument, options);
      }
export function useJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobQuery, JobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobQuery, JobQueryVariables>(JobDocument, options);
        }
export type JobQueryHookResult = ReturnType<typeof useJobQuery>;
export type JobLazyQueryHookResult = ReturnType<typeof useJobLazyQuery>;
export type JobQueryResult = Apollo.QueryResult<JobQuery, JobQueryVariables>;
export const ActivateJobDocument = gql`
    mutation activateJob($id: String!) {
  activateJob(id: $id) {
    ...JobData
  }
}
    ${JobDataFragmentDoc}`;
export type ActivateJobMutationFn = Apollo.MutationFunction<ActivateJobMutation, ActivateJobMutationVariables>;

/**
 * __useActivateJobMutation__
 *
 * To run a mutation, you first call `useActivateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateJobMutation, { data, loading, error }] = useActivateJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateJobMutation(baseOptions?: Apollo.MutationHookOptions<ActivateJobMutation, ActivateJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateJobMutation, ActivateJobMutationVariables>(ActivateJobDocument, options);
      }
export type ActivateJobMutationHookResult = ReturnType<typeof useActivateJobMutation>;
export type ActivateJobMutationResult = Apollo.MutationResult<ActivateJobMutation>;
export type ActivateJobMutationOptions = Apollo.BaseMutationOptions<ActivateJobMutation, ActivateJobMutationVariables>;
export const ExtendJobDocument = gql`
    mutation extendJob($id: String!) {
  extendJob(id: $id) {
    ...JobData
  }
}
    ${JobDataFragmentDoc}`;
export type ExtendJobMutationFn = Apollo.MutationFunction<ExtendJobMutation, ExtendJobMutationVariables>;

/**
 * __useExtendJobMutation__
 *
 * To run a mutation, you first call `useExtendJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendJobMutation, { data, loading, error }] = useExtendJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExtendJobMutation(baseOptions?: Apollo.MutationHookOptions<ExtendJobMutation, ExtendJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExtendJobMutation, ExtendJobMutationVariables>(ExtendJobDocument, options);
      }
export type ExtendJobMutationHookResult = ReturnType<typeof useExtendJobMutation>;
export type ExtendJobMutationResult = Apollo.MutationResult<ExtendJobMutation>;
export type ExtendJobMutationOptions = Apollo.BaseMutationOptions<ExtendJobMutation, ExtendJobMutationVariables>;
export const CreateJobDocument = gql`
    mutation createJob($data: CreateJobInput!) {
  createJob(data: $data) {
    ...JobData
  }
}
    ${JobDataFragmentDoc}`;
export type CreateJobMutationFn = Apollo.MutationFunction<CreateJobMutation, CreateJobMutationVariables>;

/**
 * __useCreateJobMutation__
 *
 * To run a mutation, you first call `useCreateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobMutation, { data, loading, error }] = useCreateJobMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateJobMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobMutation, CreateJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJobMutation, CreateJobMutationVariables>(CreateJobDocument, options);
      }
export type CreateJobMutationHookResult = ReturnType<typeof useCreateJobMutation>;
export type CreateJobMutationResult = Apollo.MutationResult<CreateJobMutation>;
export type CreateJobMutationOptions = Apollo.BaseMutationOptions<CreateJobMutation, CreateJobMutationVariables>;
export const UpdateJobDocument = gql`
    mutation updateJob($id: String!, $data: UpdateJobInput!) {
  updateJob(id: $id, data: $data) {
    ...JobData
  }
}
    ${JobDataFragmentDoc}`;
export type UpdateJobMutationFn = Apollo.MutationFunction<UpdateJobMutation, UpdateJobMutationVariables>;

/**
 * __useUpdateJobMutation__
 *
 * To run a mutation, you first call `useUpdateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobMutation, { data, loading, error }] = useUpdateJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateJobMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobMutation, UpdateJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobMutation, UpdateJobMutationVariables>(UpdateJobDocument, options);
      }
export type UpdateJobMutationHookResult = ReturnType<typeof useUpdateJobMutation>;
export type UpdateJobMutationResult = Apollo.MutationResult<UpdateJobMutation>;
export type UpdateJobMutationOptions = Apollo.BaseMutationOptions<UpdateJobMutation, UpdateJobMutationVariables>;
export const DeleteJobDocument = gql`
    mutation deleteJob($id: String!) {
  deleteJob(id: $id) {
    ...JobData
  }
}
    ${JobDataFragmentDoc}`;
export type DeleteJobMutationFn = Apollo.MutationFunction<DeleteJobMutation, DeleteJobMutationVariables>;

/**
 * __useDeleteJobMutation__
 *
 * To run a mutation, you first call `useDeleteJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobMutation, { data, loading, error }] = useDeleteJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteJobMutation(baseOptions?: Apollo.MutationHookOptions<DeleteJobMutation, DeleteJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteJobMutation, DeleteJobMutationVariables>(DeleteJobDocument, options);
      }
export type DeleteJobMutationHookResult = ReturnType<typeof useDeleteJobMutation>;
export type DeleteJobMutationResult = Apollo.MutationResult<DeleteJobMutation>;
export type DeleteJobMutationOptions = Apollo.BaseMutationOptions<DeleteJobMutation, DeleteJobMutationVariables>;
export const DeleteJobsDocument = gql`
    mutation deleteJobs($data: DeleteJobsInput!) {
  deleteJobs(data: $data) {
    ...JobData
  }
}
    ${JobDataFragmentDoc}`;
export type DeleteJobsMutationFn = Apollo.MutationFunction<DeleteJobsMutation, DeleteJobsMutationVariables>;

/**
 * __useDeleteJobsMutation__
 *
 * To run a mutation, you first call `useDeleteJobsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobsMutation, { data, loading, error }] = useDeleteJobsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteJobsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteJobsMutation, DeleteJobsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteJobsMutation, DeleteJobsMutationVariables>(DeleteJobsDocument, options);
      }
export type DeleteJobsMutationHookResult = ReturnType<typeof useDeleteJobsMutation>;
export type DeleteJobsMutationResult = Apollo.MutationResult<DeleteJobsMutation>;
export type DeleteJobsMutationOptions = Apollo.BaseMutationOptions<DeleteJobsMutation, DeleteJobsMutationVariables>;
export const SubscribeNotificationsDocument = gql`
    mutation subscribeNotifications($data: [SubscribeNotificationInput!]!) {
  subscribeNotifications(data: $data) {
    ...UserNotification
  }
}
    ${UserNotificationFragmentDoc}`;
export type SubscribeNotificationsMutationFn = Apollo.MutationFunction<SubscribeNotificationsMutation, SubscribeNotificationsMutationVariables>;

/**
 * __useSubscribeNotificationsMutation__
 *
 * To run a mutation, you first call `useSubscribeNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeNotificationsMutation, { data, loading, error }] = useSubscribeNotificationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubscribeNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeNotificationsMutation, SubscribeNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeNotificationsMutation, SubscribeNotificationsMutationVariables>(SubscribeNotificationsDocument, options);
      }
export type SubscribeNotificationsMutationHookResult = ReturnType<typeof useSubscribeNotificationsMutation>;
export type SubscribeNotificationsMutationResult = Apollo.MutationResult<SubscribeNotificationsMutation>;
export type SubscribeNotificationsMutationOptions = Apollo.BaseMutationOptions<SubscribeNotificationsMutation, SubscribeNotificationsMutationVariables>;
export const UnsubscribeNotificationsDocument = gql`
    mutation unsubscribeNotifications($subjects: [String!]!) {
  unsubscribeNotifications(subjects: $subjects) {
    ...UserNotification
  }
}
    ${UserNotificationFragmentDoc}`;
export type UnsubscribeNotificationsMutationFn = Apollo.MutationFunction<UnsubscribeNotificationsMutation, UnsubscribeNotificationsMutationVariables>;

/**
 * __useUnsubscribeNotificationsMutation__
 *
 * To run a mutation, you first call `useUnsubscribeNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeNotificationsMutation, { data, loading, error }] = useUnsubscribeNotificationsMutation({
 *   variables: {
 *      subjects: // value for 'subjects'
 *   },
 * });
 */
export function useUnsubscribeNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeNotificationsMutation, UnsubscribeNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsubscribeNotificationsMutation, UnsubscribeNotificationsMutationVariables>(UnsubscribeNotificationsDocument, options);
      }
export type UnsubscribeNotificationsMutationHookResult = ReturnType<typeof useUnsubscribeNotificationsMutation>;
export type UnsubscribeNotificationsMutationResult = Apollo.MutationResult<UnsubscribeNotificationsMutation>;
export type UnsubscribeNotificationsMutationOptions = Apollo.BaseMutationOptions<UnsubscribeNotificationsMutation, UnsubscribeNotificationsMutationVariables>;
export const ProfessionDocument = gql`
    query profession($id: String!) {
  profession(id: $id) {
    ...ProfessionData
  }
}
    ${ProfessionDataFragmentDoc}`;

/**
 * __useProfessionQuery__
 *
 * To run a query within a React component, call `useProfessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfessionQuery(baseOptions: Apollo.QueryHookOptions<ProfessionQuery, ProfessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfessionQuery, ProfessionQueryVariables>(ProfessionDocument, options);
      }
export function useProfessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfessionQuery, ProfessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfessionQuery, ProfessionQueryVariables>(ProfessionDocument, options);
        }
export type ProfessionQueryHookResult = ReturnType<typeof useProfessionQuery>;
export type ProfessionLazyQueryHookResult = ReturnType<typeof useProfessionLazyQuery>;
export type ProfessionQueryResult = Apollo.QueryResult<ProfessionQuery, ProfessionQueryVariables>;
export const ProfessionWithCategoriesDocument = gql`
    query professionWithCategories($id: String!) {
  profession(id: $id) {
    ...ProfessionData
    categories {
      id
      texts
      childCategories {
        id
        texts
      }
    }
  }
}
    ${ProfessionDataFragmentDoc}`;

/**
 * __useProfessionWithCategoriesQuery__
 *
 * To run a query within a React component, call `useProfessionWithCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfessionWithCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfessionWithCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfessionWithCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ProfessionWithCategoriesQuery, ProfessionWithCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfessionWithCategoriesQuery, ProfessionWithCategoriesQueryVariables>(ProfessionWithCategoriesDocument, options);
      }
export function useProfessionWithCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfessionWithCategoriesQuery, ProfessionWithCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfessionWithCategoriesQuery, ProfessionWithCategoriesQueryVariables>(ProfessionWithCategoriesDocument, options);
        }
export type ProfessionWithCategoriesQueryHookResult = ReturnType<typeof useProfessionWithCategoriesQuery>;
export type ProfessionWithCategoriesLazyQueryHookResult = ReturnType<typeof useProfessionWithCategoriesLazyQuery>;
export type ProfessionWithCategoriesQueryResult = Apollo.QueryResult<ProfessionWithCategoriesQuery, ProfessionWithCategoriesQueryVariables>;
export const ProfessionsDocument = gql`
    query professions {
  professions {
    ...ProfessionData
  }
}
    ${ProfessionDataFragmentDoc}`;

/**
 * __useProfessionsQuery__
 *
 * To run a query within a React component, call `useProfessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfessionsQuery(baseOptions?: Apollo.QueryHookOptions<ProfessionsQuery, ProfessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfessionsQuery, ProfessionsQueryVariables>(ProfessionsDocument, options);
      }
export function useProfessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfessionsQuery, ProfessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfessionsQuery, ProfessionsQueryVariables>(ProfessionsDocument, options);
        }
export type ProfessionsQueryHookResult = ReturnType<typeof useProfessionsQuery>;
export type ProfessionsLazyQueryHookResult = ReturnType<typeof useProfessionsLazyQuery>;
export type ProfessionsQueryResult = Apollo.QueryResult<ProfessionsQuery, ProfessionsQueryVariables>;
export const ProfessionsMinimalDocument = gql`
    query professionsMinimal {
  professions {
    ...ProfessionMinimal
  }
}
    ${ProfessionMinimalFragmentDoc}`;

/**
 * __useProfessionsMinimalQuery__
 *
 * To run a query within a React component, call `useProfessionsMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfessionsMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfessionsMinimalQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfessionsMinimalQuery(baseOptions?: Apollo.QueryHookOptions<ProfessionsMinimalQuery, ProfessionsMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfessionsMinimalQuery, ProfessionsMinimalQueryVariables>(ProfessionsMinimalDocument, options);
      }
export function useProfessionsMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfessionsMinimalQuery, ProfessionsMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfessionsMinimalQuery, ProfessionsMinimalQueryVariables>(ProfessionsMinimalDocument, options);
        }
export type ProfessionsMinimalQueryHookResult = ReturnType<typeof useProfessionsMinimalQuery>;
export type ProfessionsMinimalLazyQueryHookResult = ReturnType<typeof useProfessionsMinimalLazyQuery>;
export type ProfessionsMinimalQueryResult = Apollo.QueryResult<ProfessionsMinimalQuery, ProfessionsMinimalQueryVariables>;
export const ProfessionsWithCategoriesDocument = gql`
    query professionsWithCategories {
  professions {
    id
    texts
    categories {
      id
      texts
      childCategories {
        id
        texts
      }
    }
  }
}
    `;

/**
 * __useProfessionsWithCategoriesQuery__
 *
 * To run a query within a React component, call `useProfessionsWithCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfessionsWithCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfessionsWithCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfessionsWithCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ProfessionsWithCategoriesQuery, ProfessionsWithCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfessionsWithCategoriesQuery, ProfessionsWithCategoriesQueryVariables>(ProfessionsWithCategoriesDocument, options);
      }
export function useProfessionsWithCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfessionsWithCategoriesQuery, ProfessionsWithCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfessionsWithCategoriesQuery, ProfessionsWithCategoriesQueryVariables>(ProfessionsWithCategoriesDocument, options);
        }
export type ProfessionsWithCategoriesQueryHookResult = ReturnType<typeof useProfessionsWithCategoriesQuery>;
export type ProfessionsWithCategoriesLazyQueryHookResult = ReturnType<typeof useProfessionsWithCategoriesLazyQuery>;
export type ProfessionsWithCategoriesQueryResult = Apollo.QueryResult<ProfessionsWithCategoriesQuery, ProfessionsWithCategoriesQueryVariables>;
export const CreateProfessionDocument = gql`
    mutation createProfession($data: CreateProfessionInput!) {
  createProfession(data: $data) {
    ...ProfessionData
  }
}
    ${ProfessionDataFragmentDoc}`;
export type CreateProfessionMutationFn = Apollo.MutationFunction<CreateProfessionMutation, CreateProfessionMutationVariables>;

/**
 * __useCreateProfessionMutation__
 *
 * To run a mutation, you first call `useCreateProfessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfessionMutation, { data, loading, error }] = useCreateProfessionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProfessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateProfessionMutation, CreateProfessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProfessionMutation, CreateProfessionMutationVariables>(CreateProfessionDocument, options);
      }
export type CreateProfessionMutationHookResult = ReturnType<typeof useCreateProfessionMutation>;
export type CreateProfessionMutationResult = Apollo.MutationResult<CreateProfessionMutation>;
export type CreateProfessionMutationOptions = Apollo.BaseMutationOptions<CreateProfessionMutation, CreateProfessionMutationVariables>;
export const UpdateProfessionDocument = gql`
    mutation updateProfession($id: String!, $data: UpdateProfessionInput!) {
  updateProfession(id: $id, data: $data) {
    ...ProfessionData
  }
}
    ${ProfessionDataFragmentDoc}`;
export type UpdateProfessionMutationFn = Apollo.MutationFunction<UpdateProfessionMutation, UpdateProfessionMutationVariables>;

/**
 * __useUpdateProfessionMutation__
 *
 * To run a mutation, you first call `useUpdateProfessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfessionMutation, { data, loading, error }] = useUpdateProfessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProfessionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfessionMutation, UpdateProfessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfessionMutation, UpdateProfessionMutationVariables>(UpdateProfessionDocument, options);
      }
export type UpdateProfessionMutationHookResult = ReturnType<typeof useUpdateProfessionMutation>;
export type UpdateProfessionMutationResult = Apollo.MutationResult<UpdateProfessionMutation>;
export type UpdateProfessionMutationOptions = Apollo.BaseMutationOptions<UpdateProfessionMutation, UpdateProfessionMutationVariables>;
export const DeleteProfessionDocument = gql`
    mutation deleteProfession($id: String!) {
  deleteProfession(id: $id) {
    ...ProfessionData
  }
}
    ${ProfessionDataFragmentDoc}`;
export type DeleteProfessionMutationFn = Apollo.MutationFunction<DeleteProfessionMutation, DeleteProfessionMutationVariables>;

/**
 * __useDeleteProfessionMutation__
 *
 * To run a mutation, you first call `useDeleteProfessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfessionMutation, { data, loading, error }] = useDeleteProfessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProfessionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProfessionMutation, DeleteProfessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProfessionMutation, DeleteProfessionMutationVariables>(DeleteProfessionDocument, options);
      }
export type DeleteProfessionMutationHookResult = ReturnType<typeof useDeleteProfessionMutation>;
export type DeleteProfessionMutationResult = Apollo.MutationResult<DeleteProfessionMutation>;
export type DeleteProfessionMutationOptions = Apollo.BaseMutationOptions<DeleteProfessionMutation, DeleteProfessionMutationVariables>;
export const DeleteProfessionsDocument = gql`
    mutation deleteProfessions($ids: [String!]!) {
  deleteProfessions(ids: $ids) {
    ...ProfessionData
  }
}
    ${ProfessionDataFragmentDoc}`;
export type DeleteProfessionsMutationFn = Apollo.MutationFunction<DeleteProfessionsMutation, DeleteProfessionsMutationVariables>;

/**
 * __useDeleteProfessionsMutation__
 *
 * To run a mutation, you first call `useDeleteProfessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfessionsMutation, { data, loading, error }] = useDeleteProfessionsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteProfessionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProfessionsMutation, DeleteProfessionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProfessionsMutation, DeleteProfessionsMutationVariables>(DeleteProfessionsDocument, options);
      }
export type DeleteProfessionsMutationHookResult = ReturnType<typeof useDeleteProfessionsMutation>;
export type DeleteProfessionsMutationResult = Apollo.MutationResult<DeleteProfessionsMutation>;
export type DeleteProfessionsMutationOptions = Apollo.BaseMutationOptions<DeleteProfessionsMutation, DeleteProfessionsMutationVariables>;
export const QuestionGroupDocument = gql`
    query questionGroup($id: String!) {
  questionGroup(id: $id) {
    ...QuestionGroupData
  }
}
    ${QuestionGroupDataFragmentDoc}`;

/**
 * __useQuestionGroupQuery__
 *
 * To run a query within a React component, call `useQuestionGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuestionGroupQuery(baseOptions: Apollo.QueryHookOptions<QuestionGroupQuery, QuestionGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionGroupQuery, QuestionGroupQueryVariables>(QuestionGroupDocument, options);
      }
export function useQuestionGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionGroupQuery, QuestionGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionGroupQuery, QuestionGroupQueryVariables>(QuestionGroupDocument, options);
        }
export type QuestionGroupQueryHookResult = ReturnType<typeof useQuestionGroupQuery>;
export type QuestionGroupLazyQueryHookResult = ReturnType<typeof useQuestionGroupLazyQuery>;
export type QuestionGroupQueryResult = Apollo.QueryResult<QuestionGroupQuery, QuestionGroupQueryVariables>;
export const QuestionGroupsDocument = gql`
    query questionGroups($categoryId: String) {
  questionGroups(categoryId: $categoryId) {
    ...QuestionGroupData
  }
}
    ${QuestionGroupDataFragmentDoc}`;

/**
 * __useQuestionGroupsQuery__
 *
 * To run a query within a React component, call `useQuestionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionGroupsQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useQuestionGroupsQuery(baseOptions?: Apollo.QueryHookOptions<QuestionGroupsQuery, QuestionGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionGroupsQuery, QuestionGroupsQueryVariables>(QuestionGroupsDocument, options);
      }
export function useQuestionGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionGroupsQuery, QuestionGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionGroupsQuery, QuestionGroupsQueryVariables>(QuestionGroupsDocument, options);
        }
export type QuestionGroupsQueryHookResult = ReturnType<typeof useQuestionGroupsQuery>;
export type QuestionGroupsLazyQueryHookResult = ReturnType<typeof useQuestionGroupsLazyQuery>;
export type QuestionGroupsQueryResult = Apollo.QueryResult<QuestionGroupsQuery, QuestionGroupsQueryVariables>;
export const QuestionGroupWithQuestionsDocument = gql`
    query questionGroupWithQuestions($id: String!) {
  questionGroup(id: $id) {
    ...QuestionGroupData
  }
}
    ${QuestionGroupDataFragmentDoc}`;

/**
 * __useQuestionGroupWithQuestionsQuery__
 *
 * To run a query within a React component, call `useQuestionGroupWithQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionGroupWithQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionGroupWithQuestionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuestionGroupWithQuestionsQuery(baseOptions: Apollo.QueryHookOptions<QuestionGroupWithQuestionsQuery, QuestionGroupWithQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionGroupWithQuestionsQuery, QuestionGroupWithQuestionsQueryVariables>(QuestionGroupWithQuestionsDocument, options);
      }
export function useQuestionGroupWithQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionGroupWithQuestionsQuery, QuestionGroupWithQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionGroupWithQuestionsQuery, QuestionGroupWithQuestionsQueryVariables>(QuestionGroupWithQuestionsDocument, options);
        }
export type QuestionGroupWithQuestionsQueryHookResult = ReturnType<typeof useQuestionGroupWithQuestionsQuery>;
export type QuestionGroupWithQuestionsLazyQueryHookResult = ReturnType<typeof useQuestionGroupWithQuestionsLazyQuery>;
export type QuestionGroupWithQuestionsQueryResult = Apollo.QueryResult<QuestionGroupWithQuestionsQuery, QuestionGroupWithQuestionsQueryVariables>;
export const CreateQuestionGroupDocument = gql`
    mutation createQuestionGroup($data: CreateQuestionGroupInput!) {
  createQuestionGroup(data: $data) {
    ...QuestionGroupData
  }
}
    ${QuestionGroupDataFragmentDoc}`;
export type CreateQuestionGroupMutationFn = Apollo.MutationFunction<CreateQuestionGroupMutation, CreateQuestionGroupMutationVariables>;

/**
 * __useCreateQuestionGroupMutation__
 *
 * To run a mutation, you first call `useCreateQuestionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionGroupMutation, { data, loading, error }] = useCreateQuestionGroupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateQuestionGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionGroupMutation, CreateQuestionGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionGroupMutation, CreateQuestionGroupMutationVariables>(CreateQuestionGroupDocument, options);
      }
export type CreateQuestionGroupMutationHookResult = ReturnType<typeof useCreateQuestionGroupMutation>;
export type CreateQuestionGroupMutationResult = Apollo.MutationResult<CreateQuestionGroupMutation>;
export type CreateQuestionGroupMutationOptions = Apollo.BaseMutationOptions<CreateQuestionGroupMutation, CreateQuestionGroupMutationVariables>;
export const UpdateQuestionGroupDocument = gql`
    mutation updateQuestionGroup($id: String!, $data: UpdateQuestionGroupInput!) {
  updateQuestionGroup(id: $id, data: $data) {
    ...QuestionGroupData
  }
}
    ${QuestionGroupDataFragmentDoc}`;
export type UpdateQuestionGroupMutationFn = Apollo.MutationFunction<UpdateQuestionGroupMutation, UpdateQuestionGroupMutationVariables>;

/**
 * __useUpdateQuestionGroupMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionGroupMutation, { data, loading, error }] = useUpdateQuestionGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateQuestionGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionGroupMutation, UpdateQuestionGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionGroupMutation, UpdateQuestionGroupMutationVariables>(UpdateQuestionGroupDocument, options);
      }
export type UpdateQuestionGroupMutationHookResult = ReturnType<typeof useUpdateQuestionGroupMutation>;
export type UpdateQuestionGroupMutationResult = Apollo.MutationResult<UpdateQuestionGroupMutation>;
export type UpdateQuestionGroupMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionGroupMutation, UpdateQuestionGroupMutationVariables>;
export const DeleteQuestionGroupDocument = gql`
    mutation deleteQuestionGroup($id: String!) {
  deleteQuestionGroup(id: $id) {
    ...QuestionGroupData
  }
}
    ${QuestionGroupDataFragmentDoc}`;
export type DeleteQuestionGroupMutationFn = Apollo.MutationFunction<DeleteQuestionGroupMutation, DeleteQuestionGroupMutationVariables>;

/**
 * __useDeleteQuestionGroupMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionGroupMutation, { data, loading, error }] = useDeleteQuestionGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionGroupMutation, DeleteQuestionGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionGroupMutation, DeleteQuestionGroupMutationVariables>(DeleteQuestionGroupDocument, options);
      }
export type DeleteQuestionGroupMutationHookResult = ReturnType<typeof useDeleteQuestionGroupMutation>;
export type DeleteQuestionGroupMutationResult = Apollo.MutationResult<DeleteQuestionGroupMutation>;
export type DeleteQuestionGroupMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionGroupMutation, DeleteQuestionGroupMutationVariables>;
export const DeleteQuestionGroupsDocument = gql`
    mutation deleteQuestionGroups($ids: [String!]!) {
  deleteQuestionGroups(ids: $ids) {
    ...QuestionGroupData
  }
}
    ${QuestionGroupDataFragmentDoc}`;
export type DeleteQuestionGroupsMutationFn = Apollo.MutationFunction<DeleteQuestionGroupsMutation, DeleteQuestionGroupsMutationVariables>;

/**
 * __useDeleteQuestionGroupsMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionGroupsMutation, { data, loading, error }] = useDeleteQuestionGroupsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteQuestionGroupsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionGroupsMutation, DeleteQuestionGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionGroupsMutation, DeleteQuestionGroupsMutationVariables>(DeleteQuestionGroupsDocument, options);
      }
export type DeleteQuestionGroupsMutationHookResult = ReturnType<typeof useDeleteQuestionGroupsMutation>;
export type DeleteQuestionGroupsMutationResult = Apollo.MutationResult<DeleteQuestionGroupsMutation>;
export type DeleteQuestionGroupsMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionGroupsMutation, DeleteQuestionGroupsMutationVariables>;
export const QuestionDocument = gql`
    query question($id: String!) {
  question(id: $id) {
    ...QuestionData
  }
}
    ${QuestionDataFragmentDoc}`;

/**
 * __useQuestionQuery__
 *
 * To run a query within a React component, call `useQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuestionQuery(baseOptions: Apollo.QueryHookOptions<QuestionQuery, QuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionQuery, QuestionQueryVariables>(QuestionDocument, options);
      }
export function useQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionQuery, QuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionQuery, QuestionQueryVariables>(QuestionDocument, options);
        }
export type QuestionQueryHookResult = ReturnType<typeof useQuestionQuery>;
export type QuestionLazyQueryHookResult = ReturnType<typeof useQuestionLazyQuery>;
export type QuestionQueryResult = Apollo.QueryResult<QuestionQuery, QuestionQueryVariables>;
export const QuestionsDocument = gql`
    query questions($data: QueryQuestionsInput!) {
  questions(data: $data) {
    ...QuestionData
  }
}
    ${QuestionDataFragmentDoc}`;

/**
 * __useQuestionsQuery__
 *
 * To run a query within a React component, call `useQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useQuestionsQuery(baseOptions: Apollo.QueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
      }
export function useQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
        }
export type QuestionsQueryHookResult = ReturnType<typeof useQuestionsQuery>;
export type QuestionsLazyQueryHookResult = ReturnType<typeof useQuestionsLazyQuery>;
export type QuestionsQueryResult = Apollo.QueryResult<QuestionsQuery, QuestionsQueryVariables>;
export const QuestionsPaginatedDocument = gql`
    query questionsPaginated($skip: Int, $after: String, $before: String, $first: Int, $last: Int, $orderBy: QuestionsOrderInput, $filter: [QuestionsFilterInput!], $connector: FilterConnectionOperators) {
  questionsPaginated(
    skip: $skip
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    filter: $filter
    connector: $connector
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...QuestionData
      }
    }
  }
}
    ${QuestionDataFragmentDoc}`;

/**
 * __useQuestionsPaginatedQuery__
 *
 * To run a query within a React component, call `useQuestionsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsPaginatedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *      connector: // value for 'connector'
 *   },
 * });
 */
export function useQuestionsPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<QuestionsPaginatedQuery, QuestionsPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionsPaginatedQuery, QuestionsPaginatedQueryVariables>(QuestionsPaginatedDocument, options);
      }
export function useQuestionsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionsPaginatedQuery, QuestionsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionsPaginatedQuery, QuestionsPaginatedQueryVariables>(QuestionsPaginatedDocument, options);
        }
export type QuestionsPaginatedQueryHookResult = ReturnType<typeof useQuestionsPaginatedQuery>;
export type QuestionsPaginatedLazyQueryHookResult = ReturnType<typeof useQuestionsPaginatedLazyQuery>;
export type QuestionsPaginatedQueryResult = Apollo.QueryResult<QuestionsPaginatedQuery, QuestionsPaginatedQueryVariables>;
export const QuestionsCountDocument = gql`
    query questionsCount($filter: [QuestionsFilterInput!], $connector: FilterConnectionOperators) {
  questionsCount(filter: $filter, connector: $connector)
}
    `;

/**
 * __useQuestionsCountQuery__
 *
 * To run a query within a React component, call `useQuestionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      connector: // value for 'connector'
 *   },
 * });
 */
export function useQuestionsCountQuery(baseOptions?: Apollo.QueryHookOptions<QuestionsCountQuery, QuestionsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionsCountQuery, QuestionsCountQueryVariables>(QuestionsCountDocument, options);
      }
export function useQuestionsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionsCountQuery, QuestionsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionsCountQuery, QuestionsCountQueryVariables>(QuestionsCountDocument, options);
        }
export type QuestionsCountQueryHookResult = ReturnType<typeof useQuestionsCountQuery>;
export type QuestionsCountLazyQueryHookResult = ReturnType<typeof useQuestionsCountLazyQuery>;
export type QuestionsCountQueryResult = Apollo.QueryResult<QuestionsCountQuery, QuestionsCountQueryVariables>;
export const CreateQuestionDocument = gql`
    mutation createQuestion($data: CreateQuestionInput!) {
  createQuestion(data: $data) {
    ...QuestionData
  }
}
    ${QuestionDataFragmentDoc}`;
export type CreateQuestionMutationFn = Apollo.MutationFunction<CreateQuestionMutation, CreateQuestionMutationVariables>;

/**
 * __useCreateQuestionMutation__
 *
 * To run a mutation, you first call `useCreateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionMutation, { data, loading, error }] = useCreateQuestionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionMutation, CreateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionMutation, CreateQuestionMutationVariables>(CreateQuestionDocument, options);
      }
export type CreateQuestionMutationHookResult = ReturnType<typeof useCreateQuestionMutation>;
export type CreateQuestionMutationResult = Apollo.MutationResult<CreateQuestionMutation>;
export type CreateQuestionMutationOptions = Apollo.BaseMutationOptions<CreateQuestionMutation, CreateQuestionMutationVariables>;
export const UpdateQuestionDocument = gql`
    mutation updateQuestion($id: String!, $data: UpdateQuestionInput!, $removedTexts: [String!]) {
  updateQuestion(id: $id, data: $data, removedTexts: $removedTexts) {
    ...QuestionData
  }
}
    ${QuestionDataFragmentDoc}`;
export type UpdateQuestionMutationFn = Apollo.MutationFunction<UpdateQuestionMutation, UpdateQuestionMutationVariables>;

/**
 * __useUpdateQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionMutation, { data, loading, error }] = useUpdateQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *      removedTexts: // value for 'removedTexts'
 *   },
 * });
 */
export function useUpdateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(UpdateQuestionDocument, options);
      }
export type UpdateQuestionMutationHookResult = ReturnType<typeof useUpdateQuestionMutation>;
export type UpdateQuestionMutationResult = Apollo.MutationResult<UpdateQuestionMutation>;
export type UpdateQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>;
export const UpdateQuestionsStatusDocument = gql`
    mutation updateQuestionsStatus($ids: [String!]!, $reviewStatus: ReviewStatus!) {
  updateQuestionsStatus(ids: $ids, reviewStatus: $reviewStatus) {
    ...QuestionData
  }
}
    ${QuestionDataFragmentDoc}`;
export type UpdateQuestionsStatusMutationFn = Apollo.MutationFunction<UpdateQuestionsStatusMutation, UpdateQuestionsStatusMutationVariables>;

/**
 * __useUpdateQuestionsStatusMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionsStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionsStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionsStatusMutation, { data, loading, error }] = useUpdateQuestionsStatusMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      reviewStatus: // value for 'reviewStatus'
 *   },
 * });
 */
export function useUpdateQuestionsStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionsStatusMutation, UpdateQuestionsStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionsStatusMutation, UpdateQuestionsStatusMutationVariables>(UpdateQuestionsStatusDocument, options);
      }
export type UpdateQuestionsStatusMutationHookResult = ReturnType<typeof useUpdateQuestionsStatusMutation>;
export type UpdateQuestionsStatusMutationResult = Apollo.MutationResult<UpdateQuestionsStatusMutation>;
export type UpdateQuestionsStatusMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionsStatusMutation, UpdateQuestionsStatusMutationVariables>;
export const DuplicateQuestionsDocument = gql`
    mutation duplicateQuestions($ids: [String!]!, $data: DuplicateQuestionInput!) {
  duplicateQuestions(ids: $ids, data: $data) {
    ...QuestionData
  }
}
    ${QuestionDataFragmentDoc}`;
export type DuplicateQuestionsMutationFn = Apollo.MutationFunction<DuplicateQuestionsMutation, DuplicateQuestionsMutationVariables>;

/**
 * __useDuplicateQuestionsMutation__
 *
 * To run a mutation, you first call `useDuplicateQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateQuestionsMutation, { data, loading, error }] = useDuplicateQuestionsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDuplicateQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateQuestionsMutation, DuplicateQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateQuestionsMutation, DuplicateQuestionsMutationVariables>(DuplicateQuestionsDocument, options);
      }
export type DuplicateQuestionsMutationHookResult = ReturnType<typeof useDuplicateQuestionsMutation>;
export type DuplicateQuestionsMutationResult = Apollo.MutationResult<DuplicateQuestionsMutation>;
export type DuplicateQuestionsMutationOptions = Apollo.BaseMutationOptions<DuplicateQuestionsMutation, DuplicateQuestionsMutationVariables>;
export const DeleteQuestionDocument = gql`
    mutation deleteQuestion($id: String!) {
  deleteQuestion(id: $id) {
    ...QuestionData
  }
}
    ${QuestionDataFragmentDoc}`;
export type DeleteQuestionMutationFn = Apollo.MutationFunction<DeleteQuestionMutation, DeleteQuestionMutationVariables>;

/**
 * __useDeleteQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionMutation, { data, loading, error }] = useDeleteQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionMutation, DeleteQuestionMutationVariables>(DeleteQuestionDocument, options);
      }
export type DeleteQuestionMutationHookResult = ReturnType<typeof useDeleteQuestionMutation>;
export type DeleteQuestionMutationResult = Apollo.MutationResult<DeleteQuestionMutation>;
export type DeleteQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>;
export const DeleteQuestionsDocument = gql`
    mutation deleteQuestions($ids: [String!]!) {
  deleteQuestions(ids: $ids) {
    ...QuestionData
  }
}
    ${QuestionDataFragmentDoc}`;
export type DeleteQuestionsMutationFn = Apollo.MutationFunction<DeleteQuestionsMutation, DeleteQuestionsMutationVariables>;

/**
 * __useDeleteQuestionsMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionsMutation, { data, loading, error }] = useDeleteQuestionsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionsMutation, DeleteQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionsMutation, DeleteQuestionsMutationVariables>(DeleteQuestionsDocument, options);
      }
export type DeleteQuestionsMutationHookResult = ReturnType<typeof useDeleteQuestionsMutation>;
export type DeleteQuestionsMutationResult = Apollo.MutationResult<DeleteQuestionsMutation>;
export type DeleteQuestionsMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionsMutation, DeleteQuestionsMutationVariables>;
export const RolesDocument = gql`
    query roles {
  roles {
    ...RolesMinimal
  }
}
    ${RolesMinimalFragmentDoc}`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const TopicDocument = gql`
    query topic($id: String!) {
  topic(id: $id) {
    ...TopicData
  }
}
    ${TopicDataFragmentDoc}`;

/**
 * __useTopicQuery__
 *
 * To run a query within a React component, call `useTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopicQuery(baseOptions: Apollo.QueryHookOptions<TopicQuery, TopicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicQuery, TopicQueryVariables>(TopicDocument, options);
      }
export function useTopicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicQuery, TopicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicQuery, TopicQueryVariables>(TopicDocument, options);
        }
export type TopicQueryHookResult = ReturnType<typeof useTopicQuery>;
export type TopicLazyQueryHookResult = ReturnType<typeof useTopicLazyQuery>;
export type TopicQueryResult = Apollo.QueryResult<TopicQuery, TopicQueryVariables>;
export const TopicWithQuestionsDocument = gql`
    query topicWithQuestions($id: String!) {
  topic(id: $id) {
    ...TopicData
    questions {
      ...QuestionData
    }
  }
}
    ${TopicDataFragmentDoc}
${QuestionDataFragmentDoc}`;

/**
 * __useTopicWithQuestionsQuery__
 *
 * To run a query within a React component, call `useTopicWithQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicWithQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicWithQuestionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopicWithQuestionsQuery(baseOptions: Apollo.QueryHookOptions<TopicWithQuestionsQuery, TopicWithQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicWithQuestionsQuery, TopicWithQuestionsQueryVariables>(TopicWithQuestionsDocument, options);
      }
export function useTopicWithQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicWithQuestionsQuery, TopicWithQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicWithQuestionsQuery, TopicWithQuestionsQueryVariables>(TopicWithQuestionsDocument, options);
        }
export type TopicWithQuestionsQueryHookResult = ReturnType<typeof useTopicWithQuestionsQuery>;
export type TopicWithQuestionsLazyQueryHookResult = ReturnType<typeof useTopicWithQuestionsLazyQuery>;
export type TopicWithQuestionsQueryResult = Apollo.QueryResult<TopicWithQuestionsQuery, TopicWithQuestionsQueryVariables>;
export const TopicsDocument = gql`
    query topics($professionId: String!) {
  topics(professionId: $professionId) {
    ...TopicData
  }
}
    ${TopicDataFragmentDoc}`;

/**
 * __useTopicsQuery__
 *
 * To run a query within a React component, call `useTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicsQuery({
 *   variables: {
 *      professionId: // value for 'professionId'
 *   },
 * });
 */
export function useTopicsQuery(baseOptions: Apollo.QueryHookOptions<TopicsQuery, TopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options);
      }
export function useTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicsQuery, TopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options);
        }
export type TopicsQueryHookResult = ReturnType<typeof useTopicsQuery>;
export type TopicsLazyQueryHookResult = ReturnType<typeof useTopicsLazyQuery>;
export type TopicsQueryResult = Apollo.QueryResult<TopicsQuery, TopicsQueryVariables>;
export const CreateTopicDocument = gql`
    mutation createTopic($data: CreateTopicInput!) {
  createTopic(data: $data) {
    ...TopicData
  }
}
    ${TopicDataFragmentDoc}`;
export type CreateTopicMutationFn = Apollo.MutationFunction<CreateTopicMutation, CreateTopicMutationVariables>;

/**
 * __useCreateTopicMutation__
 *
 * To run a mutation, you first call `useCreateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopicMutation, { data, loading, error }] = useCreateTopicMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTopicMutation(baseOptions?: Apollo.MutationHookOptions<CreateTopicMutation, CreateTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTopicMutation, CreateTopicMutationVariables>(CreateTopicDocument, options);
      }
export type CreateTopicMutationHookResult = ReturnType<typeof useCreateTopicMutation>;
export type CreateTopicMutationResult = Apollo.MutationResult<CreateTopicMutation>;
export type CreateTopicMutationOptions = Apollo.BaseMutationOptions<CreateTopicMutation, CreateTopicMutationVariables>;
export const DeleteTopicDocument = gql`
    mutation deleteTopic($id: String!) {
  deleteTopic(id: $id) {
    ...TopicData
  }
}
    ${TopicDataFragmentDoc}`;
export type DeleteTopicMutationFn = Apollo.MutationFunction<DeleteTopicMutation, DeleteTopicMutationVariables>;

/**
 * __useDeleteTopicMutation__
 *
 * To run a mutation, you first call `useDeleteTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTopicMutation, { data, loading, error }] = useDeleteTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTopicMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTopicMutation, DeleteTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTopicMutation, DeleteTopicMutationVariables>(DeleteTopicDocument, options);
      }
export type DeleteTopicMutationHookResult = ReturnType<typeof useDeleteTopicMutation>;
export type DeleteTopicMutationResult = Apollo.MutationResult<DeleteTopicMutation>;
export type DeleteTopicMutationOptions = Apollo.BaseMutationOptions<DeleteTopicMutation, DeleteTopicMutationVariables>;
export const DeleteTopicsDocument = gql`
    mutation deleteTopics($ids: [String!]!) {
  deleteTopics(ids: $ids) {
    ...TopicData
  }
}
    ${TopicDataFragmentDoc}`;
export type DeleteTopicsMutationFn = Apollo.MutationFunction<DeleteTopicsMutation, DeleteTopicsMutationVariables>;

/**
 * __useDeleteTopicsMutation__
 *
 * To run a mutation, you first call `useDeleteTopicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTopicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTopicsMutation, { data, loading, error }] = useDeleteTopicsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteTopicsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTopicsMutation, DeleteTopicsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTopicsMutation, DeleteTopicsMutationVariables>(DeleteTopicsDocument, options);
      }
export type DeleteTopicsMutationHookResult = ReturnType<typeof useDeleteTopicsMutation>;
export type DeleteTopicsMutationResult = Apollo.MutationResult<DeleteTopicsMutation>;
export type DeleteTopicsMutationOptions = Apollo.BaseMutationOptions<DeleteTopicsMutation, DeleteTopicsMutationVariables>;
export const UpdateTopicDocument = gql`
    mutation updateTopic($id: String!, $data: UpdateTopicInput!) {
  updateTopic(id: $id, data: $data) {
    ...TopicData
  }
}
    ${TopicDataFragmentDoc}`;
export type UpdateTopicMutationFn = Apollo.MutationFunction<UpdateTopicMutation, UpdateTopicMutationVariables>;

/**
 * __useUpdateTopicMutation__
 *
 * To run a mutation, you first call `useUpdateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicMutation, { data, loading, error }] = useUpdateTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTopicMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopicMutation, UpdateTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopicMutation, UpdateTopicMutationVariables>(UpdateTopicDocument, options);
      }
export type UpdateTopicMutationHookResult = ReturnType<typeof useUpdateTopicMutation>;
export type UpdateTopicMutationResult = Apollo.MutationResult<UpdateTopicMutation>;
export type UpdateTopicMutationOptions = Apollo.BaseMutationOptions<UpdateTopicMutation, UpdateTopicMutationVariables>;
export const DashboardTrackingJobsDocument = gql`
    query dashboardTrackingJobs($companyId: String) {
  dashboardTrackingJobs(companyId: $companyId)
}
    `;

/**
 * __useDashboardTrackingJobsQuery__
 *
 * To run a query within a React component, call `useDashboardTrackingJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardTrackingJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardTrackingJobsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDashboardTrackingJobsQuery(baseOptions?: Apollo.QueryHookOptions<DashboardTrackingJobsQuery, DashboardTrackingJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardTrackingJobsQuery, DashboardTrackingJobsQueryVariables>(DashboardTrackingJobsDocument, options);
      }
export function useDashboardTrackingJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardTrackingJobsQuery, DashboardTrackingJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardTrackingJobsQuery, DashboardTrackingJobsQueryVariables>(DashboardTrackingJobsDocument, options);
        }
export type DashboardTrackingJobsQueryHookResult = ReturnType<typeof useDashboardTrackingJobsQuery>;
export type DashboardTrackingJobsLazyQueryHookResult = ReturnType<typeof useDashboardTrackingJobsLazyQuery>;
export type DashboardTrackingJobsQueryResult = Apollo.QueryResult<DashboardTrackingJobsQuery, DashboardTrackingJobsQueryVariables>;
export const DashboardTrackingIncentivesDocument = gql`
    query dashboardTrackingIncentives($companyId: String) {
  dashboardTrackingIncentives(companyId: $companyId)
}
    `;

/**
 * __useDashboardTrackingIncentivesQuery__
 *
 * To run a query within a React component, call `useDashboardTrackingIncentivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardTrackingIncentivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardTrackingIncentivesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDashboardTrackingIncentivesQuery(baseOptions?: Apollo.QueryHookOptions<DashboardTrackingIncentivesQuery, DashboardTrackingIncentivesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardTrackingIncentivesQuery, DashboardTrackingIncentivesQueryVariables>(DashboardTrackingIncentivesDocument, options);
      }
export function useDashboardTrackingIncentivesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardTrackingIncentivesQuery, DashboardTrackingIncentivesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardTrackingIncentivesQuery, DashboardTrackingIncentivesQueryVariables>(DashboardTrackingIncentivesDocument, options);
        }
export type DashboardTrackingIncentivesQueryHookResult = ReturnType<typeof useDashboardTrackingIncentivesQuery>;
export type DashboardTrackingIncentivesLazyQueryHookResult = ReturnType<typeof useDashboardTrackingIncentivesLazyQuery>;
export type DashboardTrackingIncentivesQueryResult = Apollo.QueryResult<DashboardTrackingIncentivesQuery, DashboardTrackingIncentivesQueryVariables>;
export const JobTrackingDocument = gql`
    query jobTracking($jobId: String!) {
  dashboardTrackingJob(jobId: $jobId)
}
    `;

/**
 * __useJobTrackingQuery__
 *
 * To run a query within a React component, call `useJobTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobTrackingQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobTrackingQuery(baseOptions: Apollo.QueryHookOptions<JobTrackingQuery, JobTrackingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobTrackingQuery, JobTrackingQueryVariables>(JobTrackingDocument, options);
      }
export function useJobTrackingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobTrackingQuery, JobTrackingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobTrackingQuery, JobTrackingQueryVariables>(JobTrackingDocument, options);
        }
export type JobTrackingQueryHookResult = ReturnType<typeof useJobTrackingQuery>;
export type JobTrackingLazyQueryHookResult = ReturnType<typeof useJobTrackingLazyQuery>;
export type JobTrackingQueryResult = Apollo.QueryResult<JobTrackingQuery, JobTrackingQueryVariables>;
export const IncentiveTrackingDocument = gql`
    query incentiveTracking($incentiveId: String!) {
  dashboardTrackingIncentive(incentiveId: $incentiveId)
}
    `;

/**
 * __useIncentiveTrackingQuery__
 *
 * To run a query within a React component, call `useIncentiveTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncentiveTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncentiveTrackingQuery({
 *   variables: {
 *      incentiveId: // value for 'incentiveId'
 *   },
 * });
 */
export function useIncentiveTrackingQuery(baseOptions: Apollo.QueryHookOptions<IncentiveTrackingQuery, IncentiveTrackingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncentiveTrackingQuery, IncentiveTrackingQueryVariables>(IncentiveTrackingDocument, options);
      }
export function useIncentiveTrackingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncentiveTrackingQuery, IncentiveTrackingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncentiveTrackingQuery, IncentiveTrackingQueryVariables>(IncentiveTrackingDocument, options);
        }
export type IncentiveTrackingQueryHookResult = ReturnType<typeof useIncentiveTrackingQuery>;
export type IncentiveTrackingLazyQueryHookResult = ReturnType<typeof useIncentiveTrackingLazyQuery>;
export type IncentiveTrackingQueryResult = Apollo.QueryResult<IncentiveTrackingQuery, IncentiveTrackingQueryVariables>;
export const TrainingCompanyDocument = gql`
    query trainingCompany($id: String!) {
  trainingCompany(id: $id) {
    ...TrainingCompanyMinimal
    instructors {
      ...TrainingCompanyUserMinimal
    }
  }
}
    ${TrainingCompanyMinimalFragmentDoc}
${TrainingCompanyUserMinimalFragmentDoc}`;

/**
 * __useTrainingCompanyQuery__
 *
 * To run a query within a React component, call `useTrainingCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrainingCompanyQuery(baseOptions: Apollo.QueryHookOptions<TrainingCompanyQuery, TrainingCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrainingCompanyQuery, TrainingCompanyQueryVariables>(TrainingCompanyDocument, options);
      }
export function useTrainingCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrainingCompanyQuery, TrainingCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrainingCompanyQuery, TrainingCompanyQueryVariables>(TrainingCompanyDocument, options);
        }
export type TrainingCompanyQueryHookResult = ReturnType<typeof useTrainingCompanyQuery>;
export type TrainingCompanyLazyQueryHookResult = ReturnType<typeof useTrainingCompanyLazyQuery>;
export type TrainingCompanyQueryResult = Apollo.QueryResult<TrainingCompanyQuery, TrainingCompanyQueryVariables>;
export const TrainingCompaniesDocument = gql`
    query trainingCompanies {
  trainingCompanies {
    ...TrainingCompanyMinimal
    instructors {
      ...TrainingCompanyUserMinimal
    }
  }
}
    ${TrainingCompanyMinimalFragmentDoc}
${TrainingCompanyUserMinimalFragmentDoc}`;

/**
 * __useTrainingCompaniesQuery__
 *
 * To run a query within a React component, call `useTrainingCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrainingCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<TrainingCompaniesQuery, TrainingCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrainingCompaniesQuery, TrainingCompaniesQueryVariables>(TrainingCompaniesDocument, options);
      }
export function useTrainingCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrainingCompaniesQuery, TrainingCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrainingCompaniesQuery, TrainingCompaniesQueryVariables>(TrainingCompaniesDocument, options);
        }
export type TrainingCompaniesQueryHookResult = ReturnType<typeof useTrainingCompaniesQuery>;
export type TrainingCompaniesLazyQueryHookResult = ReturnType<typeof useTrainingCompaniesLazyQuery>;
export type TrainingCompaniesQueryResult = Apollo.QueryResult<TrainingCompaniesQuery, TrainingCompaniesQueryVariables>;
export const TrainingCompaniesPaginatedDocument = gql`
    query trainingCompaniesPaginated($skip: Int, $after: String, $before: String, $first: Int, $last: Int, $orderBy: TrainingCompaniesOrderInput, $filter: [TrainingCompaniesFilterInput!]) {
  trainingCompaniesPaginated(
    skip: $skip
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...TrainingCompanyMinimal
        instructors {
          ...TrainingCompanyUserMinimal
        }
      }
    }
  }
}
    ${TrainingCompanyMinimalFragmentDoc}
${TrainingCompanyUserMinimalFragmentDoc}`;

/**
 * __useTrainingCompaniesPaginatedQuery__
 *
 * To run a query within a React component, call `useTrainingCompaniesPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingCompaniesPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingCompaniesPaginatedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTrainingCompaniesPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<TrainingCompaniesPaginatedQuery, TrainingCompaniesPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrainingCompaniesPaginatedQuery, TrainingCompaniesPaginatedQueryVariables>(TrainingCompaniesPaginatedDocument, options);
      }
export function useTrainingCompaniesPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrainingCompaniesPaginatedQuery, TrainingCompaniesPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrainingCompaniesPaginatedQuery, TrainingCompaniesPaginatedQueryVariables>(TrainingCompaniesPaginatedDocument, options);
        }
export type TrainingCompaniesPaginatedQueryHookResult = ReturnType<typeof useTrainingCompaniesPaginatedQuery>;
export type TrainingCompaniesPaginatedLazyQueryHookResult = ReturnType<typeof useTrainingCompaniesPaginatedLazyQuery>;
export type TrainingCompaniesPaginatedQueryResult = Apollo.QueryResult<TrainingCompaniesPaginatedQuery, TrainingCompaniesPaginatedQueryVariables>;
export const TrainingCompaniesTraineesDocument = gql`
    query trainingCompaniesTrainees($all: Boolean, $withTopics: Boolean) {
  trainingCompanies {
    ...TrainingCompanyMinimal
    trainees(all: $all) {
      ...TrainingCompanyUserMinimalWithPlayer
      learningProgress(withTopics: $withTopics)
    }
  }
}
    ${TrainingCompanyMinimalFragmentDoc}
${TrainingCompanyUserMinimalWithPlayerFragmentDoc}`;

/**
 * __useTrainingCompaniesTraineesQuery__
 *
 * To run a query within a React component, call `useTrainingCompaniesTraineesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingCompaniesTraineesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingCompaniesTraineesQuery({
 *   variables: {
 *      all: // value for 'all'
 *      withTopics: // value for 'withTopics'
 *   },
 * });
 */
export function useTrainingCompaniesTraineesQuery(baseOptions?: Apollo.QueryHookOptions<TrainingCompaniesTraineesQuery, TrainingCompaniesTraineesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrainingCompaniesTraineesQuery, TrainingCompaniesTraineesQueryVariables>(TrainingCompaniesTraineesDocument, options);
      }
export function useTrainingCompaniesTraineesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrainingCompaniesTraineesQuery, TrainingCompaniesTraineesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrainingCompaniesTraineesQuery, TrainingCompaniesTraineesQueryVariables>(TrainingCompaniesTraineesDocument, options);
        }
export type TrainingCompaniesTraineesQueryHookResult = ReturnType<typeof useTrainingCompaniesTraineesQuery>;
export type TrainingCompaniesTraineesLazyQueryHookResult = ReturnType<typeof useTrainingCompaniesTraineesLazyQuery>;
export type TrainingCompaniesTraineesQueryResult = Apollo.QueryResult<TrainingCompaniesTraineesQuery, TrainingCompaniesTraineesQueryVariables>;
export const FilterTrainingCompaniesDocument = gql`
    query filterTrainingCompanies($filter: [TrainingCompaniesFilterInput!]) {
  filterTrainingCompanies(filter: $filter) {
    ...TrainingCompanyMinimal
  }
}
    ${TrainingCompanyMinimalFragmentDoc}`;

/**
 * __useFilterTrainingCompaniesQuery__
 *
 * To run a query within a React component, call `useFilterTrainingCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterTrainingCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterTrainingCompaniesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFilterTrainingCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<FilterTrainingCompaniesQuery, FilterTrainingCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterTrainingCompaniesQuery, FilterTrainingCompaniesQueryVariables>(FilterTrainingCompaniesDocument, options);
      }
export function useFilterTrainingCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterTrainingCompaniesQuery, FilterTrainingCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterTrainingCompaniesQuery, FilterTrainingCompaniesQueryVariables>(FilterTrainingCompaniesDocument, options);
        }
export type FilterTrainingCompaniesQueryHookResult = ReturnType<typeof useFilterTrainingCompaniesQuery>;
export type FilterTrainingCompaniesLazyQueryHookResult = ReturnType<typeof useFilterTrainingCompaniesLazyQuery>;
export type FilterTrainingCompaniesQueryResult = Apollo.QueryResult<FilterTrainingCompaniesQuery, FilterTrainingCompaniesQueryVariables>;
export const UpdateTrainingCompaniesDocument = gql`
    mutation updateTrainingCompanies($data: UpdateTrainingCompaniesInput!) {
  updateTrainingCompanies(data: $data) {
    ...TrainingCompanyMinimal
  }
}
    ${TrainingCompanyMinimalFragmentDoc}`;
export type UpdateTrainingCompaniesMutationFn = Apollo.MutationFunction<UpdateTrainingCompaniesMutation, UpdateTrainingCompaniesMutationVariables>;

/**
 * __useUpdateTrainingCompaniesMutation__
 *
 * To run a mutation, you first call `useUpdateTrainingCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainingCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainingCompaniesMutation, { data, loading, error }] = useUpdateTrainingCompaniesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTrainingCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTrainingCompaniesMutation, UpdateTrainingCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTrainingCompaniesMutation, UpdateTrainingCompaniesMutationVariables>(UpdateTrainingCompaniesDocument, options);
      }
export type UpdateTrainingCompaniesMutationHookResult = ReturnType<typeof useUpdateTrainingCompaniesMutation>;
export type UpdateTrainingCompaniesMutationResult = Apollo.MutationResult<UpdateTrainingCompaniesMutation>;
export type UpdateTrainingCompaniesMutationOptions = Apollo.BaseMutationOptions<UpdateTrainingCompaniesMutation, UpdateTrainingCompaniesMutationVariables>;
export const CreateTrainingCompanyDocument = gql`
    mutation createTrainingCompany($data: CreateTrainingCompanyInput!) {
  createTrainingCompany(data: $data) {
    ...TrainingCompanyMinimal
  }
}
    ${TrainingCompanyMinimalFragmentDoc}`;
export type CreateTrainingCompanyMutationFn = Apollo.MutationFunction<CreateTrainingCompanyMutation, CreateTrainingCompanyMutationVariables>;

/**
 * __useCreateTrainingCompanyMutation__
 *
 * To run a mutation, you first call `useCreateTrainingCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrainingCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrainingCompanyMutation, { data, loading, error }] = useCreateTrainingCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTrainingCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateTrainingCompanyMutation, CreateTrainingCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTrainingCompanyMutation, CreateTrainingCompanyMutationVariables>(CreateTrainingCompanyDocument, options);
      }
export type CreateTrainingCompanyMutationHookResult = ReturnType<typeof useCreateTrainingCompanyMutation>;
export type CreateTrainingCompanyMutationResult = Apollo.MutationResult<CreateTrainingCompanyMutation>;
export type CreateTrainingCompanyMutationOptions = Apollo.BaseMutationOptions<CreateTrainingCompanyMutation, CreateTrainingCompanyMutationVariables>;
export const UpdateTrainingCompanyDocument = gql`
    mutation updateTrainingCompany($id: String!, $data: UpdateTrainingCompanyInput!) {
  updateTrainingCompany(id: $id, data: $data) {
    ...TrainingCompanyMinimal
  }
}
    ${TrainingCompanyMinimalFragmentDoc}`;
export type UpdateTrainingCompanyMutationFn = Apollo.MutationFunction<UpdateTrainingCompanyMutation, UpdateTrainingCompanyMutationVariables>;

/**
 * __useUpdateTrainingCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateTrainingCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainingCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainingCompanyMutation, { data, loading, error }] = useUpdateTrainingCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTrainingCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTrainingCompanyMutation, UpdateTrainingCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTrainingCompanyMutation, UpdateTrainingCompanyMutationVariables>(UpdateTrainingCompanyDocument, options);
      }
export type UpdateTrainingCompanyMutationHookResult = ReturnType<typeof useUpdateTrainingCompanyMutation>;
export type UpdateTrainingCompanyMutationResult = Apollo.MutationResult<UpdateTrainingCompanyMutation>;
export type UpdateTrainingCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateTrainingCompanyMutation, UpdateTrainingCompanyMutationVariables>;
export const DeleteTrainingCompanyDocument = gql`
    mutation deleteTrainingCompany($id: String!) {
  deleteTrainingCompany(id: $id) {
    ...TrainingCompanyMinimal
  }
}
    ${TrainingCompanyMinimalFragmentDoc}`;
export type DeleteTrainingCompanyMutationFn = Apollo.MutationFunction<DeleteTrainingCompanyMutation, DeleteTrainingCompanyMutationVariables>;

/**
 * __useDeleteTrainingCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteTrainingCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrainingCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrainingCompanyMutation, { data, loading, error }] = useDeleteTrainingCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTrainingCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTrainingCompanyMutation, DeleteTrainingCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTrainingCompanyMutation, DeleteTrainingCompanyMutationVariables>(DeleteTrainingCompanyDocument, options);
      }
export type DeleteTrainingCompanyMutationHookResult = ReturnType<typeof useDeleteTrainingCompanyMutation>;
export type DeleteTrainingCompanyMutationResult = Apollo.MutationResult<DeleteTrainingCompanyMutation>;
export type DeleteTrainingCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteTrainingCompanyMutation, DeleteTrainingCompanyMutationVariables>;
export const DeleteTrainingCompaniesDocument = gql`
    mutation deleteTrainingCompanies($data: DeleteTrainingCompaniesInput!) {
  deleteTrainingCompanies(data: $data) {
    ...TrainingCompanyMinimal
  }
}
    ${TrainingCompanyMinimalFragmentDoc}`;
export type DeleteTrainingCompaniesMutationFn = Apollo.MutationFunction<DeleteTrainingCompaniesMutation, DeleteTrainingCompaniesMutationVariables>;

/**
 * __useDeleteTrainingCompaniesMutation__
 *
 * To run a mutation, you first call `useDeleteTrainingCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrainingCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrainingCompaniesMutation, { data, loading, error }] = useDeleteTrainingCompaniesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteTrainingCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTrainingCompaniesMutation, DeleteTrainingCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTrainingCompaniesMutation, DeleteTrainingCompaniesMutationVariables>(DeleteTrainingCompaniesDocument, options);
      }
export type DeleteTrainingCompaniesMutationHookResult = ReturnType<typeof useDeleteTrainingCompaniesMutation>;
export type DeleteTrainingCompaniesMutationResult = Apollo.MutationResult<DeleteTrainingCompaniesMutation>;
export type DeleteTrainingCompaniesMutationOptions = Apollo.BaseMutationOptions<DeleteTrainingCompaniesMutation, DeleteTrainingCompaniesMutationVariables>;
export const MyTraineesDocument = gql`
    query myTrainees {
  me {
    trainees {
      ...TrainingCompanyUserMinimalWithPlayer
      learningProgress
    }
  }
}
    ${TrainingCompanyUserMinimalWithPlayerFragmentDoc}`;

/**
 * __useMyTraineesQuery__
 *
 * To run a query within a React component, call `useMyTraineesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTraineesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTraineesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTraineesQuery(baseOptions?: Apollo.QueryHookOptions<MyTraineesQuery, MyTraineesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTraineesQuery, MyTraineesQueryVariables>(MyTraineesDocument, options);
      }
export function useMyTraineesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTraineesQuery, MyTraineesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTraineesQuery, MyTraineesQueryVariables>(MyTraineesDocument, options);
        }
export type MyTraineesQueryHookResult = ReturnType<typeof useMyTraineesQuery>;
export type MyTraineesLazyQueryHookResult = ReturnType<typeof useMyTraineesLazyQuery>;
export type MyTraineesQueryResult = Apollo.QueryResult<MyTraineesQuery, MyTraineesQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...UserFull
  }
}
    ${UserFullFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UsersDocument = gql`
    query users {
  users {
    ...UserFull
  }
}
    ${UserFullFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UsersPaginatedDocument = gql`
    query usersPaginated($skip: Int, $after: String, $before: String, $first: Int, $last: Int, $orderBy: UserOrderInput, $filter: [UserFilterInput!]) {
  usersPaginated(
    skip: $skip
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...UserFull
      }
    }
  }
}
    ${UserFullFragmentDoc}`;

/**
 * __useUsersPaginatedQuery__
 *
 * To run a query within a React component, call `useUsersPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersPaginatedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUsersPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<UsersPaginatedQuery, UsersPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersPaginatedQuery, UsersPaginatedQueryVariables>(UsersPaginatedDocument, options);
      }
export function useUsersPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersPaginatedQuery, UsersPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersPaginatedQuery, UsersPaginatedQueryVariables>(UsersPaginatedDocument, options);
        }
export type UsersPaginatedQueryHookResult = ReturnType<typeof useUsersPaginatedQuery>;
export type UsersPaginatedLazyQueryHookResult = ReturnType<typeof useUsersPaginatedLazyQuery>;
export type UsersPaginatedQueryResult = Apollo.QueryResult<UsersPaginatedQuery, UsersPaginatedQueryVariables>;
export const RegisteredTraineesCountDocument = gql`
    query registeredTraineesCount($monthBack: Int!) {
  registeredTraineesCount(monthsBack: $monthBack)
}
    `;

/**
 * __useRegisteredTraineesCountQuery__
 *
 * To run a query within a React component, call `useRegisteredTraineesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisteredTraineesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisteredTraineesCountQuery({
 *   variables: {
 *      monthBack: // value for 'monthBack'
 *   },
 * });
 */
export function useRegisteredTraineesCountQuery(baseOptions: Apollo.QueryHookOptions<RegisteredTraineesCountQuery, RegisteredTraineesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegisteredTraineesCountQuery, RegisteredTraineesCountQueryVariables>(RegisteredTraineesCountDocument, options);
      }
export function useRegisteredTraineesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegisteredTraineesCountQuery, RegisteredTraineesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegisteredTraineesCountQuery, RegisteredTraineesCountQueryVariables>(RegisteredTraineesCountDocument, options);
        }
export type RegisteredTraineesCountQueryHookResult = ReturnType<typeof useRegisteredTraineesCountQuery>;
export type RegisteredTraineesCountLazyQueryHookResult = ReturnType<typeof useRegisteredTraineesCountLazyQuery>;
export type RegisteredTraineesCountQueryResult = Apollo.QueryResult<RegisteredTraineesCountQuery, RegisteredTraineesCountQueryVariables>;
export const UserTrainingCompanyDocument = gql`
    query userTrainingCompany($id: String!) {
  user(id: $id) {
    ...TrainingCompanyUserMinimal
  }
}
    ${TrainingCompanyUserMinimalFragmentDoc}`;

/**
 * __useUserTrainingCompanyQuery__
 *
 * To run a query within a React component, call `useUserTrainingCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTrainingCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTrainingCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserTrainingCompanyQuery(baseOptions: Apollo.QueryHookOptions<UserTrainingCompanyQuery, UserTrainingCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTrainingCompanyQuery, UserTrainingCompanyQueryVariables>(UserTrainingCompanyDocument, options);
      }
export function useUserTrainingCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTrainingCompanyQuery, UserTrainingCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTrainingCompanyQuery, UserTrainingCompanyQueryVariables>(UserTrainingCompanyDocument, options);
        }
export type UserTrainingCompanyQueryHookResult = ReturnType<typeof useUserTrainingCompanyQuery>;
export type UserTrainingCompanyLazyQueryHookResult = ReturnType<typeof useUserTrainingCompanyLazyQuery>;
export type UserTrainingCompanyQueryResult = Apollo.QueryResult<UserTrainingCompanyQuery, UserTrainingCompanyQueryVariables>;
export const UserDocument = gql`
    query user($id: String!) {
  user(id: $id) {
    ...UserFull
  }
}
    ${UserFullFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const TrainingCompanyUserDocument = gql`
    query trainingCompanyUser($id: String!) {
  user(id: $id) {
    ...TrainingCompanyUserMinimal
  }
}
    ${TrainingCompanyUserMinimalFragmentDoc}`;

/**
 * __useTrainingCompanyUserQuery__
 *
 * To run a query within a React component, call `useTrainingCompanyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingCompanyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingCompanyUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrainingCompanyUserQuery(baseOptions: Apollo.QueryHookOptions<TrainingCompanyUserQuery, TrainingCompanyUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrainingCompanyUserQuery, TrainingCompanyUserQueryVariables>(TrainingCompanyUserDocument, options);
      }
export function useTrainingCompanyUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrainingCompanyUserQuery, TrainingCompanyUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrainingCompanyUserQuery, TrainingCompanyUserQueryVariables>(TrainingCompanyUserDocument, options);
        }
export type TrainingCompanyUserQueryHookResult = ReturnType<typeof useTrainingCompanyUserQuery>;
export type TrainingCompanyUserLazyQueryHookResult = ReturnType<typeof useTrainingCompanyUserLazyQuery>;
export type TrainingCompanyUserQueryResult = Apollo.QueryResult<TrainingCompanyUserQuery, TrainingCompanyUserQueryVariables>;
export const TraineeDocument = gql`
    query trainee($id: String!, $withTopics: Boolean) {
  user(id: $id) {
    ...TrainingCompanyUserMinimalWithPlayer
    learningProgress(withTopics: $withTopics)
    dryRuns {
      ...dryRunData
    }
  }
}
    ${TrainingCompanyUserMinimalWithPlayerFragmentDoc}
${DryRunDataFragmentDoc}`;

/**
 * __useTraineeQuery__
 *
 * To run a query within a React component, call `useTraineeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraineeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraineeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withTopics: // value for 'withTopics'
 *   },
 * });
 */
export function useTraineeQuery(baseOptions: Apollo.QueryHookOptions<TraineeQuery, TraineeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TraineeQuery, TraineeQueryVariables>(TraineeDocument, options);
      }
export function useTraineeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TraineeQuery, TraineeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TraineeQuery, TraineeQueryVariables>(TraineeDocument, options);
        }
export type TraineeQueryHookResult = ReturnType<typeof useTraineeQuery>;
export type TraineeLazyQueryHookResult = ReturnType<typeof useTraineeLazyQuery>;
export type TraineeQueryResult = Apollo.QueryResult<TraineeQuery, TraineeQueryVariables>;
export const FilterUsersDocument = gql`
    query filterUsers($filter: [UserFilterInput!], $roles: [String!]) {
  filterUsers(filter: $filter, roles: $roles) {
    ...UserFull
  }
}
    ${UserFullFragmentDoc}`;

/**
 * __useFilterUsersQuery__
 *
 * To run a query within a React component, call `useFilterUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useFilterUsersQuery(baseOptions?: Apollo.QueryHookOptions<FilterUsersQuery, FilterUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterUsersQuery, FilterUsersQueryVariables>(FilterUsersDocument, options);
      }
export function useFilterUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterUsersQuery, FilterUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterUsersQuery, FilterUsersQueryVariables>(FilterUsersDocument, options);
        }
export type FilterUsersQueryHookResult = ReturnType<typeof useFilterUsersQuery>;
export type FilterUsersLazyQueryHookResult = ReturnType<typeof useFilterUsersLazyQuery>;
export type FilterUsersQueryResult = Apollo.QueryResult<FilterUsersQuery, FilterUsersQueryVariables>;
export const CheckInvitationTokenDocument = gql`
    query checkInvitationToken($token: String!) {
  checkInvitationToken(token: $token)
}
    `;

/**
 * __useCheckInvitationTokenQuery__
 *
 * To run a query within a React component, call `useCheckInvitationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckInvitationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckInvitationTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCheckInvitationTokenQuery(baseOptions: Apollo.QueryHookOptions<CheckInvitationTokenQuery, CheckInvitationTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckInvitationTokenQuery, CheckInvitationTokenQueryVariables>(CheckInvitationTokenDocument, options);
      }
export function useCheckInvitationTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckInvitationTokenQuery, CheckInvitationTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckInvitationTokenQuery, CheckInvitationTokenQueryVariables>(CheckInvitationTokenDocument, options);
        }
export type CheckInvitationTokenQueryHookResult = ReturnType<typeof useCheckInvitationTokenQuery>;
export type CheckInvitationTokenLazyQueryHookResult = ReturnType<typeof useCheckInvitationTokenLazyQuery>;
export type CheckInvitationTokenQueryResult = Apollo.QueryResult<CheckInvitationTokenQuery, CheckInvitationTokenQueryVariables>;
export const CountUsersDocument = gql`
    query countUsers($role: String, $hasTrainingCompany: Boolean) {
  countUsers(role: $role, hasTrainingCompany: $hasTrainingCompany)
}
    `;

/**
 * __useCountUsersQuery__
 *
 * To run a query within a React component, call `useCountUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountUsersQuery({
 *   variables: {
 *      role: // value for 'role'
 *      hasTrainingCompany: // value for 'hasTrainingCompany'
 *   },
 * });
 */
export function useCountUsersQuery(baseOptions?: Apollo.QueryHookOptions<CountUsersQuery, CountUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountUsersQuery, CountUsersQueryVariables>(CountUsersDocument, options);
      }
export function useCountUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountUsersQuery, CountUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountUsersQuery, CountUsersQueryVariables>(CountUsersDocument, options);
        }
export type CountUsersQueryHookResult = ReturnType<typeof useCountUsersQuery>;
export type CountUsersLazyQueryHookResult = ReturnType<typeof useCountUsersLazyQuery>;
export type CountUsersQueryResult = Apollo.QueryResult<CountUsersQuery, CountUsersQueryVariables>;
export const DisconnectTraineeDocument = gql`
    mutation disconnectTrainee($traineeId: String!) {
  disconnectTrainee(traineeId: $traineeId) {
    ...TrainingCompanyUserMinimal
  }
}
    ${TrainingCompanyUserMinimalFragmentDoc}`;
export type DisconnectTraineeMutationFn = Apollo.MutationFunction<DisconnectTraineeMutation, DisconnectTraineeMutationVariables>;

/**
 * __useDisconnectTraineeMutation__
 *
 * To run a mutation, you first call `useDisconnectTraineeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectTraineeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectTraineeMutation, { data, loading, error }] = useDisconnectTraineeMutation({
 *   variables: {
 *      traineeId: // value for 'traineeId'
 *   },
 * });
 */
export function useDisconnectTraineeMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectTraineeMutation, DisconnectTraineeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectTraineeMutation, DisconnectTraineeMutationVariables>(DisconnectTraineeDocument, options);
      }
export type DisconnectTraineeMutationHookResult = ReturnType<typeof useDisconnectTraineeMutation>;
export type DisconnectTraineeMutationResult = Apollo.MutationResult<DisconnectTraineeMutation>;
export type DisconnectTraineeMutationOptions = Apollo.BaseMutationOptions<DisconnectTraineeMutation, DisconnectTraineeMutationVariables>;
export const DisconnectTraineesDocument = gql`
    mutation disconnectTrainees($traineeIds: [String!]!) {
  disconnectTrainees(traineeIds: $traineeIds) {
    ...TrainingCompanyUserMinimal
  }
}
    ${TrainingCompanyUserMinimalFragmentDoc}`;
export type DisconnectTraineesMutationFn = Apollo.MutationFunction<DisconnectTraineesMutation, DisconnectTraineesMutationVariables>;

/**
 * __useDisconnectTraineesMutation__
 *
 * To run a mutation, you first call `useDisconnectTraineesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectTraineesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectTraineesMutation, { data, loading, error }] = useDisconnectTraineesMutation({
 *   variables: {
 *      traineeIds: // value for 'traineeIds'
 *   },
 * });
 */
export function useDisconnectTraineesMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectTraineesMutation, DisconnectTraineesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectTraineesMutation, DisconnectTraineesMutationVariables>(DisconnectTraineesDocument, options);
      }
export type DisconnectTraineesMutationHookResult = ReturnType<typeof useDisconnectTraineesMutation>;
export type DisconnectTraineesMutationResult = Apollo.MutationResult<DisconnectTraineesMutation>;
export type DisconnectTraineesMutationOptions = Apollo.BaseMutationOptions<DisconnectTraineesMutation, DisconnectTraineesMutationVariables>;
export const ConfirmTraineeDocument = gql`
    mutation confirmTrainee($id: String!) {
  confirmTrainee(id: $id) {
    ...TrainingCompanyUserMinimal
  }
}
    ${TrainingCompanyUserMinimalFragmentDoc}`;
export type ConfirmTraineeMutationFn = Apollo.MutationFunction<ConfirmTraineeMutation, ConfirmTraineeMutationVariables>;

/**
 * __useConfirmTraineeMutation__
 *
 * To run a mutation, you first call `useConfirmTraineeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmTraineeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmTraineeMutation, { data, loading, error }] = useConfirmTraineeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmTraineeMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmTraineeMutation, ConfirmTraineeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmTraineeMutation, ConfirmTraineeMutationVariables>(ConfirmTraineeDocument, options);
      }
export type ConfirmTraineeMutationHookResult = ReturnType<typeof useConfirmTraineeMutation>;
export type ConfirmTraineeMutationResult = Apollo.MutationResult<ConfirmTraineeMutation>;
export type ConfirmTraineeMutationOptions = Apollo.BaseMutationOptions<ConfirmTraineeMutation, ConfirmTraineeMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($data: CreateUserInput!) {
  createUser(data: $data) {
    ...UserFull
  }
}
    ${UserFullFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const InviteOrCreateTraineeDocument = gql`
    mutation inviteOrCreateTrainee($data: InviteTraineeInput!) {
  inviteOrCreateTrainee(data: $data)
}
    `;
export type InviteOrCreateTraineeMutationFn = Apollo.MutationFunction<InviteOrCreateTraineeMutation, InviteOrCreateTraineeMutationVariables>;

/**
 * __useInviteOrCreateTraineeMutation__
 *
 * To run a mutation, you first call `useInviteOrCreateTraineeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteOrCreateTraineeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteOrCreateTraineeMutation, { data, loading, error }] = useInviteOrCreateTraineeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInviteOrCreateTraineeMutation(baseOptions?: Apollo.MutationHookOptions<InviteOrCreateTraineeMutation, InviteOrCreateTraineeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteOrCreateTraineeMutation, InviteOrCreateTraineeMutationVariables>(InviteOrCreateTraineeDocument, options);
      }
export type InviteOrCreateTraineeMutationHookResult = ReturnType<typeof useInviteOrCreateTraineeMutation>;
export type InviteOrCreateTraineeMutationResult = Apollo.MutationResult<InviteOrCreateTraineeMutation>;
export type InviteOrCreateTraineeMutationOptions = Apollo.BaseMutationOptions<InviteOrCreateTraineeMutation, InviteOrCreateTraineeMutationVariables>;
export const CreateInstructorDocument = gql`
    mutation createInstructor($data: CreateInstructorInput!) {
  createInstructor(data: $data) {
    ...UserFull
  }
}
    ${UserFullFragmentDoc}`;
export type CreateInstructorMutationFn = Apollo.MutationFunction<CreateInstructorMutation, CreateInstructorMutationVariables>;

/**
 * __useCreateInstructorMutation__
 *
 * To run a mutation, you first call `useCreateInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstructorMutation, { data, loading, error }] = useCreateInstructorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInstructorMutation(baseOptions?: Apollo.MutationHookOptions<CreateInstructorMutation, CreateInstructorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInstructorMutation, CreateInstructorMutationVariables>(CreateInstructorDocument, options);
      }
export type CreateInstructorMutationHookResult = ReturnType<typeof useCreateInstructorMutation>;
export type CreateInstructorMutationResult = Apollo.MutationResult<CreateInstructorMutation>;
export type CreateInstructorMutationOptions = Apollo.BaseMutationOptions<CreateInstructorMutation, CreateInstructorMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: String!, $data: UpdateUserInput!) {
  updateUser(id: $id, data: $data) {
    ...UserFull
  }
}
    ${UserFullFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateInstructorDocument = gql`
    mutation updateInstructor($id: String!, $data: UpdateInstructorInput!) {
  updateInstructor(id: $id, data: $data) {
    ...UserFull
  }
}
    ${UserFullFragmentDoc}`;
export type UpdateInstructorMutationFn = Apollo.MutationFunction<UpdateInstructorMutation, UpdateInstructorMutationVariables>;

/**
 * __useUpdateInstructorMutation__
 *
 * To run a mutation, you first call `useUpdateInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstructorMutation, { data, loading, error }] = useUpdateInstructorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateInstructorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstructorMutation, UpdateInstructorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstructorMutation, UpdateInstructorMutationVariables>(UpdateInstructorDocument, options);
      }
export type UpdateInstructorMutationHookResult = ReturnType<typeof useUpdateInstructorMutation>;
export type UpdateInstructorMutationResult = Apollo.MutationResult<UpdateInstructorMutation>;
export type UpdateInstructorMutationOptions = Apollo.BaseMutationOptions<UpdateInstructorMutation, UpdateInstructorMutationVariables>;
export const UpdateUsersDocument = gql`
    mutation updateUsers($data: UpdateUsersInput!) {
  updateUsers(data: $data) {
    ...UserFull
  }
}
    ${UserFullFragmentDoc}`;
export type UpdateUsersMutationFn = Apollo.MutationFunction<UpdateUsersMutation, UpdateUsersMutationVariables>;

/**
 * __useUpdateUsersMutation__
 *
 * To run a mutation, you first call `useUpdateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsersMutation, { data, loading, error }] = useUpdateUsersMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUsersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUsersMutation, UpdateUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUsersMutation, UpdateUsersMutationVariables>(UpdateUsersDocument, options);
      }
export type UpdateUsersMutationHookResult = ReturnType<typeof useUpdateUsersMutation>;
export type UpdateUsersMutationResult = Apollo.MutationResult<UpdateUsersMutation>;
export type UpdateUsersMutationOptions = Apollo.BaseMutationOptions<UpdateUsersMutation, UpdateUsersMutationVariables>;
export const UpdateMeDocument = gql`
    mutation updateMe($data: UpdateUserInput!) {
  updateMe(data: $data) {
    ...UserFull
  }
}
    ${UserFullFragmentDoc}`;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const DeleteMeDocument = gql`
    mutation deleteMe {
  deleteMe {
    ...UserFull
  }
}
    ${UserFullFragmentDoc}`;
export type DeleteMeMutationFn = Apollo.MutationFunction<DeleteMeMutation, DeleteMeMutationVariables>;

/**
 * __useDeleteMeMutation__
 *
 * To run a mutation, you first call `useDeleteMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeMutation, { data, loading, error }] = useDeleteMeMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteMeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMeMutation, DeleteMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMeMutation, DeleteMeMutationVariables>(DeleteMeDocument, options);
      }
export type DeleteMeMutationHookResult = ReturnType<typeof useDeleteMeMutation>;
export type DeleteMeMutationResult = Apollo.MutationResult<DeleteMeMutation>;
export type DeleteMeMutationOptions = Apollo.BaseMutationOptions<DeleteMeMutation, DeleteMeMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: String!) {
  deleteUser(id: $id) {
    ...UserFull
  }
}
    ${UserFullFragmentDoc}`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DeleteUsersDocument = gql`
    mutation deleteUsers($userIds: [String!]!) {
  deleteUsers(userIds: $userIds) {
    ...UserFull
  }
}
    ${UserFullFragmentDoc}`;
export type DeleteUsersMutationFn = Apollo.MutationFunction<DeleteUsersMutation, DeleteUsersMutationVariables>;

/**
 * __useDeleteUsersMutation__
 *
 * To run a mutation, you first call `useDeleteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersMutation, { data, loading, error }] = useDeleteUsersMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useDeleteUsersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUsersMutation, DeleteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUsersMutation, DeleteUsersMutationVariables>(DeleteUsersDocument, options);
      }
export type DeleteUsersMutationHookResult = ReturnType<typeof useDeleteUsersMutation>;
export type DeleteUsersMutationResult = Apollo.MutationResult<DeleteUsersMutation>;
export type DeleteUsersMutationOptions = Apollo.BaseMutationOptions<DeleteUsersMutation, DeleteUsersMutationVariables>;
export const InviteUserDocument = gql`
    mutation inviteUser($id: String!) {
  inviteUser(id: $id)
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    